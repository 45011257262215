import React from "react";
import { Outlet } from "react-router-dom";
import SidebarWithNav from "../sidebarwithnav/SidebarWithNav";
import NavigationComponent from "../navigation/NavigationComponent";
import { useAuthContext } from "../../Context/authContext";

const DashboardLayout = () => {
  const { logoutFunction, profile, accountType } = useAuthContext(); 

  return (
    <div className="flex h-screen overflow-hidden">
      {/* SidebarWithNav occupies one side */}
      <SidebarWithNav logoutFunction={logoutFunction} accountType={accountType} />
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden bg-[#F9F9F9]">
        {/* Top Navigation */}
        <NavigationComponent />
        {/* Page Content */}
        <main className="p-6 overflow-x-hidden overflow-y-scroll flex-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
