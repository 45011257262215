import React from "react";
import { LandingPageNavigation } from "../../components/navigation/NavigationComponent";
import "./LandingPage.scss";
import { FAQComponent, FooterComponent } from "./LandingPage";

function HowItWorksPage() {
  return (
    <div>
      <section className="w-full  z-[99999] mb-5 fixed top-0 bg-white">
        <LandingPageNavigation />
      </section>

      <section className="w-full flex justify-center items-center">
        <div className="pt-[90px] px-5  w-full max-w-[900px] text-black/70 text-[18px]">
          <h1 className="self-stretch text-wh ite text-[35px] mb-3 font-bold  text-black/80 leading-[72px]">
            How It Work
          </h1>
          Vooom is a revolutionary Last-mile logistics and vehicle management
          platform that connects vehicle owners, delivery riders, and businesses
          for seamless operations. Whether you’re looking to earn passive
          income, manage your deliveries, or start a delivery career, Vooom
          makes it simple.
          <br />
          <br />{" "}
          <b className="text-[20px] text-black/80">
            For Logistics Owners:
          </b>{" "}
          <br />
          Unlock Passive Income Let your vehicle work for you while you earn
          stress-free income.
          <br /> <br />
          Sign Up and Register Your Logistics Bike, Create an account on the
          Vooom platform. Provide required details such as your vehicle’s type,
          condition, and ownership documents. Vehicle Inspection and Branding{" "}
          <br />
          Schedule a free inspection to ensure your vehicle meets operational
          standards. <br />
          Vooom handles branding, permits, Insurance and installation of
          tracking devices and boxes.
          <br />
          <br />
          <b className="text-[20px] text-black/80">
            Onboard and Start Earning
          </b>{" "}
          <br />
          Once your vehicle is onboarded, it becomes part of the Vooom fleet.
          <br />
          Vooom matches your vehicle with riders or direct delivery tasks.{" "}
          <br />
          <br />
          <b className="text-[20px] text-black/80">
            Relax and Monitor Earnings
          </b>{" "}
          <br />
          Sit back as Vooom manages the day-to-day operations, including
          maintenance and rider allocation. <br />
          Get weekly payouts with full transparency via the Vooom app.
          <br />
          <br />
          <b className="text-[20px] text-black/80">For Delivery Riders:</b>{" "}
          <br />
          Start Earning Flexibly <br />
          Join the Vooom network and take control of your income with flexible
          hours and guaranteed earnings. <br />
          <br />{" "}
          <b className="text-[20px] text-black/80">Register as a Rider</b>{" "}
          <br />
          Sign up on the Vooom platform as a freelance rider. <br />
          Submit essential documents, including a valid license, ID, Gurantors
          and proof of experience. <br />
          <br />
          <b className="text-[20px] text-black/80">
            Onboarding and Training
          </b>{" "}
          <br />
          Complete a brief onboarding process to familiarize yourself with
          Vooom’s tools and practices. <br />
          Get matched with a vehicle. <br />
          <br />
          <b className="text-[20px] text-black/80">
            Start Accepting Deliveries
          </b>{" "}
          <br />
          Use the Vooom app to browse and accept delivery tasks in your city.
          <br />
          <br />
          <b className="text-[20px] text-black/80">Why Choose Vooom?</b> <br />
          Convenience: Comprehensive management of logistics, riders, and
          vehicles. <br />
          Safety and Security: All vehicles are insured, tracked, and maintained
          regularly. <br />
          <br />
          <b className="text-[20px] text-black/80">Earnings Transparency:</b>
          <br />
          Clear, detailed reports for vehicle owners and riders.
          <br />
          <br />
          <b className="text-[20px] text-black/80">Flexibility:</b> <br />
          Work on your terms as a rider or list your vehicle for passive income.
          <br />
          Start Your Journey with Vooom Today! <br />
          Whether you’re a vehicle owner, delivery rider, or business, Vooom
          offers a seamless solution for logistics and vehicle management.{" "}
          <br />
          {/* <br /> */}
          <br />
          Sign up now and be part of the logistics revolution!
        </div>
      </section>

      <FooterComponent />
    </div>
  );
}

export default HowItWorksPage;
