import React from "react";
import { useAuthContext } from "../../Context/authContext";
import { StatCard } from "../../components/statscard/StatsCard";
import { Spinner } from "@chakra-ui/react";
import RemittanceListComponent from "../../components/table/react-tables/RemittanceList";

function ReportPage() {
  const { profile, accountType } = useAuthContext();
  return (
    <main className="flex over flow-hidden flex-col pb-56 max-md:pb-24 ">
      <section className="w-full  flex px-5 ">
        <div className="w-[1128px] h-[100px] py-5 justify-between items-center gap-[84 0px] inline-flex mx-auto">
          <div className="text-[#121212] text-[25px] md:text-[32px] font-['Circular Std'] leading-normal ">
            {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
            Remittance
          </div>
        </div>
      </section>

      <section className="flex w-full mx-auto">
        <div className="w-full">
          <RemittanceListComponent />
        </div>
      </section>
    </main>
  );
}

export default ReportPage;