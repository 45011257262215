import React, { useMemo } from "react";
import { useAuthContext } from "../../Context/authContext";
import { StatCard } from "../../components/statscard/StatsCard";
import OwnerVehicleListComponent from "../../components/table/react-tables/OwnerVehicleList";
import { Spinner } from "@chakra-ui/react";
import { useDataContext } from "../../Context/dataContext";

function ListedVehiclePage() {
  const { profile, accountType } = useAuthContext();
  const { listedVehicle } = useDataContext();

  const statCards = useMemo(
    () => [
      {
        title: "Wallet Balance",
        value:
          profile?.wallet?.balance !== undefined ? (
            `₦${profile?.wallet?.balance.toLocaleString()}`
          ) : (
            <Spinner />
          ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/76ba05aeeafdd95ef1416e5e890d8ffef4fb04fd9ee1b4e2b2f95ecb4e84123e?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Amount this week",
        // map through vehicle and get active once and multiply it with the fixed price
        value: !listedVehicle?.loading ? (
          `₦${(
            listedVehicle.data.filter(
              (item) =>
                item.active_vehicle &&
                item.rider !== null &&
                item.verified_vehicle
            ).length * 13000
          ).toLocaleString()}`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a51ba61aa98bf32263b0bb5a2c49f27fc46daa868b04f2b85d6f793d8c66d845?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Active bikes",
        // map through vehicle and get active length
        value: !listedVehicle?.loading ? (
          `${
            listedVehicle.data.filter(
              (item) =>
                item.active_vehicle &&
                item.rider !== null &&
                item.verified_vehicle
            ).length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4cb079b41cc57ea4d0e3ded7d5de408532d977827f6fe4defef7051bddebc3da?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Inactive bikes",
        // map through vehicle and get nonactive length
        value: !listedVehicle?.loading ? (
          `${
            listedVehicle.data.filter(
              (item) =>
                !item.active_vehicle ||
                item.rider === null ||
                !item.verified_vehicle
            ).length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/eef1ee6798421addd02719d5a573e95df5da63d00dccf84aa691bb090df1355a?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
    ],
    [profile?.wallet?.balance, listedVehicle]
  );

  return (
    <main className="flex over flow-hidden flex-col pb-56  max-md:pb-24 ">
      <section className="w-full  flex px-5 ">
        <div className="w-[1128px] h-[100px] py-5 justify-between items-center gap-[84 0px] inline-flex mx-auto">
          <div className="text-[#121212] text-[25px] md:text-[32px] font-['Circular Std'] leading-normal ">
            {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
            Listed Vehicles
          </div>
        </div>
      </section>

      <section className="flex w-full mx-auto">
        <div className="w-full">
          {accountType === "owner" && (
            <>
              {" "}
              {!profile ? (
                <div className="flex justify-center mt-10">
                  <Spinner className="mx-auto" />
                </div>
              ) : (
                <div className="flex gap-3 flex-wrap justify-evenly lg:justify-start">
                  {/* <div className="flex flex-wrap gap-6 items-center self-stretch mt-11 max-md:mt-10 max-md:max-w-full"> */}
                  {statCards.map((card, index) => (
                    <StatCard key={index} {...card} />
                  ))}
                  {/* </div> */}
                </div>
              )}
            </>
          )}
          <OwnerVehicleListComponent />
        </div>
      </section>
    </main>
  );
}

export default ListedVehiclePage;
