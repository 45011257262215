import React from "react";
import { LandingPageNavigation } from "../../components/navigation/NavigationComponent";
import "./LandingPage.scss";
import { FAQComponent, FooterComponent } from "./LandingPage";

function ContactUsPage() {
  return (
    <div>
      <section className="w-full  z-[99999] mb-5 fixed top-0 bg-white">
        <LandingPageNavigation />
      </section>

      <section className="back-ruge-bg-image  w-full flex  justify-center mt-[70px]">
        <div className="flex justify-evenly gap-10 items-center w-[1440px]">
          <div className="hidden md:block image-container w-[731px] h-[806px]">
            <img
              className="size-full object-cover "
              // src="https://via.placeholder.com/731x806"
              src="https://s3-alpha-sig.figma.com/img/b594/1c77/744cd59deb110a5a7beb1e622b4b5df3?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c16GA8-n4bpHoJiZ0As6D1xtacVFrLDdtESuA8nJ96~qI~06gXy7IQ9qsg-MfvS9MqMIEyOQC9FZOOtmwkyyD3nDwnHpFBJaxuc9GZz0VBJB8CWV4Oyhzkr4XcaNsnMaIIkrTGAsOi7cmwH3MWAbUyPRaDoY8n-hR0zB0V6onGhv5h3HZFyxO9LkAjaoDVUeWp~m2ibjNUD7JtKgDR37Vcrtru6Hv55S9FVbrYl0xYffoEeDcQYEH3BBy-zbyekl1mXP6IZi0GGHlvWrFMQNaOWyJGtlyfGf6g-qvMNqJqz4pcZVvN11btQqrLUt1j8HCQ5zfZQtPwgDSd47Lcnong__"
            />
          </div>

          <div className="my-10 md:my-0 px-10 md:pe-3 md:p-0 w-full md:max-w-[559px] min-h-[616px] flex-col justify-start items-start gap-[45px] inline-flex">
            <div className="h-[104px] flex-col justify-start items-start flex mb-5">
              <div className="self-stretch text-white text-[45px] font-bold  leading-[72px]">
                Get in touch
              </div>
              <div className="text-white/60 text-xl font-semibold  leading-tight">
                Have an enquiry? Fill out the form to contact our team
              </div>
            </div>
            <div className=" h- [374px] w-full flex-col justify-start items-start gap-5 flex">
              <div className="flex-col md:flex-row w-full justify-start items-center gap-[31px] inline-flex">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
              </div>
              <div className=" flex-col md:flex-row w-full justify-start items-center gap-[31px] inline-flex">
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
                <input
                  type="text"
                  placeholder="Email address"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
              </div>
              <textarea
                className=" h-[210px] w-full px-7 py-[13px] rounded-[20px] border-2 border-white justify-start bg-transparent outline-none inline-flex text-white/50 text-xl"
                placeholder="Message"
              ></textarea>
            </div>
            <div className="px-[50px] py-2.5 bg-white rounded-[50px] justify-center items-center gap-2.5 inline-flex">
              <div className="text-[#110e0d] text-2xl font-bold ">Submit</div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full flex justify-center items-center">
        <div className="py-20 pb-10 flex-col justify-start items-center gap-[50px] inline-flex">
          <div className="flex-col justify-start items-center gap-2 flex">
            <div className=" text-center text-black text-[45px] font-bold  leading-[58.50px]">
              Get in touch
            </div>
            <div className=" text-center text-black/40 text-xl font-bold  leading-relaxed">
              We always send you a quick response form to contact our team
            </div>
          </div>
          <div className=" items-center w-full max-w-[1000px] gap-[50px] flex-wrap flex-col md:flex-row justify-evenly inline-flex">
            <div className="px-10 py-7 bg-[#ececec] w-[300px] rounded-[15px] flex-col justify-start items-center gap-[21px] inline-flex">
              <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                <div className="w-[32.87px] h-[32.87px] relative" />
              </div>
              <div className="flex-col justify-start items-center gap-3 flex">
                <div className="text-black text-xl font-bold  leading-relaxed">
                  Phone
                </div>
                <div className="text-center text-black/40 text-base font-bold  leading-3">
                  +234 810 197 7171
                </div>
              </div>
            </div>
            <div className="px-10 py-7 bg-[#ececec] w-[300px] rounded-[15px] flex-col justify-start items-center gap-[21px] inline-flex">
              <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                <div className="w-[32.87px] h-[32.87px] relative" />
              </div>
              <div className="flex-col justify-start items-center gap-3 flex">
                <div className="text-black text-xl font-bold  leading-relaxed">
                  Email
                </div>
                <div className="w-[178px] text-center text-black/40 text-base font-bold  leading-3">
                  hello@binnox.app
                </div>
              </div>
            </div>
            <div className="px-10 py-5 bg-[#ececec] w-[300px] rounded-[15px] flex-col justify-start items-center gap-[21px] inline-flex">
              <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                <div className="w-[32.87px] h-[32.87px] relative" />
              </div>
              <div className="flex-col justify-start items-center gap-3 flex">
                <div className="text-black text-xl font-bold  leading-relaxed">
                  Location
                </div>
                <div className="text-center text-black/40 text-base font-bold  leading-5">
                  Binnox Global HQ, Rock of
                  {/* <br /> */}
                  Ages Mall - Jabi
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative w-full flex justify-center">
        <FAQComponent />
      </section>

      <FooterComponent />
    </div>
  );
}

export default ContactUsPage;
