import React, { useState, useMemo, useEffect } from "react";
import VoomTable from "./Table"; // Adjust the path to your VehicleTable component
import { useDataContext } from "../../../Context/dataContext";

const OwnerVehicleListComponent = () => {
  const { listedVehicle } = useDataContext();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle ID",
        accessor: "vehicle_id",
      },
      {
        Header: "Health Status",
        accessor: "health_status",
      },
      // {
      //   Header: "Week",
      //   accessor: "week",
      // },
      {
        Header: "Active Status",
        accessor: "status",
      },
      // {
      //   Header: "Amount",
      //   accessor: "amount",
      // },
      {
        Header: "Rider",
        accessor: "rider",
      },
    ],
    []
  );

  useEffect(() => {
    // console.log([listedVehicle]);

    const transformedArray = listedVehicle.data.map((vehicle) => ({
      vehicle_id: vehicle._id, // Use the _id field as the vehicle_id
      status:
        vehicle.active_vehicle && vehicle.verified_vehicle && vehicle.rider
          ? "Active"
          : "Inactive", // Map active_vehicle to health_status
      health_status: vehicle.active_vehicle ? "Good" : "not Good", // Map active_vehicle to health_status
      week: "Week 1", // Hardcoded value
      amount: 1300, // Hardcoded value
      rider: {
        full_name: vehicle.rider ? vehicle.rider.full_name : "---", // Default to "John Doe" if no rider is present
      },
    }));

    setData(transformedArray);
  }, [listedVehicle]);

  return (
    <>
      <VoomTable
        title="Hosted Vehicles"
        data={data}
        columns={columns}
        filter={filter}
        setFilter={setFilter}
      />
    </>
  );
};

export default OwnerVehicleListComponent;
