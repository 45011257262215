import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  BackArrowIconSvg,
  HostedVehiclesIconSvg,
  InspectionIconSvg,
  PopUpCloseIconSvg,
  RevenueIconSvg,
} from "../../icons";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
} from "@chakra-ui/react";
import { useDataContext } from "../../Context/dataContext";
import { useAuthContext } from "../../Context/authContext";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { toast } from "react-toastify";
import ModelPopupComponent from "../../components/modalPopup/ModelPopupComponent";
import { ModalContext } from "../../Context/modalContext";

import { StatCard } from "../../components/statscard/StatsCard";
import VoomTable from "../../components/table/react-tables/Table";
import OwnerVehicleListComponent from "../../components/table/react-tables/OwnerVehicleList";
import {
  InputField,
  SelectDropDownField,
} from "../../components/FormElementComponent/FormElementComponent";
import PaymentsHistoryListComponent from "../../components/table/react-tables/PaymentsHistoryList";

function DashboardPage() {
  const { profile, accountType } = useAuthContext();
  const { payments, ownerListedVehicle, listedVehicle, assignedVehicle } =
    useDataContext();
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility

  const statCards = useMemo(() => {
    // Cards for Owner
    const ownerCards = [
      {
        title: "Wallet Balance",
        value:
          profile?.wallet?.balance !== undefined ? (
            `₦${profile?.wallet?.balance?.toLocaleString()}`
          ) : profile?.wallet === null ? (
            <button
              className="flex justify-center items-center text-sm font-medium text-white bg-[#000] rounded-lg h-[40px] w-full"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Create Wallet
            </button>
          ) : (
            <Spinner />
          ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/76ba05aeeafdd95ef1416e5e890d8ffef4fb04fd9ee1b4e2b2f95ecb4e84123e?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Amount this week",
        value: !listedVehicle?.loading ? (
          `₦${(
            listedVehicle.data.filter(
              (item) =>
                item.active_vehicle &&
                item.rider !== null &&
                item.verified_vehicle
            ).length * 13000
          ).toLocaleString()}`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a51ba61aa98bf32263b0bb5a2c49f27fc46daa868b04f2b85d6f793d8c66d845?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Active bikes",
        value: !listedVehicle?.loading ? (
          `${
            listedVehicle.data.filter(
              (item) =>
                item.active_vehicle &&
                item.rider !== null &&
                item.verified_vehicle
            ).length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4cb079b41cc57ea4d0e3ded7d5de408532d977827f6fe4defef7051bddebc3da?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Inactive bikes",
        value: !listedVehicle?.loading ? (
          `${
            listedVehicle.data.filter(
              (item) =>
                !item.active_vehicle ||
                item.rider === null ||
                !item.verified_vehicle
            ).length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/eef1ee6798421addd02719d5a573e95df5da63d00dccf84aa691bb090df1355a?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
    ];

    // Cards for Rider
    const riderCards = [
      {
        title: "Remittance this week",
        value: assignedVehicle.data ? "₦22,000" : "₦0",
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1234fbc4cbf2a9ff3e3e8c4d4db0c4725a4ff5dbbf44a4d482da56b523e45bb2?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Pending",
        value: !payments?.loading ? (
          `${
            payments?.data?.filter((item) => item.payment_status === "pending")
              ?.length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/56efed3519e9b7ac40ec2e2e379cfbe1dbe05f5b1e89e0b86594d82cc5d5e1ac?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Overdue",
        value: !payments?.loading ? (
          `${
            payments?.data?.filter((item) => item.payment_status === "overdue")
              ?.length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/56efed3519e9b7ac40ec2e2e379cfbe1dbe05f5b1e89e0b86594d82cc5d5e1ac?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
    ];

    // Determine which set of cards to render based on account type
    return accountType === "owner" ? ownerCards : riderCards;
  }, [profile?.wallet?.balance, listedVehicle, accountType]);

  console.log({ assignedVehicle });

  return (
    <main className="flex over flow-hidden flex-col pb-56 max-md:pb-24 ">
      <section className="w-full  flex px-5 ">
        <div className="w-[1128px] h-[100px] py-5 justify-between items-center gap-[84 0px] inline-flex mx-auto">
          <div className="text-[#121212] text-[25px] md:text-[32px] font-['Circular Std'] leading-normal ">
            {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
            Dashboard
          </div>

          <Link
            to={"./profile"}
            className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white"
          >
            Profile
          </Link>
        </div>
      </section>

      <section className="prompt_notification flex flex-col px-5 gap-2 items-center">
        {/* <PromptNotificationCardComponent
          // type="info"
          type="error"
          message={"Account was suspended by admin"}
          actionUrl={"/"}
          isAction={false}
        />
        <PromptNotificationCardComponent
          type="info"
          message={"Complete Account Setup"}
          actionUrl={`./setup-account?action=${accountType}`}
          isAction={true}
        /> */}
        {payments == null ? (
          <div className="flex justify-center mt-10">
            <Spinner className="mx-auto" />
          </div>
        ) : (
          <>
            {profile?.guarantor_documents?.length < 2 && (
              <PromptNotificationCardComponent
                type="info"
                message={"Complete your guarantor form"}
                actionUrl={`./profile`}
                isAction={true}
              />
            )}
            {profile?.verification_documents?.length < 3 && (
              <PromptNotificationCardComponent
                type="info"
                message={"Complete your verification documents"}
                actionUrl={`./profile`}
                isAction={true}
              />
            )}
            {profile?.verification_documents?.some(
              (document) => !document?.verified
            ) && (
              <PromptNotificationCardComponent
                type="info"
                message={"Verification documents not verified"}
                actionUrl={`./profile`}
                isAction={false}
              />
            )}
            {profile?.guarantor_documents?.some(
              (document) => !document?.verified
            ) && (
              <PromptNotificationCardComponent
                type="info"
                message={"Guarantor not verified"}
                actionUrl={`./profile`}
                isAction={false}
              />
            )}
            {!profile?.account_verified && (
              <PromptNotificationCardComponent
                type="info"
                message={"Account is not yet verified"}
                actionUrl={`./setup-account?action=${accountType}`}
                isAction={false}
              />
            )}
            {!profile?.account_active && (
              <PromptNotificationCardComponent
                type="danger"
                message={"Account has been deactivated. contact admin"}
                actionUrl={`./setup-account?action=${accountType}`}
                isAction={false}
              />
            )}
            {!profile?.account_active && (
              <PromptNotificationCardComponent
                type="danger"
                message={"Account has been deactivated. contact admin"}
                actionUrl={`./setup-account?action=${accountType}`}
                isAction={false}
              />
            )}
          </>
        )}
      </section>
      {/* render for only rider, render assigned vehicle */}
      <section className=" mt-3 flex w-full max-w-[1128px] mx-auto">
        {accountType === "rider" && (
          <>
            {assignedVehicle.data && (
              <div className="flex justify-between items-center flex-wrap w-full">
                <div className="flex flex-row flex-wrap items-center gap-2">
                  <img
                    src={
                      assignedVehicle?.data?.vehicle_images?.length > 0
                        ? assignedVehicle?.data?.vehicle_images[0]
                        : "https://via.placeholder.com/145x97"
                    }
                    alt="Vehicle Image"
                    className="size-[70px] rounded-[100px]"
                  />
                  <div className="flex flex-col gap-.5">
                    <b>
                      {assignedVehicle.data.vehicle_company}{" "}
                      {assignedVehicle.data.model}
                    </b>
                    <div className="flex flex-row gap-1">
                      <div
                        className={`
                      flex justify-center items-center rounded-md text-white
                      h-[24px] px-1
                      text-[10px]
                      ${
                        assignedVehicle.data.active_vehicle
                          ? "bg-[#00C814]"
                          : "bg-[#F00000]"
                      }
                      `}
                      >
                        {" "}
                        {assignedVehicle.data.active_vehicle
                          ? "Active"
                          : "Not Active"}
                      </div>
                      <div
                        className={`
                       flex justify-center items-center rounded-md text-white
                      h-[24px] px-1
                      text-[10px]
                      ${
                        assignedVehicle.data.verified_vehicle
                          ? "bg-[#00C814]"
                          : "bg-[#F00000]"
                      }
                      `}
                      >
                        {" "}
                        {assignedVehicle.data.verified_vehicle
                          ? "Verified"
                          : "Not Verified"}
                      </div>
                    </div>
                    {/* <p> {assignedVehicle.data._id}</p> */}
                  </div>
                  <button
                    className="   flex justify-center items-center rounded-md text-white
                      h-[30px] px-1 ms-3
                      text-[15px] bg-gray-400"
                  >
                    Open details
                  </button>
                </div>
                <button
                  className={`
                      border
                      py-1 px-2 rounded-md text-white
                  h-[40px]
                         bg-[#F00000]
                     
                      `}
                >
                  Make Request
                </button>
              </div>
            )}
          </>
        )}
      </section>

      <section className="flex w-full max-w-[1128px] mx-auto">
        {!profile ? (
          <div className="flex justify-center mt-10">
            <Spinner className="mx-auto" />
          </div>
        ) : (
          <div className="flex gap-3 flex-wrap justify-evenly lg:justify-start">
            {statCards.map((card, index) => (
              <StatCard key={index} {...card} />
            ))}
          </div>
        )}
      </section>

      <section className="flex w-full max-w-[1128px] mx-auto">
        <div className="w-full md:w-[70%] ">
          {/* {accountType === "rider" && (
            <>
              {" "}
              {payments.loading ? (
                <div className="flex justify-center mt-10">
                  <Spinner className="mx-auto" />
                </div>
              ) : (
                <>
                  {payments.data.map((payment, index) => {
                    if (payment.payment_status === "paid") return;
                    return (
                      <PaymentPromptNotificationComponent
                        payment={payment}
                        key={index}
                      />
                    );
                  })}
                </>
              )}
            </>
          )} */}

          <div className="  mx-auto flex flex-col flex-wrap justify-evenly overflow-x-scroll">
            {accountType === "owner" && <OwnerVehicleListComponent />}
            {accountType === "rider" && <RiderPaymentTableComponent />}
          </div>
        </div>
        <div className="hidden md:block w-full md:w-[30%]">
          <NotificationComponent />
        </div>
      </section>

      {/* Modal for  create wallet model */}
      {isModalOpen && (
        <CreateWalletModelContentComponent setIsModalOpen={setIsModalOpen} />
      )}
    </main>
  );
}

export default DashboardPage;

function HostedVehiclesCardComponent({ vehicle }) {
  return (
    <>
      <Link
        to={`./vehicle/${vehicle?._id}`}
        className="w-full p-[10px] gap- 1 flex flex-col md:flex-row flex-wrap justify-be tween bg-white rounded-lg border border-[#909090] cursor-pointer mb-3"
      >
        <div className="flex gap-3 md:gap-5 md:flex-row w-full md:w-[50%]">
          <div className=" w-[200px] md:w-[150px] h-[100px] gap-2 rounded-md border border-[#909090]">
            <img
              className="w-full h-full object-center object-cover"
              src={
                vehicle?.vehicle_images[0] ||
                "https://via.placeholder.com/145x97"
              }
            />
          </div>
          <div className="my-auto">
            <h3 className="text-[#121212] text-ellipsis font-Circular  font-size-[16px] font-bold ">
              {vehicle?.vehicle_company} {vehicle?.model}
            </h3>
            <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
              {vehicle?.vehicle_type}
            </span>
            <div className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px] flex gap-1">
              {vehicle?.verified_vehicle ? (
                <div className="border bg-green-500 text-white rounded-md px-1 py-.5 ">
                  Verified
                </div>
              ) : (
                <div className="border bg-red-500 text-white rounded-md px-1 py-.5 ">
                  Not Verified
                </div>
              )}
              {vehicle?.active_vehicle ? (
                <div className="border bg-green-500 text-white rounded-md px-1 py-.5 ">
                  Active
                </div>
              ) : (
                <div className="border bg-red-500 text-white rounded-md px-1 py-.5 ">
                  Not Active
                </div>
              )}
            </div>
          </div>
        </div>

        <div className=" flex md:justify-center md:items-center w-full md:w-[50%] md:gap-8 flex-col md:flex-row">
          <div className="my-auto  ">
            <h3 className="text-[#121212] text-ellipsis font-Circular  font-size-[16px] font-bold ">
              Engine:
            </h3>
            <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
              {vehicle?.health_status?.engine}
            </span>
            <h3 className="text-[#121212] text-ellipsis font-Circular  font-size-[16px] font-bold ">
              Body exterior:
            </h3>
            <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
              {vehicle?.health_status?.body_exterior}
            </span>
          </div>
          <div className=" flex justify-between items-center w-full">
            {vehicle?.rider && (
              <div className="my-auto">
                <h3 className="text-[#121212] text-ellipsis font-Circular  font-size-[16px] font-bold ">
                  Rider
                </h3>
                <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
                  {vehicle?.rider?.full_name}
                </span>
                <div className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px] flex gap-1">
                  {vehicle?.verified_vehicle ? (
                    <div className="border bg-green-500 text-white rounded-md px-1 py-.5 ">
                      Verified
                    </div>
                  ) : (
                    <div className="border bg-red-500 text-white rounded-md px-1 py-.5 ">
                      Not Verified
                    </div>
                  )}
                  {vehicle?.active_vehicle ? (
                    <div className="border bg-green-500 text-white rounded-md px-1 py-.5 ">
                      Active
                    </div>
                  ) : (
                    <div className="border bg-red-500 text-white rounded-md px-1 py-.5 ">
                      Not Active
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="ms-auto my-a uto">
              <span className="text-[#121212] text-ellipsis font-Circular  font-size-[16px]  tracking-[-0.425px]">
                {moment(vehicle?.createdAt).format("LL")}
              </span>
            </div>
          </div>
        </div>
      </Link>
      {/* )} */}
    </>
  );
}

function RevenueTableComponent() {
  const [startPage, setStartPage] = useState(0);

  const { walletHistory, getWalletHistoryFunction } = useDataContext();

  useEffect(() => {
    getWalletHistoryFunction();
  }, []);

  const [countPerPage, setCountPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let maxPageLength = walletHistory.data.length;
    setTotalPage(Math.ceil(maxPageLength / countPerPage));
  }, [countPerPage, walletHistory]);

  const nextPageFunction = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageFunction = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <TableContainer maxWidth="100%">
      <Table variant="simple">
        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
        <Thead>
          <Tr>
            <Th className="w-52">Amount</Th>
            <Th className="w-52">Description</Th>
            <Th className="w-52">New Balance</Th>
            <Th className="w-52">Date </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* TODO: this is the owner teable */}
          {walletHistory.data
            ?.slice(
              currentPage * countPerPage,
              (currentPage + 1) * countPerPage
            )
            ?.map((data, index) => (
              // {revenueData?.slice(startPage, -10)?.map((data, index) => (
              <Tr className="" key={index}>
                <Td>₦{data.amount}</Td>
                <Td>{data.description}</Td>
                <Td>₦{data.newBalance}</Td>
                <Td> {moment(data.createdAt).format("llll")}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      {walletHistory.loading && (
        <div className="flex w-full ">
          <Spinner className="mx-auto mt-5" />
        </div>
      )}
      <div className="m-3">
        <button onClick={prevPageFunction} className="btn btn-sm btn-primary">
          Prev
        </button>{" "}
        <span className="mx-3 ">
          {currentPage + 1} of {totalPage}
        </span>
        <button onClick={nextPageFunction} className="btn btn-sm btn-primary">
          Next
        </button>
      </div>
    </TableContainer>
  );
}

export function RiderPaymentTableComponent() {
  const { payments } = useDataContext();

  const [countPerPage, setCountPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let maxPageLength = payments.data.length;
    setTotalPage(Math.ceil(maxPageLength / countPerPage));
  }, [countPerPage, payments]);

  const nextPageFunction = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageFunction = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <div className="mt-3 flex justify-between me-7 mb-5">
        {/* <div className=" border flex gap-5">
          <button className="flex items-center gap-2 border py-1 px-2 rounded hover:bg-slate-300">
            Overdue{" "}
            <div className="bg-red-500 text-white rounded-md min-w-5 h-5.5 px-1 text-sm flex justify-center items-center">
              2
            </div>
          </button>
          <button className="flex items-center gap-2 border py-1 px-2 rounded hover:bg-slate-300">
            Pending{" "}
            <div className="bg-yellow-500 text-white rounded-md min-w-5 h-5.5 px-1 text-sm flex justify-center items-center">
              2
            </div>
          </button>
          <button className="flex items-center gap-2 border py-1 px-2 rounded hover:bg-slate-300">
            Paid{" "}
            <div className="bg-green-500 text-white rounded-md min-w-5 h-5.5 px-1 text-sm flex justify-center items-center">
              2
            </div>
          </button>
        </div> */}
        <h4>{"Remittance Table"}</h4>
        <div className="">
          <select
            name="count"
            id="count"
            className="mx-3 me-5 my-auto border rounded p-0.5"
            onChange={(e) => setCountPerPage(() => e.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <button onClick={prevPageFunction} className="btn btn-sm btn-primary">
            Prev
          </button>{" "}
          <span className="mx-3 ">
            {currentPage + 1} of {totalPage}
          </span>
          <button onClick={nextPageFunction} className="btn btn-sm btn-primary">
            Next
          </button>
        </div>
      </div>
      {payments.loading ? (
        <div className="w-full flex justify-center ">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      ) : (
        <TableContainer maxWidth="100%">
          <Table variant="simple">
            <Thead className=" font-raleway text-[14px] font-semibold leading-[16px] text-left">
              <Tr className="h-[50px] ">
                <Th
                  className="w-52 bg-[#000000]  rounded-tl-[10px]"
                  style={{ color: "#fff" }}
                >
                  Rider
                </Th>
                <Th className="w-52 bg-[#000000] " style={{ color: "#fff" }}>
                  Vehicle
                </Th>
                <Th className="w-52 bg-[#000000] " style={{ color: "#fff" }}>
                  Payment
                </Th>
                <Th className="w-52 bg-[#000000] " style={{ color: "#fff" }}>
                  Status
                </Th>
                <Th className="w-52 bg-[#000000] " style={{ color: "#fff" }}>
                  Amount
                </Th>
                <Th className="w-52 bg-[#000000] " style={{ color: "#fff" }}>
                  Overdue
                </Th>
                <Th
                  className="w-52 bg-[#000000] rounded-tr-[10px]"
                  style={{ color: "#fff" }}
                >
                  Action/Date
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {payments.data
                ?.slice(
                  currentPage * countPerPage,
                  (currentPage + 1) * countPerPage
                )
                .map((data, index) => (
                  // {revenueData?.slice(startPage, -10)?.map((data, index) => (
                  <Tr className="" key={index}>
                    <Td className="p-[14px_17px] border-b border-[#0000001A] text-[#878686] font-raleway text-[14px] font-semibold leading-[16px] text-left">
                      {data.rider.full_name}
                    </Td>
                    <Td className="p-[14px_17px] border-b border-[#0000001A] text-[#878686] font-raleway text-[14px] font-semibold leading-[16px] text-left">
                      {data.vehicle.model}
                    </Td>
                    <Td className="p-[14px_17px] border-b border-[#0000001A] text-[#878686] font-raleway text-[14px] font-semibold leading-[16px] text-left">
                      {data.description}
                    </Td>
                    <Td className="p-[14px_17px] border-b border-[#0000001A] text-[#878686] font-raleway text-[14px] font-semibold leading-[16px] text-left">
                      <div
                        className={`
                        ${data.payment_status === "paid" && "bg-green-500"}
                        ${data.payment_status === "pending" && "bg-yellow-500"}
                        ${data.payment_status === "overdue" && "bg-red-500"}
                        p-0.5 text-center rounded text-white`}
                      >
                        {data.payment_status}
                      </div>
                    </Td>
                    <Td className="p-[14px_17px] border-b border-[#0000001A] text-[#878686] font-raleway text-[14px] font-semibold leading-[16px] text-left">
                      ₦{data.payment_amount}
                    </Td>
                    <Td className="p-[14px_17px] border-b border-[#0000001A] text-[#878686] font-raleway text-[14px] font-semibold leading-[16px] text-left">
                      ₦{data.overdue_charges}
                    </Td>
                    <RiderPaymentButtonComponent payment={data} />
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

function RiderPaymentButtonComponent({ payment }) {
  const { endpoint, token, profile } = useAuthContext();
  const { paystackPublicKey, getPaymentsFunction, getNotificationFunction } =
    useDataContext();

  // console.log({ payment });

  const componentProps = {
    email: profile.email,
    amount: (payment.payment_amount + payment.overdue_charges) * 100, // Convert to kobo
    metadata: {
      riderId: payment.rider._id,
      vehicleId: payment.vehicle._id,
    },
    publicKey: paystackPublicKey,
    text: `Pay ₦${(
      payment.payment_amount + payment.overdue_charges
    ).toLocaleString()} `,
    onSuccess: async (response) => {
      // Payment successful, update database
      const requestResponce = await handelPaymentFunction(response);
      if (requestResponce.statusText === "OK") {
        getNotificationFunction();
        getPaymentsFunction();
        toast.success("Payment successful");
      } else {
        console.log({ requestResponce });
      }
    },
    onClose: () => {
      // Payment modal closed
      console.log("Payment modal closed");
    },
  };
  async function handelPaymentFunction(paymentResponce) {
    // console.log({ paymentResponce });
    // return console.log("handelPaymentFunction", { vID: payment.vehicle });
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/payment`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      data: {
        payment: paymentResponce,
        vehicle_id: payment.vehicle._id,
        payment_id: payment._id,
      },
    };

    try {
      let response = await axios.request(config);
      console.log({ response });
      return response;
    } catch (error) {
      return error.response;
    }
  }
  return (
    <>
      {payment.payment ? (
        <Td>{moment(payment.payment_date).format("MMM Do YYYY")}</Td>
      ) : (
        <Td>
          {" "}
          <PaystackButton
            className="bg-[#000000] flex items-center rounded-md my-auto h-[35px] px-2 text-white"
            {...componentProps}
          />
        </Td>
      )}
    </>
  );
}

function InspectionTableComponent() {
  const [inspectionData, setInspectionData] = useState([
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
    {
      number: "ABJ288XD",
      name: "Qlink Roost",
      scheduledDate: "24/10/24",
      status: "Done",
      nextDate: "25th Jul 2024",
    },
  ]);
  return (
    <TableContainer>
      <Table variant="simple">
        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
        <Thead>
          <Tr>
            <Th className="w-52">Number</Th>
            <Th className="w-52">Name</Th>
            <Th className="w-52">Scheduled date</Th>
            <Th className="w-52">Status</Th>
            <Th className="w-52">Next </Th>
          </Tr>
        </Thead>
        <Tbody>
          {inspectionData.map((data, index) => (
            <Tr key={index}>
              <Td>{data.number}</Td>
              <Td>{data.name}</Td>
              <Td>{data.scheduledDate}</Td>
              <Td>{data.status}</Td>
              <Td>{data.nextDate}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function BookedVehicleListComponent() {
  const { bookedListedVehicle } = useDataContext();
  // useEffect(() => {
  //   console.log({ bookedListedVehicle });
  // }, [bookedListedVehicle]);
  return (
    <>
      {" "}
      {bookedListedVehicle.loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <>
          {bookedListedVehicle.data.length > 0 && (
            <span className="border-y-2 block py-4 my-4 text-[#121212] font-medium text-xl  font-['Circular Std'] leading-[14px]">
              Booked Vehicle{" "}
            </span>
          )}
          {bookedListedVehicle.data.map((vehicle, index) => {
            return (
              <HostedVehiclesCardComponent vehicle={vehicle} key={index} />
            );
          })}
        </>
      )}
    </>
  );
}
function AssignedVehicleComponent() {
  const { assignedVehicle } = useDataContext();

  return (
    <>
      {" "}
      {assignedVehicle.loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <div className=" px-5">
          {/* {ownerListedVehicle.data.map((vehicle, index) => {
            return (
              <HostedVehiclesCardComponent vehicle={vehicle} key={index} />
              );
              })} */}
          {assignedVehicle.data ? (
            <HostedVehiclesCardComponent vehicle={assignedVehicle.data} />
          ) : (
            <div className="flex flex-col  items-center">
              No Active Vehicle
              <div className="mt-5">
                <Link
                  to={"/search"}
                  className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white w-40 justify-center"
                >
                  Rent A Vehicle
                </Link>
              </div>
            </div>
          )}

          {/* <BookedVehicleListComponent /> */}
        </div>
      )}
    </>
  );
}

function PromptNotificationCardComponent({
  type,
  message,
  actionUrl,
  isAction,
}) {
  let color = type === "danger" ? "bg-red-500/50 text-black" : "bg-[#6cd7f8a2]";
  return (
    <div
      className={`flex items-center justify-between px-5 min-h-[60px] w-full max-w-[1128px] border-[2px] border-[#121212] rounded-md ${color}`}
    >
      <b>{message}</b>

      {isAction && (
        <Link
          to={actionUrl}
          className="bg-[#F46702] flex items-center rounded-md text-nowrap h-[40px] px-2 md:px-5 text-white"
        >
          Take Action
        </Link>
      )}
    </div>
  );
}

function NotificationComponent() {
  const { notificationList } = useDataContext();

  const [countPerPage, setCountPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let maxPageLength = notificationList.data.length;
    setTotalPage(Math.ceil(maxPageLength / countPerPage));
  }, [countPerPage, notificationList]);

  const nextPageFunction = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageFunction = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex flex-col px-3 w-full  ">
      <b className="block mt-5">Notification</b>

      {notificationList.loading ? (
        <Spinner className="mx-auto mt-5" />
      ) : (
        <>
          {notificationList.data
            ?.slice(
              currentPage * countPerPage,
              (currentPage + 1) * countPerPage
            )
            .map((notification, index) => {
              return (
                <div
                  key={index}
                  className="bg-[#F3F3F3] rounded-[10px] p-2 my-2 flex flex-col"
                >
                  {notification.message}
                  <div className="text-xs mt-0.5">
                    {" "}
                    {moment(notification.createdAt).format("llll")}
                  </div>
                </div>
              );
            })}
          <div className="mt-3">
            <button
              onClick={prevPageFunction}
              className="btn btn-sm btn-primary"
            >
              Prev
            </button>{" "}
            <span className="mx-3 ">
              {currentPage + 1} of {totalPage}
            </span>
            <button
              onClick={nextPageFunction}
              className="btn btn-sm btn-primary"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

function PaymentPromptNotificationComponent({ payment }) {
  const { endpoint, token, profile } = useAuthContext();
  const { paystackPublicKey, getPaymentsFunction, getNotificationFunction } =
    useDataContext();

  // useEffect(() => {
  //   console.log({ payment });
  // }, [payment]);

  const componentProps = {
    email: profile.email,
    amount: (payment.payment_amount + payment.overdue_charges) * 100, // Convert to kobo
    metadata: {
      riderId: payment.rider._id,
      vehicleId: payment.vehicle._id,
    },
    publicKey: paystackPublicKey,
    text: `Make ₦${(
      payment.payment_amount + payment.overdue_charges
    ).toLocaleString()} Payment`,
    onSuccess: async (response) => {
      // Payment successful, update database
      const requestResponce = await handelPaymentFunction(response);

      if (requestResponce.statusText === "OK") {
        getNotificationFunction();
        getPaymentsFunction();
        toast.success("Payment successful");
      } else {
        console.log({ requestResponce });
      }
    },
    onClose: () => {
      toast.info("Payment modal closed");
      // Payment modal closed
      console.log("Payment modal closed");
    },
  };

  async function handelPaymentFunction(paymentResponce) {
    // console.log({ paymentResponce });
    // console.log({ payment });
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/payment?payment_id=${payment._id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      data: { payment: paymentResponce, vehicle_id: payment.vehicle._id },
    };

    try {
      let response = await axios.request(config);
      // console.log({ response });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  return (
    <div
      className={`"payment-container border-[2px] border-[#121212] rounded-md px-3 h-14 mt-3 flex justify-between items-center ${
        payment.payment_status === "overdue" && "bg-red-500/50 "
      }`}
    >
      {/* Weekly Remittance Payment */}
      <div className="flex gap-2 items-center">
        {payment.description}{" "}
        {payment.overdue_charges ? (
          <div className="bg-red-900 text-white rounded py-0.5 p-2 ">
            +{payment.overdue_charges}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>{moment(payment.payment_due_date, "YYYYMMDD").fromNow()}</div>
      <PaystackButton
        className="bg-[#F46702] flex items-center rounded-md h-[40px] px-5 text-white"
        {...componentProps}
      />
    </div>
  );
}

export function CreateWalletModelContentComponent({ setIsModalOpen, wallet }) {
  const { endpoint, token, getProfileUpdateFunction } = useAuthContext();
  let oldIndex = 10;
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [accountInfo, setAccountInfo] = useState({});
  useEffect(() => {
    getBanksCodes();

    if (wallet) {
      setAccountInfo({
        account_number: wallet.bank.account_number,
        bank: wallet.bank.bank,
        account_name: wallet.bank.account_name,
        selectedBank: wallet.bank.selectedBank,
      });
    }
  }, []);

  useEffect(() => {
    if (
      !accountInfo?.account_number ||
      accountInfo?.account_number?.length < 10
    ) {
      // Update state with the account name on account number change
      setAccountInfo((prev) => ({
        ...prev,
        account_name: null,
      }));
      return;
    }
    if (!accountInfo.selectedBank) {
      return;
    }
    setLoading(true);
    // Define an async function within the useEffect
    const fetchAccountDetails = async () => {
      try {
        const accountDetail = await VerifyAccountNumber(
          accountInfo.account_number,
          accountInfo.selectedBank.code
        );
        // console.log("Account Detail:", accountDetail);

        // Update state with the account name from the API
        setAccountInfo((prev) => ({
          ...prev,
          account_name: accountDetail.account_name,
        }));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
        console.error("Account verification failed:", error);
      }
    };

    // Call the async function
    fetchAccountDetails();

    // Optional cleanup if needed
  }, [accountInfo.account_number, accountInfo.selectedBank]);

  function getBanksCodes() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/withdrawal/banks`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.okay) {
          // console.log(response.data.banks);
          setBanks(response.data.banks);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function VerifyAccountNumber(account_number, bank_code) {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/withdrawal/resolve-account?account_number=${account_number}&bank_code=${bank_code}`,
      headers: {
        Authorization: token,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.data.okay) {
        return response.data.accountDetail; // Return account detail if the request was successful
      } else {
        throw new Error("Account verification failed");
      }
    } catch (error) {
      console.error("Error in VerifyAccountNumber:", error);
      throw error; // Rethrow the error so the caller can handle it
    }
  }

  async function createAccountFunction(account_number, bank_code) {
    const data = {
      pin: accountInfo.pin,
      bank: { ...accountInfo },
    };

    // return console.log({ data });
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/wallet/create`,
      headers: {
        Authorization: token,
      },
      data,
    };

    try {
      const response = await axios.request(config);
      console.log({ response });
      if (response.data.ok) {
        // return response.data.accountDetail; // Return account detail if the request was successful
        getProfileUpdateFunction();
        setIsModalOpen(false);
      } else {
        toast.error(response.data.message);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast.error("Error in creating wallet");
      console.error("Error in creating wallet:", error);
      // throw error; // Rethrow the error so the caller can handle it
    }
  }

  async function updateAccountFunction() {
    const data = {
      pin: accountInfo.pin,
      bank: { ...accountInfo },
    };

    if (!data.pin || data.pin === "" || data.pin.length < 4) {
      return toast.error("Enter Valid Pin");
    }
    // return console.log({ data });
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/wallet/update`,
      headers: {
        Authorization: token,
      },
      data,
    };

    try {
      const response = await axios.request(config);
      console.log({ response });
      if (response.data.ok) {
        // return response.data.accountDetail; // Return account detail if the request was successful
        getProfileUpdateFunction();
        setIsModalOpen(false);
      } else {
        toast.error(response.data.message);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast.error("Error in creating wallet");
      console.error("Error in creating wallet:", error);
      // throw error; // Rethrow the error so the caller can handle it
    }
  }

  function handelInputChanges(e) {
    // console.log(e.target.name, e.target.value);
    if (e.target.name === "account_number" && e.target.value.length > 10)
      return;
    setAccountInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handelSelectChanges(name, value) {
    // Update state with the account name on bank change
    setAccountInfo((prev) => ({
      ...prev,
      account_name: null,
    }));
    const selectedBank = banks.find((bank) => bank.name === value); // Find the bank object by name
    setAccountInfo((prev) => {
      return {
        ...prev,
        [name]: value,
        selectedBank: selectedBank,
      };
    });
  }

  return (
    <div className="fixed inset-0 bg-[#A3A3A333]/20 backdrop-blur-[3px] shadow-lg border border-white/20 flex items-center justify-center left-[240px]">
      <div
        className="w-[536px] p-[28px_24px_28px_24px] gap-[27px] rounded-[12px]  bg-white
"
      >
        <div className="flex justify-between mb-4 items-center">
          <h2 className="font-raleway text-lg font-semibold leading-[23.48px] text-center ">
            Create account
          </h2>
          <button className="" onClick={() => setIsModalOpen(false)}>
            <PopUpCloseIconSvg />
          </button>
        </div>
        {/* <p
          className="font-raleway text-[18px] font-semibold leading-[20px] text-center text-[#000000]
"
        >
          Create a 4 digit pin to withdraw
        </p> */}
        <InputField
          // icon={field.icon}
          label={"Account Number"}
          name={"account_number"}
          handelInputChanges={handelInputChanges}
          stateData={accountInfo}
          // key={`accountInfoFields`}
          type={"number"}
        />

        <SelectDropDownField
          label={wallet ? accountInfo.bank : "Select Bank"}
          optionData={banks?.map((bank) => bank.name)}
          className=" my-3 w-full h-[50px]"
          stateData={accountInfo}
          // key={index}
          name={"bank"}
          handelSelectChanges={handelSelectChanges}
          oldIndex={wallet ? oldIndex : null}
        />

        {!accountInfo?.account_number ||
        accountInfo?.account_number?.length < 10 ? (
          <span>Enter valid account number.</span>
        ) : !accountInfo?.selectedBank ? (
          <span>Select a bank.</span>
        ) : loading ? (
          <span>Loading...</span>
        ) : accountInfo?.account_name ? (
          <span className="text-[20px] mt-2 mb-5">
            <b>Account Name:</b> {accountInfo.account_name}
          </span>
        ) : (
          <span>No account name available.</span>
        )}

        {accountInfo?.account_name && (
          <>
            <InputField
              // icon={field.icon}
              label={"Withdrawal Pin"}
              name={"pin"}
              handelInputChanges={handelInputChanges}
              stateData={accountInfo}
              type={"password"}
            />
            {!accountInfo.pin || accountInfo.pin.length < 4 ? (
              <span>Enter valid account number.</span>
            ) : null}

            {wallet ? (
              <button
                className="w-[476px] h-[52px] rounded-[100px] bg-[#000] text-[#fff] mt-[27px]"
                onClick={() => updateAccountFunction()}
              >
                Update Wallet
              </button>
            ) : (
              <>
                {!accountInfo.pin ||
                  (accountInfo.pin.length >= 4 && (
                    <button
                      className="w-[476px] h-[52px] rounded-[100px] bg-[#000] text-[#fff] mt-[27px]"
                      // className="bg-[#F46702] flex items-center justify-center rounded-md h-[40px] px-5 text-white mt-5 "
                      onClick={() => createAccountFunction()}
                    >
                      Create Wallet
                    </button>
                  ))}
              </>
            )}
          </>
        )}
        <p className="font-raleway text-[13px] font-semibold leading-[22px] text-left text-[#00000066] mt-5">
          Your personal data will be used to process your order, support your
          experience throughout this website, and for other purposes described
          in our privacy policy.
        </p>
      </div>
    </div>
  );
}
