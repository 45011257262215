import React, { useMemo, useState } from "react";
import { useAuthContext } from "../../Context/authContext";
import { StatCard } from "../../components/statscard/StatsCard";
import { Spinner } from "@chakra-ui/react";
import WithdrawalListComponent from "../../components/table/react-tables/WithdrawalList";
import { useDataContext } from "../../Context/dataContext";
import { toast } from "react-toastify";
import axios from "axios";
import { PopUpCloseIconSvg } from "../../icons";

function WithdrawalPage() {
  const { profile, accountType } = useAuthContext();
  const [withdrawalScreen, setWithdrawalScreen] = useState(false); // Modal visibility

  const { listedVehicle } = useDataContext();

  const statCards = useMemo(
    () => [
      {
        title: "Wallet Balance",
        value:
          profile?.wallet?.balance !== undefined ? (
            `₦${profile?.wallet?.balance.toLocaleString()}`
          ) : (
            <Spinner />
          ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/76ba05aeeafdd95ef1416e5e890d8ffef4fb04fd9ee1b4e2b2f95ecb4e84123e?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Total Withdrawn",
        value:
          profile?.wallet?.balance !== undefined ? (
            `₦${profile?.wallet?.total_withdrawn?.toLocaleString() || 0}`
          ) : (
            <Spinner />
          ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/76ba05aeeafdd95ef1416e5e890d8ffef4fb04fd9ee1b4e2b2f95ecb4e84123e?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Amount this week",
        // map through vehicle and get active once and multiply it with the fixed price
        value: !listedVehicle?.loading ? (
          `₦${(
            listedVehicle.data.filter(
              (item) =>
                item.active_vehicle &&
                item.rider !== null &&
                item.verified_vehicle
            ).length * 13000
          ).toLocaleString()}`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a51ba61aa98bf32263b0bb5a2c49f27fc46daa868b04f2b85d6f793d8c66d845?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Active bikes",
        // map through vehicle and get active length
        value: !listedVehicle?.loading ? (
          `${
            listedVehicle.data.filter(
              (item) =>
                item.active_vehicle &&
                item.rider !== null &&
                item.verified_vehicle
            ).length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4cb079b41cc57ea4d0e3ded7d5de408532d977827f6fe4defef7051bddebc3da?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
      {
        title: "Inactive bikes",
        // map through vehicle and get nonactive length
        value: !listedVehicle?.loading ? (
          `${
            listedVehicle.data.filter(
              (item) =>
                !item.active_vehicle ||
                item.rider === null ||
                !item.verified_vehicle
            ).length
          }`
        ) : (
          <Spinner />
        ),
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/eef1ee6798421addd02719d5a573e95df5da63d00dccf84aa691bb090df1355a?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb",
      },
    ],
    [profile?.wallet?.balance, listedVehicle]
  );

  return (
    <main className="flex over flow-hidden flex-col pb-56 max-md:pb-24 ">
      {!withdrawalScreen ? (
        <>
          {" "}
          <section className="w-full  flex px-5 ">
            <div className="w-full h-[100px] py-5 justify-between items-center gap-[84 0px] inline-flex mx-auto">
              <div className="text-[#121212] text-[25px] md:text-[32px] font-['Circular Std'] leading-normal ">
                {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
                Withdrawal
              </div>
              <button
                className="w-[177px] h-[46px] flex justify-center items-center gap-[12px] rounded-[6px] bg-[#00C814] text-[#F9F9F9]"
                onClick={() => setWithdrawalScreen(true)}
              >
                Withdraw{" "}
                <div className="icon-container">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1620_5952)">
                      <path
                        d="M10 0L12.5 3.75H11.25V6.25H8.75V3.75H7.5L10 0ZM18.75 8.75V18.75H1.25V8.75H18.75ZM20 7.5H0V20H20V7.5Z"
                        fill="white"
                      />
                      <path
                        d="M10 10C10.4925 10 10.9801 10.097 11.4351 10.2855C11.89 10.4739 12.3034 10.7501 12.6517 11.0983C12.9999 11.4466 13.2761 11.86 13.4645 12.3149C13.653 12.7699 13.75 13.2575 13.75 13.75C13.75 14.2425 13.653 14.7301 13.4645 15.1851C13.2761 15.64 12.9999 16.0534 12.6517 16.4017C12.3034 16.7499 11.89 17.0261 11.4351 17.2145C10.9801 17.403 10.4925 17.5 10 17.5H16.25V16.25H17.5V11.25H16.25V10H10ZM6.25 13.75C6.25 12.7554 6.64509 11.8016 7.34835 11.0983C8.05161 10.3951 9.00544 10 10 10H3.75V11.25H2.5V16.25H3.75V17.5H10C9.00544 17.5 8.05161 17.1049 7.34835 16.4017C6.64509 15.6984 6.25 14.7446 6.25 13.75Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1620_5952">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </button>
            </div>
          </section>
          <section className="flex w-full mx-auto">
            <div className="w-full">
              {accountType === "owner" && (
                <>
                  {" "}
                  {!profile ? (
                    <div className="flex justify-center mt-10">
                      <Spinner className="mx-auto" />
                    </div>
                  ) : (
                    <div className="flex gap-3 flex-wrap justify-evenly lg:justify-start">
                      {/* <div className="flex flex-wrap gap-6 items-center self-stretch mt-11 max-md:mt-10 max-md:max-w-full"> */}
                      {statCards.map((card, index) => (
                        <StatCard key={index} {...card} />
                      ))}
                      {/* </div> */}
                    </div>
                  )}
                </>
              )}
              <WithdrawalListComponent />
            </div>
          </section>
        </>
      ) : (
        <WithdrawalScreen setWithdrawalScreen={setWithdrawalScreen} />
      )}
    </main>
  );
}

export default WithdrawalPage;

function WithdrawalScreen({ setWithdrawalScreen }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility

  const { profile, accountType } = useAuthContext();
  const { endpoint, token } = useAuthContext();

  const [amount, setAmount] = useState(0);
  const { bank, balance } = profile.wallet;
  const [pin, setPin] = useState(["", "", "", ""]);

  const handleEnterPin = (e, index) => {
    const value = e.target.value;
    if (!/^\d$/.test(value) && value !== "") return; // Allow only a single digit

    const newPin = [...pin];
    newPin[index] = value; // Update the specific digit
    setPin(newPin);

    // Move focus to the next input if a digit is entered
    if (value && index < 3) {
      document.getElementById(`pin-input-${index + 1}`).focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !pin[index] && index > 0) {
      document.getElementById(`pin-input-${index - 1}`).focus();
    }
  };
  async function handelWithdrawFunction() {
    if (pin.join("").length !== 4) {
      return toast.error("Please enter a 4-digit PIN.");
    }
    let data = {
      accountNumber: bank.account_number,
      bankCode: bank.selectedBank.code,
      requested_amount: amount,
      pin: pin.join(""),
    };

    // return console.log({ data });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${endpoint}/withdrawal/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      data,
    };

    try {
      let response = await axios.request(config);
      // console.log({ response });
      setPin(["", "", "", ""]);

      if (response.data.okay) {
        setIsModalOpen(false);
        toast.success("withdrawal Successful");
      } else {
        setIsModalOpen(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      toast.error(error?.response?.data?.error?.message);
      setIsModalOpen(false);
      // console.log(error.response);
    }
  }
  return (
    <div className="bg-re d-500">
      <button onClick={() => setWithdrawalScreen(false)}>Back</button>{" "}
      <h2 className="font-raleway text-[24px] font-bold leading-[28px] tracking-[-0.02em] text-center w-[476px] mx-auto border-b-2 py-[8px]">
        Withdrawal Details
      </h2>
      <div className="withdrawal-form flex flex-col gap-[17px] mx-auto w-[440px] mt-10">
        <label className="flex flex-col gap-[8px]">
          <b className="font-raleway text-[14px] font-medium leading-[16.44px] text-left text-[#cccccc]">
            Account Name
          </b>
          <input
            type="text"
            value={bank.account_name}
            className="w-full h-[53px] p-[12px_17px] gap-[360px] rounded-[5px] bg-[#F3F3F4] text-[#999999] font-raleway text-[18px] font-semibold leading-[28.8px] text-left "
          />
        </label>
        <label className="flex flex-col gap-[8px]">
          <b className="font-raleway text-[14px] font-medium leading-[16.44px] text-left text-[#cccccc]">
            Account Number
          </b>
          <input
            type="text"
            value={bank.account_number}
            className="w-full h-[53px] p-[12px_17px] gap-[360px] rounded-[5px] bg-[#F3F3F4] text-[#999999] font-raleway text-[18px] font-semibold leading-[28.8px] text-left "
          />
        </label>

        <div className="flex gap-[20px]">
          <label className="flex flex-col gap-[8px]">
            <b className="font-raleway text-[14px] font-medium leading-[16.44px] text-left text-[#cccccc]">
              Bank
            </b>
            <input
              type="text"
              value={bank.bank}
              className="w-full h-[53px] p-[12px_17px] gap-[360px] rounded-[5px] bg-[#F3F3F4] text-[#999999] font-raleway text-[18px] font-semibold leading-[28.8px] text-left "
            />
          </label>
          <label className="flex flex-col gap-[8px]">
            <b className="font-raleway text-[14px] font-medium leading-[16.44px] text-left text-[#cccccc]">
              Amount
            </b>
            <input
              type="text"
              value={amount.toLocaleString()}
              placeholder={`₦${balance.toLocaleString()}`}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              className="w-full h-[53px] p-[12px_17px] gap-[360px] rounded-[5px] bg-[#F3F3F4] text-[#999999] font-raleway text-[18px] font-semibold leading-[28.8px] text-left "
            />
          </label>
        </div>

        <label className="flex gap-[16px] items-center">
          <input type="checkbox" name="" id="" />
          <span className="font-inter text-[16px] font-normal leading-[20px] text-left ">
            Save card details
          </span>
        </label>

        <button
          className="w-[390px] h-[53px] flex justify-center items-center  rounded-[50px] mx-auto text-white bg-black "
          onClick={() => {
            // if (amount < 13000) {
            //   return toast.error("Min. Withdrawal is ₦13,000");
            // }
            if (amount > balance) {
              return toast.error("Insufficient balance");
            }

            setIsModalOpen(true);
          }}
        >
          Withdraw ₦{amount.toLocaleString() || 0}
        </button>

        <p className="font-raleway text-[13px] font-semibold leading-[22px] text-left text-[#00000066]">
          Your personal data will be used to process your order, support your
          experience throughout this website, and for other purposes described
          in our privacy policy.
        </p>
      </div>
      {/* Modal for  Withdrawal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-[#A3A3A333]/20 backdrop-blur-[3px] shadow-lg border border-white/20 flex items-center justify-center left-[240px]">
          <div
            className="w-[536px] p-[28px_24px_28px_24px] gap-[27px] rounded-[12px]  bg-white
"
          >
            <div className="flex justify-between mb-4 items-center">
              <h2 className="font-raleway text-lg font-semibold leading-[23.48px] text-center ">
                Withdrawal
              </h2>
              <button className="" onClick={() => setIsModalOpen(false)}>
                <PopUpCloseIconSvg />
              </button>
            </div>
            <p
              className="font-raleway text-[18px] font-semibold leading-[20px] text-center text-[#000000]
"
            >
              Create a 4 digit pin to withdraw
            </p>

            <div className="pin-form flex gap-[24px] justify-center mt-5">
              {pin.map((digit, index) => (
                <input
                  key={index}
                  id={`pin-input-${index}`}
                  type="text"
                  maxLength={1} // Ensures only 1 digit is allowed
                  value={digit}
                  onChange={(e) => handleEnterPin(e, index)}
                  onKeyDown={(e) => handleBackspace(e, index)}
                  className="w-[50px] h-[52px] text-center text-lg font-semibold rounded-[4px] border-[1.5px]"
                />
              ))}
            </div>

            <button
              className="w-[476px] h-[52px] rounded-[100px] bg-[#000] text-[#fff] mt-[27px]"
              onClick={() => handelWithdrawFunction()}
            >
              Continue
            </button>

            <p className="font-raleway text-[13px] font-semibold leading-[22px] text-left text-[#00000066]">
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our privacy policy.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
