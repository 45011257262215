import { useEffect, useState } from "react";

export function InputField({
  label,
  icon,
  className = "",
  handelInputChanges,
  stateData,
  name,
  type,
  maxLength,
  disabled,
}) {
  return (
    <label
      className={`flex w-full h-[52px] p-2 px-4 gap-2 rounded-md bg-[#F3F3F4] ${className}`}
      // className={`flex overflow-hidden flex-wrap gap-4 items-center px-7 py-7 w-full whitespace-nowrap rounded-md border border-solid border-neutral-900 min-h-[84px] max-md:px-5 ${className}`}
    >
      {icon && (
        <img
          loading="lazy"
          src={icon}
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-5 aspect-[0.83]"
        />
      )}

      <input
        type={type || "text"}
        aria-label={label}
        className="sr- only size-full font-raleway bg-transparent text-[16px] font-semibold leading-[25.6px] text-left text-[#292929CC] bor der focus:outline-none"
        name={name}
        value={stateData[name]}
        placeholder={stateData[name] || label}
        // value={""}
        onChange={(e) => {
          // console.log(e.target.value);
          handelInputChanges(e);
        }}
        disabled={disabled || false}
      />
    </label>
  );
}

export function SelectDropDownField({
  name,
  handelSelectChanges,
  label,
  optionData,
  className,
  stateData,
  oldIndex,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    oldIndex || null
  );
  const [searchQuery, setSearchQuery] = useState(""); // state for search query
  // Filter options based on search query
  const filteredOptions = optionData.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (selectedOptionIndex === null) {
      handelSelectChanges(name, "");
      return;
    }
    if (searchQuery === "") {
      handelSelectChanges(name, optionData[selectedOptionIndex]);
    } else {
      handelSelectChanges(name, filteredOptions[selectedOptionIndex]);
    }
  }, [selectedOptionIndex, searchQuery]);

  return (
    <div className={`relative -z-1 w-full ${className} cursor-pointer`}>
      <div
        className="flex flex-wrap gap-4 items-center w-full h-[52px] p-2 px-4 rounded-md bg-[#F3F3F4] font-raleway text-[16px] font-semibold leading-[25.6px] text-left text-[#292929CC]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          loading="lazy"
          src={
            "https://cdn.builder.io/api/v1/image/assets/TEMP/8817e337f08e011aebe2e8256fd86234b8db663315e00994a6c9f92bf61bcd54?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43"
          }
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-5 aspect-[0.83]"
        />
        <div
          className={`${selectedOptionIndex !== null && "text-neutral-950"}`}
        >
          {selectedOptionIndex === null ? (
            <>Select {label}...</>
          ) : (
            <>{stateData[name]}</>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-[200] border border-solid border-neutral-300 border-t-0 w-full p-2 bg-white max-h-[400px] overflow-y-scroll">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
            className="border w-full px-3 h-10 mb-2"
          />
          <div
            className="p-2 cursor-pointer mb-2"
            onClick={() => {
              setSelectedOptionIndex(null);
              setIsOpen(false);
              setSearchQuery(""); // Clear search query
            }}
          >
            Select {label}...
          </div>

          {/* Render filtered options based on the search query */}
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                className="p-2 cursor-pointer mb-2"
                key={index}
                onClick={() => {
                  setSelectedOptionIndex(index);
                  setIsOpen(false);
                }}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No results found</div>
          )}
        </div>
      )}
    </div>
  );
}
