import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import NavigationComponent from "../../components/navigation/NavigationComponent";
import ModelPopupComponent from "../../components/modalPopup/ModelPopupComponent";
import {
  BackArrowIconSvg,
  GoldProfileStarIconSvg,
  MapLocationIconSvg,
  MessageIconSvg,
  MoneyPrizeIconSvg,
  PhoneIconSvg,
  RightCoveArrowIconSvg,
  TickCheckBoxIconSvg,
} from "../../icons";

import { ModalContext } from "../../Context/modalContext";
import { Spinner } from "@chakra-ui/react";
import { useAuthContext } from "../../Context/authContext";
import { useDataContext } from "../../Context/dataContext";
import axios from "axios";
import { toast } from "react-toastify";
import { FaBackward } from "react-icons/fa";
import { FaArrowLeftLong, FaArrowPointer } from "react-icons/fa6";
import { PaystackButton } from "react-paystack";
import moment from "moment";

function VehicleDetailsPage() {
  let navigate = useNavigate();
  const { endpoint, token, accountType, profile } = useAuthContext();
  const {
    cancelledInspectionFunction,
    getVehicleDetailFunction,
    vehicleDetail,
  } = useDataContext();
  const { carID } = useParams();

  useEffect(() => {
    getVehicleDetailFunction(carID);
  }, []);
  const currentPathname = window.location.pathname;

  return (
    <main className="flex overflow-hidden flex-col pb-2 bg-white">
      <NavigationComponent />
      <section className="flex flex-wrap gap-10 ite ms-start  mt-6 px-5">
        {vehicleDetail.loading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            className="mt-10"
          />
        ) : (
          <div className="w-full flex flex-wrap">
            <div className="flex flex-col  w-full md:w-[35%]">
              <button
                className="float-start border rounded-full mb-5 w-8 h-8 flex justify-center items-center"
                onClick={() => navigate(-1)}
              >
                <FaArrowLeftLong />
              </button>
              <ImageGallery images={vehicleDetail?.data?.vehicle_images} />
              <VehicleInfo vehicleDetail={vehicleDetail?.data} />
            </div>

            <div className="flex flex-col mb-5 w-full md:w-[65%] mt-5 md:mt-0 md:ps-5">
              <Features feature={vehicleDetail?.data?.features} />
              <HealthStatus health={vehicleDetail?.data?.health_status} />
              {/* <AdditionalNote /> */}
              <div className="mt-5">
                {" "}
                <OwnerProfileCardComponent
                  profile={vehicleDetail?.data?.vehicle_owner}
                />
              </div>
              {/*  */}
              <div className="mt-5">
                <b> {vehicleDetail.data?.booked_inspection?.length}</b> Already
                Book Vehicle for inspection
              </div>

              {token &&
                accountType !== "owner" &&
                vehicleDetail.data?.booked_inspection?.some(
                  (item) => item.rider === profile._id
                ) && (
                  <>
                    <div className="flex w-full gap-3">
                      <ModelPopupComponent
                        trigger={
                          <button
                            // className="gap-2.5 px-2.5 py-5 mt-5 max-w-full text-xl font-medium leading-none text-center text-white bg-orange-600 rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-full md:w-full max-md:mt-10"
                            className="flex justify-center items-center mt-8 text-sm md:text-xl font-medium text-white bg-orange-600 rounded-lg h-[50px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-full"
                          >
                            Confirm Inspection
                          </button>
                        }
                      >
                        <GetVehicleModelContentComponent startFrom={2} />
                      </ModelPopupComponent>
                      <button
                        className="flex justify-center items-center mt-8 text-sm md:text-xl font-medium text-white bg-red-600 rounded-lg h-[50px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-full"
                        // className="gap-2.5 px-2.5 py-5 mt-5 max-w-full text-xl font-medium leading-none text-center text-white bg-red-600 rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-full md:w-2/4 max-md:mt-10"
                        onClick={() => {
                          if (window.confirm("Cancel Inspection?")) {
                            cancelledInspectionFunction(
                              vehicleDetail?.data?._id
                            );
                            navigate(-1);
                          }
                        }}
                      >
                        Cancel Inspection
                      </button>
                    </div>
                  </>
                )}
              {token &&
                `${vehicleDetail?.data?.rider}` === `${profile._id}` && (
                  <button className="gap-2.5 px-2.5 py-5 mt-5 max-w-full text-xl font-medium leading-none text-center text-white bg-orange-600 rounded-lg min-h-[50px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] md:w-[571px] w-full max-md:mt-10">
                    Report Inspection
                  </button>
                )}
              {token &&
                accountType !== "owner" &&
                !vehicleDetail.data?.booked_inspection?.some(
                  (item) => item.rider === profile._id
                ) &&
                `${vehicleDetail?.data?.rider}` !== `${profile._id}` &&
                profile.vehicle === null && (
                  <ModelPopupComponent
                    trigger={
                      <button className="gap-2.5 self-stretch px-2.5 py-5 mt-5 max-w-full text-xl font-medium leading-none text-center text-white bg-orange-600 rounded-lg min-h-[65px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-full max-md:mt-10">
                        Get Vehicle
                      </button>
                    }
                  >
                    <GetVehicleModelContentComponent />
                  </ModelPopupComponent>
                )}
              {!token && (
                <Link
                  to={"/auth/login"}
                  className="flex justify-center items-center mt-8 text-sm md:text-xl font-medium text-white bg-orange-600 rounded-lg h-[50px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-full"
                >
                  Login To Rent Vehicle
                </Link>
              )}
            </div>
          </div>
        )}
      </section>
    </main>
  );
}

export default VehicleDetailsPage;

function ImageGallery({ images }) {
  const [activeImage, setActiveImage] = useState();

  useEffect(() => {
    // console.log(images);
    setActiveImage(
      images[0] ||
        "https://archive.org/download/placeholder-image/placeholder-image.jpg"
    );
  }, [images]);

  return (
    <div className="flex flex-col w -full max-w -[508px] ">
      <div className="flex overflow-hidden z-0 flex-col w-full bg-white rounded-xl ">
        <img
          loading="lazy"
          src={activeImage}
          alt={"Car Image"}
          className="object-cover w-full aspect-[1.68] "
        />
      </div>
      <div className="flex overflow-scroll z-0 fl ex-wrap gap-3.5 items-center mt-3 w-full">
        {images?.slice(1)?.map((image, index) => (
          <img
            key={index}
            loading="lazy"
            src={image}
            alt={"Car Image"}
            className="object-contain shrink-0 self-stretch my-auto rounded-lg aspect-[1.12] w-[110px]"
            onClick={() => {
              setActiveImage(image);
            }}
          />
        ))}
      </div>
    </div>
  );
}

function VehicleInfo({ vehicleDetail }) {
  const vehicleDetails = [
    { label: "To be taken daily:", value: vehicleDetail.timeline_rent },
    { label: "Available on:", value: vehicleDetail.available_date },
    { label: "Distance allowed:", value: vehicleDetail.distance_allowed },
    // NOTE: price is still dommy
    { label: "Prize:", value: "35,000/day" },
    {
      label: "Pickup location:",
      value: vehicleDetail.pick_up_location,
    },
  ];

  return (
    <section className="flex flex-col mt-10  tracking-tight text-neutral-900">
      <h1 className="text-3xl md:text-4xl font-bold leading-none">
        {" "}
        {vehicleDetail.vehicle_company} {vehicleDetail.model}
      </h1>
      <div className="flex flex-col mt-10 text-lg md:text-xl leading-6 md:font-[450] ">
        {vehicleDetails.map((detail, index) => (
          <div key={index} className={index > 0 ? "mt-8" : ""}>
            <span className="font-medium">{detail.label}</span> <br />
            <span
              className={
                detail.label === "Pickup location:"
                  ? "text-neutral-900 text-opacity-80"
                  : "text-neutral-900"
              }
            >
              {detail.value}
            </span>
          </div>
        ))}
      </div>
    </section>
  );
}

function Features({ feature }) {
  function checkValue(value) {
    if (value) {
      return "Yes";
    } else {
      return "No";
    }
  }
  const features = [
    { name: "Gear transmission:", value: feature.gear_transmission },
    { name: "Bluetooth:", value: checkValue(feature.bluetooth) },
    { name: "Android Auto:", value: checkValue(feature.android_auto) },
    { name: "Rear Camera:", value: checkValue(feature.rear_camera) },
    { name: "GPS:", value: checkValue(feature.gps) },
    { name: "USB input:", value: checkValue(feature.usb_in) },
    { name: "AUX input:", value: checkValue(feature.aux_in) },
    { name: "USB output:", value: checkValue(feature.usb_out) },
    // { name: "USB output:", value: checkValue(feature.aux_out) },
  ];

  return (
    <section className="flex flex-col justify-center max-w-full">
      {/* <div className="flex flex-col w-full text-neutral-900"> */}
      {/* <div className="flex flex-col w-full font-medium "> */}
      <h2 className="text-2xl leading-none max-md:max-w-full">Features</h2>
      <div className="flex flex-wrap gap-10 items-center mt-8 w-full text-xl leading-6">
        {features.map((feature, index) => (
          <div
            key={index}
            className="font-[450] md:font-[500] grow shrink my-auto  w-[149px]"
          >
            {feature.name} <br />
            <span className="font-[350] md:font-[450] text-neutral-900">
              {feature.value}
            </span>
          </div>
        ))}
      </div>
      {/* </div> */}
      {/* </div> */}
    </section>
  );
}

function HealthStatus({ health }) {
  const healthDetails = [
    { label: "Body and exteriors:", status: health.body_exterior },
    { label: "Engine:", status: health.engine },
  ];

  return (
    <section className="flex flex-col mt-12 font-medium  max-md:mt-10">
      <h2 className="text-2xl leading-none">Health status</h2>
      <div className="flex flex-col mt-8 gap-8 w-full text-xl leading-6">
        {healthDetails.map((detail, index) => (
          <div key={index}>
            {detail.label} <br />
            <span className="font-[400] text-neutral-900">{detail.status}</span>
          </div>
        ))}
      </div>
      <div className="flex flex-col mt-12 w-full text-xl ">
        <h2 className="font-medium leading-none max-md:max-w-full">
          Additional note:
        </h2>
        <div className="overflow-hidden px-2 p-4 min-h-24 mt-8 leading-none bg-white rounded-md font-[400] border border-gray-400 ">
          {health.Additional_note}
        </div>
      </div>
    </section>
  );
}

// function AdditionalNote() {
//   return (
//     <section className="flex flex-col mt-12 w-full text-xl max-md:mt-10 max-md:max-w-full">
//       <h2 className="font-medium leading-none max-md:max-w-full">
//         Additional note:
//       </h2>
//       <div className="overflow-hidden px-2 pt-2 pb-16 mt-8 max-w-full leading-none bg-white rounded-md font-[450] w-[567px] max-md:pr-5">
//         I have standby fuel you can pay for.
//       </div>
//     </section>
//   );
// }

// function VehicleOwner() {
//   // NOTE: the Owner details is not filled

//   return (
//     <section className="flex flex-col max-w-full mt-5">
//       <h2 className="text-2xl font-medium leading-none text-neutral-900">
//         Vehicle owner
//       </h2>
//       <div className="flex gap-2 items-center mt-8 w-full text-xl leading-tight">
//         <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/4f5961df5a18668677e1644a9547e8e7ea56da60721378b0fc3508ed42257778?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
//           alt="Vehicle owner"
//           className="object-contain shrink-0 self-stretch my-auto w-20 rounded-none aspect-[0.94]"
//         />
//         <div className="flex flex-col justify-center self-stretch my-auto">
//           <div className="font-[450] text-neutral-900">Paschal</div>
//           <div className="mt-1 font-light text-neutral-900 text-opacity-70">
//             25 rents | Since July 2024
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

function GetVehicleModelContentComponent({ startFrom }) {
  const { handelBookVehicleInspectionFunction } = useDataContext();
  const { closeModelFunction } = useContext(ModalContext);
  const [currentStep, setCurrentStep] = useState(startFrom || 0);
  const [progressSteps, setProgressSteps] = useState([
    {
      step: 1,
      content: (
        <GuidelineComplianceModelBody
          closeModelFunction={closeModelFunction}
          action={nextStepFunction}
        />
      ),
    },
    {
      step: 2,
      content: (
        <VehicleOwnerModelBody
          closeModelFunction={closeModelFunction}
          action={handelBookVehicleInspectionFunction}
        />
      ),
    },
    {
      step: 3,
      content: (
        <ApproveVehicleHealthModelBody
          closeModelFunction={closeModelFunction}
          action={nextStepFunction}
        />
      ),
    },
    {
      step: 4,
      content: (
        <VehiclePaymentModelBody
          closeModelFunction={closeModelFunction}
          action={closeModelFunction}
        />
      ),
    },
  ]);

  function nextStepFunction(stepIndex) {
    setCurrentStep(stepIndex);
  }

  return <>{progressSteps[currentStep]?.content}</>;
}

function GuidelineComplianceModelBody({ closeModelFunction, action }) {
  const { profile } = useAuthContext();

  return (
    <div className=" w-full max-w-[900px] h-full  md:h-[800px] mb-0 md:mb-20 md:-mt-14 flex flex-col gap-5 md:flex-row bg-white rounded-xl shadow border border-[#666666]/80 py-5 px-3 md:p-10   border-red-500 ">
      <div className="w-full md:w-[50%] px-1">
        <div
          className="w-10 h-10 relative cursor-pointer"
          onClick={() => closeModelFunction()}
        >
          <BackArrowIconSvg />
        </div>
        <div className="flex-col  gap-3 flex">
          <div className="text-[#121212] text-[32px] font-['Circular Std'] leading-normal">
            Guideline and compliance
          </div>
          <div className=" text-[#121212]/80 text-lg md:text-xl font-['Circular Std'] leading-normal">
            You are one step away from this vehicle, read carefully to
            understand our guidelines.
          </div>
        </div>
      </div>
      <div className="w-fill md:w-[50%] px-1">
        <div className="flex-col justify-start items-start gap-5 flex">
          <div className=" text-[#121212] text-lg md:text-2xl font-['Circular Std'] leading-7">
            Age requirement to rent a vehicle is 21+ years
          </div>
          <div className=" text-[#121212] text-lg md:text-2xl font-['Circular Std'] leading-7">
            For personal use, inspection of vehicle by vehicle owner is at the
            return of the vehicle.
          </div>
          <div className=" text-[#121212] text-lg md:text-2xl font-['Circular Std'] leading-7">
            For commercial use, Inspection of vehicle by vehicle owner is every
            3 months.
          </div>
          <div className=" text-[#121212] text-lg md:text-2xl font-['Circular Std'] leading-7">
            Vehicle owner will file a complaint and you will be charged if
            damage is recorded.
          </div>
          <div className=" text-[#121212] text-lg md:text-2xl font-medium font-['Circular Std'] leading-7">
            You are to verify the health status and features of the vehicle and
            confirm in the app before you move with the vehicle.{" "}
          </div>
        </div>
        <label className="j items-center gap-5 inline-flex mt-5">
          <div className="w-10 h-10 flex justify-center items-center relative">
            <input type="checkbox" name="" id="" />
          </div>
          <div className=" text-[#121212] text-xl font-['Circular Std'] leading-normal ">
            I have read all 5 guidelines and i agree to comply.
          </div>
        </label>
        <div
          className="w-full h-[60px] mt-5  bg-[#f46702] rounded-lg shadow border border-black/60 justify-center items-center inline-flex"
          onClick={() => {
            if (!profile.verified || !profile.account_active) {
              return toast.error("Account not active, contact admin");
            }
            action(1);
          }}
        >
          <div className="text-center text-white text-lg md:text-xl font-medium font-['Circular Std'] leading-[22.92px]">
            Get Vehicle
          </div>
        </div>
      </div>
    </div>
  );
}

function LoadingModelBody() {
  return (
    <div className="w-[358px] h-[218px] flex justify-center items-center relative bg-white rounded-xl shadow border border-[#666666]/80">
      <div className="h-[67.50px] flex flex-col justify-between">
        <LoadingGIFComponent className={"self-stretch h-[29.50px]"} />
        <div className="self-stretch text-center text-[#121212] text-xl font-medium font-['Circular Std'] leading-normal">
          Loading.
        </div>
      </div>
    </div>
  );
}

function LoadingGIFComponent({ className }) {
  return (
    <img
      src="https://s3-alpha-sig.figma.com/img/5e15/982c/8b548856d29af4144e171663fa1dfa1a?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GW8Z82TNiq~OJfUW8fPwqy3q8PA8Lc14TtEwRO1Y65lnlFt9G6-ZbFii-CTzQhbVo3yelWrtqovhbmO9OUGSKxioWX6zJc7enzjnjZZrUwe5uhuf0Dkgzo06nC6mwvk~bcZinPSe5y62a39x97zLXlS~mouAJXCasfYJ6f90OkcKRcV8mj2CFEoyEFz-fe3ynyv9sKLRQUNjTGChnC7QR0hGJA0ALqjkIvZO24~yvW0DsYXAVELiW5aO9TiPNLHftGvtccqLT0y~l3x9hyPAdpgQt9IYdkuRvRZSU4bDPhQZ2QevGHA0RCG-ltyfoeo09Nd2c~iKMDv3myvDO913Rw__"
      alt="LoadingGIFComponent"
      className={`${className}`}
    />
  );
}

function VehicleOwnerModelBody({ closeModelFunction, action }) {
  const { carID } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    getVehicleDetailFunction,
    getBookedVehicleFunction,
    getNotificationFunction,
  } = useDataContext();
  async function bookVehicleForInspectionFunction() {
    setLoading(true);
    // console.log({ carID });
    const actionResponce = await action(carID);
    // console.log({ actionResponce });
    if (actionResponce.data.okay) {
      setLoading(false);
      toast.success("Vehicle Inspected Booked");
      getVehicleDetailFunction(carID);
      getBookedVehicleFunction();
      getNotificationFunction();
      closeModelFunction();
    }
  }
  return (
    <div className="w-full md:max-w-[600px] md:-mt-10 md:mb-20 py-5 px-3 md:p-10 h-full md:h-[800px]  bg-white rounded-xl shadow border border-[#666666]/80 flex-col inline-flex gap-5">
      <div className="self-stretch text-[#121212] text-2xl font-medium font-['Circular Std'] leading-[15px] flex items-center gap-5">
        <div className="" onClick={() => closeModelFunction()}>
          <BackArrowIconSvg />
        </div>{" "}
        Vehicle owner
      </div>
      <div className="w-[358px] justify-start items-center gap-2 inline-flex mt-5">
        <OwnerProfileCardComponent />
      </div>
      <div className="self-stretch justify-evenly items-center gap-4 inline-flex mt-5">
        <div className="h-12 relative rounded-xl border border-black/80 w-full flex justify-center items-center">
          <div className="w-5 h-5 relative">
            <PhoneIconSvg />
          </div>
        </div>
        <div className="h-12 relative rounded-xl border border-black/80 w-full flex justify-center items-center">
          <div className="w-5 h-5 relative">
            <MessageIconSvg />
          </div>
        </div>
      </div>

      <div className="self-stretch h-[152px] flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch py-2.5 justify-start items-center gap-3 inline-flex">
          <div className="w-8 h-8 relative">
            <MapLocationIconSvg />
          </div>
          <div className="w-80 text-[#121212]/80 text-xl font-['Circular Std'] leading-normal">
            Plot 23, 1 Jikwoyi Road, Opposite Karu Primary School, Karu, Abuja.
          </div>
        </div>
        <div className="self-stretch py-2.5 justify-start items-center gap-3 inline-flex">
          <div className="w-8 h-8 relative">
            <MoneyPrizeIconSvg />
          </div>
          <div className="w-44">
            <span className="text-[#121212] text-xl font-medium font-['Circular Std'] leading-normal">
              Prize:
              <br />
            </span>
            <span className="text-[#121212]/80 text-xl font-['Circular Std'] leading-normal">
              35,000/day
            </span>
          </div>
        </div>
      </div>
      <div className="self-stretch h-[120px] flex-col justify-start items-start gap-6 flex mt-10">
        <div className="h-12 justify-between items-center  inline-flex w-full">
          <div className="text-[#121212] text-xl font-['Circular Std'] leading-[14px]">
            Read inspection policy
          </div>
          <div className="w-8 h-8 relative">
            <RightCoveArrowIconSvg />
          </div>
        </div>
        <div className="h-12 justify-between items-center inline-flex w-full">
          <div className="text-[#121212] text-xl font-['Circular Std'] leading-[14px]">
            Cancel{" "}
          </div>
          <div className="w-8 h-8 relative">
            <RightCoveArrowIconSvg />
          </div>
        </div>
      </div>

      <div
        className="w-full h-[60px] bg-[#f46702] rounded-lg shadow border border-black/60 justify-center items-center inline-flex  text-white text-xl font-medium leading-[22.92px]"
        onClick={() => bookVehicleForInspectionFunction()}
      >
        {loading ? <Spinner /> : "Book Inspection"}
      </div>
    </div>
  );
}

function ApproveVehicleHealthModelBody({ closeModelFunction, action }) {
  return (
    <div className="w-full md:max-w-[600px] md:-mt-10 md:mb-20 py-5 px-3 md:p-10 h-full md:h-[500px] bg-white rounded-xl shadow border border-[#666666]/80 flex-col justify-start items-start gap-5 inline-flex">
      <div className=" gap-[15px] inline-flex items-center mt-10">
        <div
          className="w-12 h-12 relative flex items-center justify-center"
          onClick={closeModelFunction}
        >
          <BackArrowIconSvg />
        </div>
        <div className="text-[#121212] text-2xl font-medium font-['Circular Std'] leading-[15px]">
          Approve vehicle health
        </div>
      </div>

      <div className="self-stretch h-[163px] flex-col justify-start items-start gap-6 flex">
        <div className="self-stretch text-[#121212] text-xl font-medium font-['Circular Std'] leading-[14px]">
          Additional note:
        </div>
        <div className="w-full h-[100px] relative bg-white rounded-md border border-[#909090] text-[#121212] text-xl">
          <div className="left-[13px] top-[16.12px] absolute  font-['Circular Std'] leading-normal">
            Good to go!
          </div>
        </div>
      </div>

      <div className="justify-between items-center inline-flex w-full">
        <div className="text-[#121212] text-xl md:text-2xl font-['Circular Std'] leading-none">
          Everything is in check as in the app.
        </div>
        <div className="w-10 h-10 relative">
          <TickCheckBoxIconSvg />
        </div>
      </div>

      <div
        className="w-full h-[60px] mt-3 bg-[#f46702] rounded-lg shadow border border-black/60 justify-center items-center gap-2.5 inline-flex"
        onClick={() => action(3)}
      >
        <div className="text-center text-white text-xl font-medium font-['Circular Std'] leading-[22.92px]">
          Make down payment
        </div>
      </div>
    </div>
  );
}
function VehiclePaymentModelBody({ closeModelFunction, action }) {
  //   payment_amount
  // payment_type

  // payment

  const {
    paystackPublicKey,
    vehicleDetail,
    handelConfirmVehicleInspectionFunction,
  } = useDataContext();

  let bike_down_amount_amount = 40000;
  const [payment_type, setPayment_type] = useState("full_payment");
  const [payment_amount, setPayment_amount] = useState(40000);
  useEffect(() => {
    if (payment_type === "full_payment") {
      setPayment_amount(bike_down_amount_amount);
    } else if (payment_type === "half_payment") {
      setPayment_amount(bike_down_amount_amount * 0.5);
    } else {
      toast.error("Invalid payment Type");
    }
  }, [payment_type]);
  // useEffect(() => {
  //   // console.log({ vehicleDetail });
  // }, [vehicleDetail]);

  const componentProps = {
    email: "victorjosiahm3@email.com",
    amount: payment_amount * 100, // Convert to kobo
    metadata: {
      riderId: "riderId",
      vehicleId: "vehicleId",
    },
    publicKey: paystackPublicKey,
    text: "Make Payment",
    onSuccess: async (response) => {
      // Payment successful, update database
      // console.log("Payment successful", { response });
      const requestResponce = await handelConfirmVehicleInspectionFunction({
        vehicle_id: vehicleDetail.data._id,
        payment_amount,
        payment_type,
        payment: response,
      });
      if (requestResponce.statusText == "OK") {
        toast.success("Payment successful");
        closeModelFunction();
        // console.log({ requestResponce });
      } else {
        toast.success("Payment not verified, Contact Admin");
      }
    },
    onClose: () => {
      // Payment modal closed
      // console.log("Payment modal closed");
      toast.info("Payment modal closed");
    },
  };

  return (
    <div className=" md:max-w-[600px] md:-mt-10 md:mb-20 py-5 px-3 md:p-10 w-full h-full md:h-[550px] bg-white rounded-xl shadow border border-[#666666]/80 flex-col justify-start inline-flex">
      <div className=" gap-[15px] inline-flex items-center ">
        <div
          className="w-12 h-12 relative flex items-center justify-center"
          onClick={closeModelFunction}
        >
          <BackArrowIconSvg />
        </div>
        <div className="text-[#121212] text-2xl font-medium font-['Circular Std'] leading-[15px]">
          Make Down Payment
        </div>
      </div>
      <div className="mt-10 mb-5">
        <OwnerProfileCardComponent />
      </div>
      <h1 className="text-2xl md:text-3xl mb-2 font-bold leading-none">
        Hyundai Elantra 2024
      </h1>
      Down payment for 2 weeks
      <select
        name="payment_type"
        id="payment_type"
        value={payment_type}
        onChange={(e) => {
          setPayment_type(e.target.value);
        }}
        className=" border-[2px] border-[#121212] rounded-md w-full mt-5 h-14 px-5 text-[22px]"
      >
        <option value="full_payment">Full Payment</option>
        <option value="half_payment">Half Payment</option>
      </select>
      <div className=" border-[2px] border-[#121212] rounded-md w-full h-13 mb-5 text-[25px] mt-10 flex justify-center items-center">
        ₦ {payment_amount.toLocaleString()}
      </div>
      <PaystackButton
        className="w-full h-[60px] p-2.5 bg-[#f46702] text-white rounded-lg shadow border border-black/60 justify-center items-center gap-2.5 inline-flex"
        {...componentProps}
      />
    </div>
  );
}

function OwnerProfileImageComponent() {
  return (
    <div className="w-[60px] h-[63.75px] relative flex justify-center">
      <img
        className="w-[60px] h-[60px] rounded-[100px]"
        src="https://via.placeholder.com/60x60"
      />
      <div className="text-[#121212] text-[10px] absolute bottom-0 bg-white rounded shadow border border-[#eeeeee] justify-center items-center flex">
        4.0 <GoldProfileStarIconSvg />
      </div>
    </div>
  );
}

function OwnerProfileCardComponent({ profile }) {
  console.log({ profile });
  return (
    <div className=" items-start gap-1 inline-flex">
      <OwnerProfileImageComponent />
      <div className="flex flex-col">
        {" "}
        <div className="text-[#121212] text-xl font-['Circular Std'] leading-normal">
          {profile?.full_name}
        </div>
        <div className="text-[#121212]/70 text-xl font-light font-['Circular Std'] leading-normal">
          25 rents | Since {moment(profile?.createdAt).format("ll")}
        </div>
      </div>
    </div>
  );
}

