import * as React from "react";

export default function Logo() {
  return (
    <img
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8b800a54b407a9df1183200e7996fa633cc0220eab11381e8c18225f5c188a31?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb"
      alt="Company Logo"
      className="object-contain w-44 max-w-full aspect-[4]"
    />
  );
}