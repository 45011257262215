import * as React from "react";
import { Link } from "react-router-dom";

export default function NavigationItem({ icon, label, link, isActive }) {
  return (
    <Link to={link}>
      <button
        className={`flex flex-col justify-center px-5 mt-5 w-full whitespace-nowrap ${
          isActive ? "bg-black text-white" : "bg-white"
        } rounded-[13.5px] min-h-[53px]`}
        aria-label={label}
      >
        <div className="flex gap-4 items-center">
          <img
            src={icon}
            alt=""
            className="object-contain shrink-0 self-stretch w-6 aspect-square"
          />
          <span className="self-stretch">{label}</span>
        </div>
      </button>
    </Link>
  );
}