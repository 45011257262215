import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Context/authContext";
import { toast } from "react-toastify";
import { Spinner } from "@chakra-ui/react";
import NavigationComponent from "../../components/navigation/NavigationComponent";

function LoginPage() {
  const navigate = useNavigate();
  const { loginAuthFunction } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState({
    email: "",
    password: "",
    accountType: "owner", // Host or Rider
  });

  const handleChanges = (e) => {
    setAuth((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  async function handleLogin() {
    const { email, password, accountType } = auth;
    setLoading(true);

    // Validation checks
    if (!email || !password || !accountType) {
      setLoading(false);
      return toast.error("All fields are required.");
    }

    // Call login function
    const loginResponse = await loginAuthFunction({
      email,
      password,
      accountType,
    });

    setLoading(false);

    if (!loginResponse) {
      return toast.error("Network error");
    }

    if (!loginResponse.data.okay) {
      return toast.error(loginResponse.data.message);
    }

    const { token, profile } = loginResponse.data;
    localStorage.setItem("vooom-token", token);
    localStorage.setItem("vooom-profile", JSON.stringify(profile));

    window.location.href = "/dashboard"; // Navigate to the dashboard on successful login
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-black relative">
    {/* Background Image */}
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcf4ead8a7ae0cde7edb00c8f4154c808ab13747bc30d564eaabaafa76ed64ee?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb"
      alt=""
      className="absolute inset-0 w-full h-full object-cover"
    />
  
    {/* Logo and Form Container */}
    <div className="z-10 flex flex-col items-center">
      {/* Vooom Logo */}
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9358d3ca0c3845f2fedf4f474f4a8576720e4c96e26c8fd6bea5df1c75d43c11?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb"
        alt="Vooom Logo"
        className="object-contain max-w-full w-[236px] mb-8"
      />
  
      {/* Registration Form */}
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold">Login your account</h2>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <div className="mb-4">
            <label className="block mb-2 font-bold">Email Address</label>
            <input
              type="email"
              className="w-full p-2 border rounded"
              name="email"
              id="email"
              onChange={handleChanges}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-bold">Password</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              name="password"
              id="password"
              onChange={handleChanges}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-bold">Select purpose</label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                name="accountType"
                id="owner"
                value="owner"
                className="mr-2"
                onChange={handleChanges}
                required
              />
              <label htmlFor="owner" className="flex flex-col">
                <span className="font-bold">Vehicle Owner</span>
                <span className="text-sm text-gray-500">
                  I have a vehicle that needs management
                </span>
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="accountType"
                id="rider"
                value="rider"
                className="mr-2"
                onChange={handleChanges}
                required
              />
              <label htmlFor="rider" className="flex flex-col">
                <span className="font-bold">Rider</span>
                <span className="text-sm text-gray-500">
                  I'm in need of a vehicle/bike
                </span>
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white py-2 rounded-lg font-bold"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Login My Account"}
          </button>
        </form>
        <p className="text-center text-gray-500 mt-4">
          Don't have an account?{" "}
          <Link to="/auth/register" className="text-orange-500">
            Register
          </Link>
        </p>
      </div>
    </div>
  </div>

    /*<main className="flex overflow-hidden flex-col pb-10 h-full bg-white">
      <div className="fixed t-0 w-full bg-white">
        <NavigationComponent />
      </div>

      <section className="flex flex-wrap gap-10 items-center self-center justify-center mx-3 lg:mx-0 px-10 lg:px-32 py-8 lg:py-16 mt-36 rounded-2xl bg-neutral-50">
        <div className="flex flex-col self-stretch my-auto tracking-tight lg:min-w-[240px] text-neutral-900 lg:w-[285px]">
          <div className="flex flex-col justify-center w-full">
            <h1 className="self-start text-3xl font-bold leading-none text-center">
              Login
            </h1>
            <p className="mt-5 text-xl leading-6 font-[450]">
              Sign in as a
            </p>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/65dc2bb475ea99ee85c5e518d79a4d538fd609fe1de19c3019b46ca4fbf9cbe9?apiKey=e85d4038ac5a41748b51322169f61f43"
            alt="login"
            className="hidden lg:block object-contain mt-20 max-w-full aspect-square w-[200px] max-md:mt-10"
          />
        </div>

        <div className="flex flex-col justify-center items-center self-stretch my-auto md:min-w-[240px] md:w-[420px] w-full">
          <form
            className="flex flex-col w-full text-base tracking-tight leading-none font-[450] max-w-[420px] text-neutral-900 text-opacity-70"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <div className="flex items-center gap-5">
              <label>
                <input
                  type="radio"
                  name="accountType"
                  value="host"
                  onChange={handleChanges}
                  required
                />
                Host
              </label>
              <label>
                <input
                  type="radio"
                  name="accountType"
                  value="rider"
                  onChange={handleChanges}
                  required
                />
                Rider
              </label>
            </div>

            <div className="w-full rounded min-h-[72px] border border-[#121212] overflow-hidden mt-5">
              <input
                type="email"
                id="email"
                placeholder="Email address"
                className="size-full bg-white ps-5 min-h-[72px] rounded"
                name="email"
                onChange={handleChanges}
                required
              />
            </div>

            <div className="w-full rounded min-h-[72px] mt-5 border border-[#121212] overflow-hidden">
              <input
                type="password"
                id="password"
                placeholder="Enter password"
                className="size-full bg-white ps-5 min-h-[72px] rounded"
                name="password"
                onChange={handleChanges}
                required
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="gap-2.5 self-stretch px-2.5 py-5 mt-20 w-full text-xl leading-none text-white bg-orange-600 rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] max-md:mt-10"
            >
              {loading ? <Spinner /> : "Login"}
            </button>
          </form>

          <p className="mt-7 text-xl leading-tight text-neutral-900">
            Don't have an account?{" "}
            <Link to="/auth/register" className="underline">
              Sign up
            </Link>
          </p>
        </div>
      </section>
    </main>*/
  );
}

export default LoginPage;
