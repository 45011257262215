import React, { useEffect, useState } from "react";
import NavigationComponent, {
  LandingPageNavigation,
} from "../../components/navigation/NavigationComponent";
import { SearchBarComponent } from "../../components/search/SearchFormComponent";
import { Link, useNavigate } from "react-router-dom";
import { useDataContext } from "../../Context/dataContext";
import "./LandingPage.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { StepsIconSvg } from "../../icons";

function LandingPage() {
  const companyLogos = [
    {
      src: require("../../assets/image/binnox-logo.png"),
      // width: "140px",
      className: "w-[100px] md:w-[140px]",
      aspectRatio: "3.5",
    },
    {
      src: require("../../assets/image/allianz-logo.png"),
      // width: "134px",
      className: "w-[95px] md:w-[134px]",
      aspectRatio: "3.5",
    },
    {
      src: require("../../assets/image/testia-logo.png"),
      // width: "132px",
      className: "w-[93px] md:w-[132px]",
      aspectRatio: "3.5",
    },
    {
      src: require("../../assets/image/yakoyo-logo.png"),
      // width: "109px",
      className: "w-[75px] md:w-[109px]",
      aspectRatio: "1.6",
    },
  ];

  const testimonialQuote = {};

  return (
    <div className=" LandingPage">
      <section className="w-full  z-[99999] mb-5 fixed top-0 bg-white">
        <LandingPageNavigation />
      </section>

      <section className="hero-section flex-wr ap back-ruge-bg-image">
        <div className="flex items-center pt-[130px] md:pt-[70px]  w-full max-w-[1100px] flex-col md:flex-row">
          <div className="content  w-full md:min-w-[00px] max-w-[700px] text-center md:text-left ">
            <h1 className=" w-full md:w- [50%] text-[40px] md:text-[60px] font-bold leading-[50px] mb-8 text-[#FFFFFF]">
              Empower Your Logistics Management Journey!
            </h1>
            <p className=" text-[18px] md:text-[20px] mb-8 font-bold leading-[31.2px] text-[#FFFFFF99">
              You List your Motorcycles, We manage, and maximize your vehicle’s
              potential while you enjoy stress less Profit.
            </p>
          </div>
          {/* <div className="image-container w-full md:w-1/2 min -w-[500px] w -[1000px] h-[625px] border border-red-500 overflow-hidden relative">
          <img
            src={require("../../assets/image/hero-bike.png")}
            alt="Vooom bike"
            className="absolute left-1/2 right-0  top-[55%] transform -translate-x-1/2 -translate-y-1/2 scale-x-[-1] min-w -[1000px] h-[699px]"
          />
        </div> */}
          <div className="image-container flex justify-center w-full md:w-[700px]">
            <img
              src={require("../../assets/image/hero-bike.png")}
              alt="Vooom bike"
              className="ab solute object-contain w-full md:min-w-[600px] scale-x-[-1] md:scale-x-[1]"
            />
          </div>
        </div>
      </section>

      <section className="relative z-20 -mt-[60px] md:-mt-[50px] w-full px-9 flex justify-center">
        <div className="flex flex-col rounded-2xl border border-solid bg-zinc-100 border-black border-opacity-20 w-full max-w-[1090px] ">
          {/* <div className="flex flex-col items-center w-full max-w-[1046px] max-md:max-w-full"> */}
          <h2 className="text-2xl pt-3 md:text-3xl font-bold tracking-wide leading-relaxed text-center text-black max-md:max-w-full">
            Trusted by 100+ Companies Worldwide
          </h2>
          <div className="flex justify-evenly self-center items-center mt-5 w-full gap-4 pb-5 px-2 md:pb-2 max-w-[870px] flex-wrap ">
            {companyLogos.map((logo, index) => (
              <div key={index} className="">
                <img
                  loading="lazy"
                  src={logo.src}
                  alt={"company logo"}
                  className={`object-co tain shrink-0  my-auto ${logo.className}`}
                  style={{ aspectRatio: logo.aspectRatio }}
                />
              </div>
            ))}
          </div>
          {/* </div> */}
        </div>
      </section>

      <section className=" w-full flex justify-center">
        <div className="w-[982px] py-20 flex-col justify-start items-center gap-[50px] inline-flex">
          <div className="flex-col justify-start items-center gap-2 flex">
            <div className=" text-center text-black text-[45px] font-bold  leading-[58.50px]">
              Three simple step
            </div>
            <div className=" text-center text-black/40 text-xl font-bold  leading-relaxed">
              Signup in seconds, do the steps easily and start feeling your new
              experience
            </div>
          </div>
          <div className=" justify-evenly flex-wrap items-center gap-[10px] w-full inline-flex">
            <div className="flex-col justify-start items-center gap-[10px] p-3 w-[250px] inline-flex">
              <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                <div className="w-[32.87px] h-[32.87px] relative">
                  <StepsIconSvg />
                </div>
              </div>
              <div className="flex-col justify-start items-center gap-3 flex">
                <div className="text-black text-xl font-bold  leading-relaxed">
                  Step 1
                </div>
                <div className="text-center text-black/40 text-sm font-bold  leading-[20px]">
                  Sign Up and List Your Vehicle
                </div>
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-[10px] p-3 w-[250px] inline-flex">
              <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                <div className="w-[32.87px] h-[32.87px] relative">
                  <StepsIconSvg />
                </div>
              </div>
              <div className="flex-col justify-start items-center gap-3 flex">
                <div className="text-black text-xl font-bold  leading-relaxed">
                  Step 2
                </div>
                <div className="text-center text-black/40 text-sm font-bold  leading-[20px]">
                  Set Availability and Complete KYC
                </div>
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-[10px] p-3 w-[250px] inline-flex">
              <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                <div className="w-[32.87px] h-[32.87px] relative">
                  <StepsIconSvg />
                </div>
              </div>
              <div className="flex-col justify-start items-center gap-3 flex">
                <div className="text-black text-xl font-bold  leading-relaxed">
                  Step 3
                </div>
                <div className="text-center text-black/40 text-sm font-bold  leading-[20px]">
                  Earn as Your Bikes Gets <br /> Leased to Riders
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" w-full flex justify-center  py-20 px-5 pt-10">
        <div className="w-full max-w-[1200px] flex  flex-col md:flex-row items-center">
          <div className="image-container w-full md:min-w-[500px] max-w-[900px]">
            <img
              src={require("../../assets/image/Macbook-Mockup-owner.png")}
              alt=""
              className="object-contain"
            />
          </div>
          <div className=" flex-col justify-start items-start gap-10 inline-flex">
            <div className="flex-col justify-start items-start gap-1 flex">
              <div className=" text-[#f46702] text-[16px] md:text-lg font-normal  uppercase leading-7 tracking-[2.88px]">
                Vehicle Owners
              </div>
              <div className=" text-black text-[30px] md:text-[40px] font-bold  capitalize leading-[48px]">
                Turn your stressful delivery bikes into a steady income stream.
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-6 flex">
              {/* 1 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative bo rder">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.99805L10.6985 7.20404C10.4445 8.21989 10.3176 8.72782 10.0531 9.14114C9.81915 9.50672 9.50868 9.8172 9.14309 10.0511C8.72978 10.3156 8.22185 10.4426 7.20599 10.6965L2 11.998L7.20599 13.2995C8.22185 13.5535 8.72978 13.6805 9.14309 13.945C9.50868 14.1789 9.81915 14.4894 10.0531 14.855C10.3176 15.2683 10.4445 15.7762 10.6985 16.7921L12 21.998L13.3015 16.7921C13.5555 15.7762 13.6824 15.2683 13.9469 14.855C14.1808 14.4894 14.4913 14.1789 14.8569 13.945C15.2702 13.6805 15.7782 13.5535 16.794 13.2995L22 11.998L16.794 10.6965C15.7782 10.4426 15.2702 10.3156 14.8569 10.0511C14.4913 9.8172 14.1808 9.50672 13.9469 9.14114C13.6824 8.72782 13.5555 8.2199 13.3015 7.20404L12 1.99805Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-black text-lg font-bold  capitalize leading-7">
                    Earn Passive Income
                  </div>
                </div>
                <div className=" text-black/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Put your vehicle (Delivery Bikes) to work and earn
                  effortlessly while we manage the logistics and operations.
                </div>
              </div>
              {/* 2 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2.49862V11.9986M12 11.9986L20.5 7.27633M12 11.9986L3.5 7.27633M12 11.9986V21.4986M20.5 16.7208L12.777 12.4303C12.4934 12.2727 12.3516 12.194 12.2015 12.1631C12.0685 12.1357 11.9315 12.1357 11.7986 12.1631C11.6484 12.194 11.5066 12.2727 11.223 12.4303L3.5 16.7208M21 16.0572V7.94006C21 7.59742 21 7.4261 20.9495 7.2733C20.9049 7.13813 20.8318 7.01404 20.7354 6.90935C20.6263 6.79102 20.4766 6.70782 20.177 6.54141L12.777 2.4303C12.4934 2.27274 12.3516 2.19396 12.2015 2.16308C12.0685 2.13574 11.9315 2.13574 11.7986 2.16308C11.6484 2.19396 11.5066 2.27274 11.223 2.4303L3.82297 6.54142C3.52345 6.70782 3.37369 6.79102 3.26463 6.90936C3.16816 7.01405 3.09515 7.13813 3.05048 7.2733C3 7.4261 3 7.59742 3 7.94007V16.0572C3 16.3998 3 16.5711 3.05048 16.7239C3.09515 16.8591 3.16816 16.9832 3.26463 17.0879C3.37369 17.2062 3.52345 17.2894 3.82297 17.4558L11.223 21.5669C11.5066 21.7245 11.6484 21.8033 11.7986 21.8342C11.9315 21.8615 12.0685 21.8615 12.2015 21.8342C12.3516 21.8033 12.4934 21.7245 12.777 21.5669L20.177 17.4558C20.4766 17.2894 20.6263 17.2062 20.7354 17.0879C20.8318 16.9832 20.9049 16.8591 20.9495 16.7239C21 16.5711 21 16.3998 21 16.0572Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-black text-lg font-bold  capitalize leading-7">
                    Stress-Free Management
                  </div>
                </div>
                <div className=" text-black/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Forget about the headaches of driver recruitment, maintenance,
                  and operations. Vooom handles everything for you.
                </div>
              </div>
              {/* 3 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.5 15.998H8M8 15.998V3.49805M8 15.998L3.5 20.498M3.5 7.99805H16M16 7.99805V20.498M16 7.99805L20.5 3.49805M21 15.3353V3.79805C21 3.51802 21 3.37801 20.9455 3.27105C20.8976 3.17697 20.8211 3.10048 20.727 3.05254C20.62 2.99805 20.48 2.99805 20.2 2.99805H8.66274C8.41815 2.99805 8.29586 2.99805 8.18077 3.02568C8.07873 3.05017 7.98119 3.09058 7.89172 3.14541C7.7908 3.20725 7.70432 3.29372 7.53137 3.46668L3.46863 7.52942C3.29568 7.70237 3.2092 7.78885 3.14736 7.88976C3.09253 7.97923 3.05213 8.07678 3.02763 8.17881C3 8.2939 3 8.4162 3 8.66079V20.198C3 20.4781 3 20.6181 3.0545 20.725C3.10243 20.8191 3.17892 20.8956 3.273 20.9436C3.37996 20.998 3.51997 20.998 3.8 20.998H15.3373C15.5818 20.998 15.7041 20.998 15.8192 20.9704C15.9213 20.9459 16.0188 20.9055 16.1083 20.8507C16.2092 20.7888 16.2957 20.7024 16.4686 20.5294L20.5314 16.4667C20.7043 16.2937 20.7908 16.2072 20.8526 16.1063C20.9075 16.0169 20.9479 15.9193 20.9724 15.8173C21 15.7022 21 15.5799 21 15.3353Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-black text-lg font-bold  capitalize leading-7">
                    Flexible Partnership Options
                  </div>
                </div>
                <div className=" text-black/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Whether you have one vehicle or a fleet, Vooom offers tailored
                  solutions to meet your needs.
                </div>
              </div>
              {/* 4 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative bo rder">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.99805L10.6985 7.20404C10.4445 8.21989 10.3176 8.72782 10.0531 9.14114C9.81915 9.50672 9.50868 9.8172 9.14309 10.0511C8.72978 10.3156 8.22185 10.4426 7.20599 10.6965L2 11.998L7.20599 13.2995C8.22185 13.5535 8.72978 13.6805 9.14309 13.945C9.50868 14.1789 9.81915 14.4894 10.0531 14.855C10.3176 15.2683 10.4445 15.7762 10.6985 16.7921L12 21.998L13.3015 16.7921C13.5555 15.7762 13.6824 15.2683 13.9469 14.855C14.1808 14.4894 14.4913 14.1789 14.8569 13.945C15.2702 13.6805 15.7782 13.5535 16.794 13.2995L22 11.998L16.794 10.6965C15.7782 10.4426 15.2702 10.3156 14.8569 10.0511C14.4913 9.8172 14.1808 9.50672 13.9469 9.14114C13.6824 8.72782 13.5555 8.2199 13.3015 7.20404L12 1.99805Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-black text-lg font-bold  capitalize leading-7">
                    Guaranteed Security
                  </div>
                </div>
                <div className=" text-black/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Your vehicle is insured and monitored through our system to
                  ensure safety and accountability.
                </div>
              </div>
              {/* 5 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2.49862V11.9986M12 11.9986L20.5 7.27633M12 11.9986L3.5 7.27633M12 11.9986V21.4986M20.5 16.7208L12.777 12.4303C12.4934 12.2727 12.3516 12.194 12.2015 12.1631C12.0685 12.1357 11.9315 12.1357 11.7986 12.1631C11.6484 12.194 11.5066 12.2727 11.223 12.4303L3.5 16.7208M21 16.0572V7.94006C21 7.59742 21 7.4261 20.9495 7.2733C20.9049 7.13813 20.8318 7.01404 20.7354 6.90935C20.6263 6.79102 20.4766 6.70782 20.177 6.54141L12.777 2.4303C12.4934 2.27274 12.3516 2.19396 12.2015 2.16308C12.0685 2.13574 11.9315 2.13574 11.7986 2.16308C11.6484 2.19396 11.5066 2.27274 11.223 2.4303L3.82297 6.54142C3.52345 6.70782 3.37369 6.79102 3.26463 6.90936C3.16816 7.01405 3.09515 7.13813 3.05048 7.2733C3 7.4261 3 7.59742 3 7.94007V16.0572C3 16.3998 3 16.5711 3.05048 16.7239C3.09515 16.8591 3.16816 16.9832 3.26463 17.0879C3.37369 17.2062 3.52345 17.2894 3.82297 17.4558L11.223 21.5669C11.5066 21.7245 11.6484 21.8033 11.7986 21.8342C11.9315 21.8615 12.0685 21.8615 12.2015 21.8342C12.3516 21.8033 12.4934 21.7245 12.777 21.5669L20.177 17.4558C20.4766 17.2894 20.6263 17.2062 20.7354 17.0879C20.8318 16.9832 20.9049 16.8591 20.9495 16.7239C21 16.5711 21 16.3998 21 16.0572Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-black text-lg font-bold  capitalize leading-7">
                    Maximized Earnings
                  </div>
                </div>
                <div className=" text-black/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Benefit from optimized vehicle utilization through our
                  cutting-edge AI and logistics network, ensuring consistent
                  bookings and income.
                </div>
              </div>
              {/* 6 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.5 15.998H8M8 15.998V3.49805M8 15.998L3.5 20.498M3.5 7.99805H16M16 7.99805V20.498M16 7.99805L20.5 3.49805M21 15.3353V3.79805C21 3.51802 21 3.37801 20.9455 3.27105C20.8976 3.17697 20.8211 3.10048 20.727 3.05254C20.62 2.99805 20.48 2.99805 20.2 2.99805H8.66274C8.41815 2.99805 8.29586 2.99805 8.18077 3.02568C8.07873 3.05017 7.98119 3.09058 7.89172 3.14541C7.7908 3.20725 7.70432 3.29372 7.53137 3.46668L3.46863 7.52942C3.29568 7.70237 3.2092 7.78885 3.14736 7.88976C3.09253 7.97923 3.05213 8.07678 3.02763 8.17881C3 8.2939 3 8.4162 3 8.66079V20.198C3 20.4781 3 20.6181 3.0545 20.725C3.10243 20.8191 3.17892 20.8956 3.273 20.9436C3.37996 20.998 3.51997 20.998 3.8 20.998H15.3373C15.5818 20.998 15.7041 20.998 15.8192 20.9704C15.9213 20.9459 16.0188 20.9055 16.1083 20.8507C16.2092 20.7888 16.2957 20.7024 16.4686 20.5294L20.5314 16.4667C20.7043 16.2937 20.7908 16.2072 20.8526 16.1063C20.9075 16.0169 20.9479 15.9193 20.9724 15.8173C21 15.7022 21 15.5799 21 15.3353Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-black text-lg font-bold  capitalize leading-7">
                    Hassle-Free Maintenance
                  </div>
                </div>
                <div className=" text-black/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Enjoy routine servicing and repairs at no additional cost,
                  keeping your vehicle in top condition without
                  any extra effort.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" w-full flex justify-center back-ruge-bg-image py-20 px-5">
        <div className="flex flex-col  max-w-[1000px] items-center md:flex-row-reverse">
          <div className="image-container p x-20 w-full md:min-w-[400px] max-w-[900px]">
            <img
              src={require("../../assets/image/Macbook-Mockup-rider.png")}
              alt=""
              className="object-contain"
            />
          </div>
          <div className="w-full max-w-[582px] flex-col justify-start items-start gap-10 inline-flex text-white">
            <div className="flex-col justify-start items-start gap-1 flex">
              <div className=" text-[#f46702] text-[16px] md:text-lg font-normal  uppercase leading-7 tracking-[2.88px]">
                Vehicle Riders
              </div>
              <div className=" text-white text-4xl md:text-5xl  font-bold  capitalize leading-[48px]">
                Drive your way to financial independence with vooom
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-6 flex">
              {/* 1 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative bo rder">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.99805L10.6985 7.20404C10.4445 8.21989 10.3176 8.72782 10.0531 9.14114C9.81915 9.50672 9.50868 9.8172 9.14309 10.0511C8.72978 10.3156 8.22185 10.4426 7.20599 10.6965L2 11.998L7.20599 13.2995C8.22185 13.5535 8.72978 13.6805 9.14309 13.945C9.50868 14.1789 9.81915 14.4894 10.0531 14.855C10.3176 15.2683 10.4445 15.7762 10.6985 16.7921L12 21.998L13.3015 16.7921C13.5555 15.7762 13.6824 15.2683 13.9469 14.855C14.1808 14.4894 14.4913 14.1789 14.8569 13.945C15.2702 13.6805 15.7782 13.5535 16.794 13.2995L22 11.998L16.794 10.6965C15.7782 10.4426 15.2702 10.3156 14.8569 10.0511C14.4913 9.8172 14.1808 9.50672 13.9469 9.14114C13.6824 8.72782 13.5555 8.2199 13.3015 7.20404L12 1.99805Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-white text-lg font-bold  capitalize leading-7">
                    Steady Income Opportunities
                  </div>
                </div>
                <div className=" text-white/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Join a growing network of riders and enjoy consistent delivery
                  requests tailored to your availability.
                </div>
              </div>
              {/* 2 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2.49862V11.9986M12 11.9986L20.5 7.27633M12 11.9986L3.5 7.27633M12 11.9986V21.4986M20.5 16.7208L12.777 12.4303C12.4934 12.2727 12.3516 12.194 12.2015 12.1631C12.0685 12.1357 11.9315 12.1357 11.7986 12.1631C11.6484 12.194 11.5066 12.2727 11.223 12.4303L3.5 16.7208M21 16.0572V7.94006C21 7.59742 21 7.4261 20.9495 7.2733C20.9049 7.13813 20.8318 7.01404 20.7354 6.90935C20.6263 6.79102 20.4766 6.70782 20.177 6.54141L12.777 2.4303C12.4934 2.27274 12.3516 2.19396 12.2015 2.16308C12.0685 2.13574 11.9315 2.13574 11.7986 2.16308C11.6484 2.19396 11.5066 2.27274 11.223 2.4303L3.82297 6.54142C3.52345 6.70782 3.37369 6.79102 3.26463 6.90936C3.16816 7.01405 3.09515 7.13813 3.05048 7.2733C3 7.4261 3 7.59742 3 7.94007V16.0572C3 16.3998 3 16.5711 3.05048 16.7239C3.09515 16.8591 3.16816 16.9832 3.26463 17.0879C3.37369 17.2062 3.52345 17.2894 3.82297 17.4558L11.223 21.5669C11.5066 21.7245 11.6484 21.8033 11.7986 21.8342C11.9315 21.8615 12.0685 21.8615 12.2015 21.8342C12.3516 21.8033 12.4934 21.7245 12.777 21.5669L20.177 17.4558C20.4766 17.2894 20.6263 17.2062 20.7354 17.0879C20.8318 16.9832 20.9049 16.8591 20.9495 16.7239C21 16.5711 21 16.3998 21 16.0572Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-white text-lg font-bold  capitalize leading-7">
                    Access to Reliable Vehicles(Delivery Bikes)
                  </div>
                </div>
                <div className=" text-white/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Don’t have your own bikes? Rent from our fleet and start
                  earning immediately.
                </div>
              </div>
              {/* 3 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.5 15.998H8M8 15.998V3.49805M8 15.998L3.5 20.498M3.5 7.99805H16M16 7.99805V20.498M16 7.99805L20.5 3.49805M21 15.3353V3.79805C21 3.51802 21 3.37801 20.9455 3.27105C20.8976 3.17697 20.8211 3.10048 20.727 3.05254C20.62 2.99805 20.48 2.99805 20.2 2.99805H8.66274C8.41815 2.99805 8.29586 2.99805 8.18077 3.02568C8.07873 3.05017 7.98119 3.09058 7.89172 3.14541C7.7908 3.20725 7.70432 3.29372 7.53137 3.46668L3.46863 7.52942C3.29568 7.70237 3.2092 7.78885 3.14736 7.88976C3.09253 7.97923 3.05213 8.07678 3.02763 8.17881C3 8.2939 3 8.4162 3 8.66079V20.198C3 20.4781 3 20.6181 3.0545 20.725C3.10243 20.8191 3.17892 20.8956 3.273 20.9436C3.37996 20.998 3.51997 20.998 3.8 20.998H15.3373C15.5818 20.998 15.7041 20.998 15.8192 20.9704C15.9213 20.9459 16.0188 20.9055 16.1083 20.8507C16.2092 20.7888 16.2957 20.7024 16.4686 20.5294L20.5314 16.4667C20.7043 16.2937 20.7908 16.2072 20.8526 16.1063C20.9075 16.0169 20.9479 15.9193 20.9724 15.8173C21 15.7022 21 15.5799 21 15.3353Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-white text-lg font-bold  capitalize leading-7">
                    Flexible Work Schedule
                  </div>
                </div>
                <div className=" text-white/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Choose when and where you want to work, offering you full
                  control of your time and lifestyle.
                </div>
              </div>
              {/* 4 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative bo rder">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.99805L10.6985 7.20404C10.4445 8.21989 10.3176 8.72782 10.0531 9.14114C9.81915 9.50672 9.50868 9.8172 9.14309 10.0511C8.72978 10.3156 8.22185 10.4426 7.20599 10.6965L2 11.998L7.20599 13.2995C8.22185 13.5535 8.72978 13.6805 9.14309 13.945C9.50868 14.1789 9.81915 14.4894 10.0531 14.855C10.3176 15.2683 10.4445 15.7762 10.6985 16.7921L12 21.998L13.3015 16.7921C13.5555 15.7762 13.6824 15.2683 13.9469 14.855C14.1808 14.4894 14.4913 14.1789 14.8569 13.945C15.2702 13.6805 15.7782 13.5535 16.794 13.2995L22 11.998L16.794 10.6965C15.7782 10.4426 15.2702 10.3156 14.8569 10.0511C14.4913 9.8172 14.1808 9.50672 13.9469 9.14114C13.6824 8.72782 13.5555 8.2199 13.3015 7.20404L12 1.99805Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-white text-lg font-bold  capitalize leading-7">
                    Comprehensive Support
                  </div>
                </div>
                <div className=" text-white/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Get access to tools, training, and a dedicated support team to
                  ensure smooth operations and personal growth.
                </div>
              </div>
              {/* 5 */}
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="gap-[8px] items-center inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2.49862V11.9986M12 11.9986L20.5 7.27633M12 11.9986L3.5 7.27633M12 11.9986V21.4986M20.5 16.7208L12.777 12.4303C12.4934 12.2727 12.3516 12.194 12.2015 12.1631C12.0685 12.1357 11.9315 12.1357 11.7986 12.1631C11.6484 12.194 11.5066 12.2727 11.223 12.4303L3.5 16.7208M21 16.0572V7.94006C21 7.59742 21 7.4261 20.9495 7.2733C20.9049 7.13813 20.8318 7.01404 20.7354 6.90935C20.6263 6.79102 20.4766 6.70782 20.177 6.54141L12.777 2.4303C12.4934 2.27274 12.3516 2.19396 12.2015 2.16308C12.0685 2.13574 11.9315 2.13574 11.7986 2.16308C11.6484 2.19396 11.5066 2.27274 11.223 2.4303L3.82297 6.54142C3.52345 6.70782 3.37369 6.79102 3.26463 6.90936C3.16816 7.01405 3.09515 7.13813 3.05048 7.2733C3 7.4261 3 7.59742 3 7.94007V16.0572C3 16.3998 3 16.5711 3.05048 16.7239C3.09515 16.8591 3.16816 16.9832 3.26463 17.0879C3.37369 17.2062 3.52345 17.2894 3.82297 17.4558L11.223 21.5669C11.5066 21.7245 11.6484 21.8033 11.7986 21.8342C11.9315 21.8615 12.0685 21.8615 12.2015 21.8342C12.3516 21.8033 12.4934 21.7245 12.777 21.5669L20.177 17.4558C20.4766 17.2894 20.6263 17.2062 20.7354 17.0879C20.8318 16.9832 20.9049 16.8591 20.9495 16.7239C21 16.5711 21 16.3998 21 16.0572Z"
                        stroke="#F46702"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text-white text-lg font-bold  capitalize leading-7">
                    Fuel Benefits:
                  </div>
                </div>
                <div className=" text-white/40 text-[15px] md:text-base font-medium  capitalize leading-7">
                  Enjoy subsidized fuel to keep you connected and
                  operational at all times.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" w-full flex justify-center">
        <div className="w-full max-w-[1246px] py-20 flex-col justify-start items-center gap-[60px] inline-flex">
          <div className="flex-col justify-start items-center flex">
            <div className=" text-center text-black text-[33px] md:text-[44px] font-bold  leading-[79.20px]">
              Ready to start earning?
            </div>
            <div className=" text-center text-[#696983] text-[18px] md:text-xl font-normal  leading-loose tracking-tight">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi
            </div>
          </div>
          <div className=" justify-center w-full flex-col md:flex-row items-start gap-[46px] inline-flex px-5 md:px-0">
            <div className="relative w-full max-w-[600px] h-[350px] md:h-[400px] rounded-[20px] flex flex-col justify-center items-center gap-2.5">
              {/* Background image */}
              <img
                src="https://s3-alpha-sig.figma.com/img/eb30/c225/23df4e5defce9a9ffb13c4d90947a683?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ei9ZaS0fvVn5hg3U61MNtsgWtsFPphT5Xi8ZQZfIg83hTRvX8F1XtOUv1MdyjotRe1rdDuB46rVqKwNIgz~pOeGAnFJOML0VE0vYNcpIdmQc8KEGmojKvt6JrqJhF9pqoONCNk9JJSZvL3IBKhNUZLCj3tlfXzZKymmrMS2EaPibJh7dgdasHFMDJ-P7rhW5HCg-3VIStEW57JbRs6Vfby4c674Z2Ot2eVrNEjYMmbt4MWf~F33G12bzWAkkWqEf7l4fwSY7Xj8-qgVNX1L-~~ZqXi71XqNvQpHUlr6J5Yy43QX5~YYyFEGts5ZaK8OZWtnlDO5j0kwiNhB7Fnq-rQ__"
                alt="car owner image"
                className="absolute inset-0 size-full object-cover rounded-[20px]"
              />
              {/* Black overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-[20px]"></div>
              {/* Content */}
              <div className="relative z-10 text-center text-white text-[28px] md:text-[32px] font-bold">
                FOR VEHICLE OWNERS
              </div>
              {/* <div >
              </div> */}
              <Link
                to="/auth/register"
                className="relative z-10 text-white text-[20px] md:text-2xl font-bold px-8 py-2.5 rounded-[100px] border border-white justify-center items-center inline-flex"
              >
                Get Started Now
              </Link>
            </div>
            <div className="relative w-full max-w-[600px] h-[350px] md:h-[400px] rounded-[20px] flex flex-col justify-center items-center gap-2.5">
              {/* Background image */}
              <img
                src="https://s3-alpha-sig.figma.com/img/38e0/270f/53c1a668dea9f8029a1d6b87e7c04e47?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=p-UoJcabN~06YaVur87AnT-FkcgwXzqGQPLmmVqugFImOVwnGkufUz4yTROiVBBuzMamDhKtVlJZWpCaQT9bidwfy~i9wu8uxqYobmnrE9CQnTm2Cyt4CGm5OulaAA68JedteDJTTJmTrBF4F3nnEu-Q6b2lknD7wo5pfqdNWxWmSEAafwjjo0kubtvREIsrVPcyUAq8zVuMMU2IguVhjGzPeSxcWIppXw6lFuqTgzRXD~D6t68-G7aI3rbiks37pIXM8y11OjyXdyOl8Iet8OWbG5d0L-drWYXOqxhmkuMVLWUUTegJ-B1o29SDH5~X8DG6Bf-YWkOu8CwAuK59oA__"
                alt="car owner image"
                className="absolute inset-0 size-full object-cover rounded-[20px]"
              />
              {/* Black overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-[20px]"></div>
              {/* Content */}

              <div className=" relative z-10 text-center text-white text-[28px] md:text-[32px] font-bold ">
                WANT TO RIDE?
              </div>

              <Link
                to="/auth/register"
                // className="px-[23px] py-[13px] bg-white rounded-[100px] justify-center items-center gap-2.5 inline-flex text-2xl font-bold"
                className="relative z-10 text-[#110e0d] text-[20px] md:text-2xl font-bold px-8 py-2.5 rounded-[100px] bg-white justify-center items-center inline-flex"
              >
                Get Started Now
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className=" w-full flex justify-center">
        <div className="flex flex-col items-cent er max-w-[1200px]  pt-20 overflow-hidden">
          <div className="flex flex-col items-center  w-full text-center mb-5">
            <div className="text-4xl md:text-5xl font-semibold text-black leading-[79px]">
              <span className="font-bold">Happy Earners</span>
              <span className="font-bold text-black">?</span>
            </div>
            <div className="text-[17px] md:text-xl tracking-wide leading-loose text-gray-500 max-md:max-w-full">
              Our happy user, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim
            </div>
          </div>

          {/* <div className="flex flex-wrap gap-10 items-center mt-14 max-md:mt-10 max-md:max-w-full"> */}
          {/* </div> */}
          <TestimoniesSlideComponent />
        </div>
      </section>

      <section className=" w-full flex justify-center" id="faq-section">
        <FAQComponent />
      </section>
      <FooterComponent />
    </div>
  );
}

export default LandingPage;

const VehicleCategory = ({ filterKey, name, image }) => {
  const navigate = useNavigate();

  const { setSearchFilter } = useDataContext();
  return (
    <div
      className="flex flex-col w-[48%] max:w-[539px] max-md:ml-0 max-md:w-full"
      onClick={() => {
        setSearchFilter((prev) => {
          return {
            ...prev,
            type: filterKey,
          };
        });
        navigate("/search");
      }}
    >
      <div className="flex flex-col grow p-1 w-full text-2xl font-bold tracking-tight leading-7 text-center whitespace-nowrap bg-white rounded-xl border border-solid border-neutral-400 text-neutral-900 max-md:mt-10 max-md:max-w-full">
        <h3 className="max-md:max-w-full">{name}</h3>
        <img
          loading="lazy"
          src={image}
          alt={`${name} category`}
          className="mt-5 w-full max-md:mr-2.5 max-md:max-w-full rounded-[10.592px] max-w-[250px] m-auto"
        />
      </div>
    </div>
  );
};

function HeroSectionCardSlideComponent({ images }) {
  return (
    <div className="HeroSectionCardSlideComponent ">
      <div class="scroll-content">
        {images.map((image, index) => (
          <div
            className="image-container h-[140px] min-w-[180px] md:h-[225px] md:min-w-[313px]"
            key={index}
          >
            <div className="size-full cursor-pointer overflow-hidden rounded">
              <img
                src={image}
                alt={`Car exterior ${index + 1}`}
                className="object-cover object-center size-full"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function FooterComponent() {
  return (
    <footer className="FooterComponent mt-10 bg-[#2E2E2E] w-full flex-col md:flex-row flex justify-evenly p-[20px] md:p-[64px] m-0 ">
      <div className="flex gap-10 md:gap-20 flex-wrap">
        <div className="w-[200px]">
          <h3>Menu</h3>
          <ul>
            <li>
              {" "}
              <Link to={"/contact"}>Contact us</Link>
            </li>
            <li>Team</li>
            <li>Careers</li>
            <li>
              <Link
                to={
                  "https://docs.google.com/forms/d/e/1FAIpQLSePBWb71n4SdOP8BTOchBvSMFQ5eTh7_leK-ES8nRxF63BtNQ/viewform?usp=sf_link"
                }
              >
                Partners
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-[200px]">
          <h3>Legal</h3>
          <ul>
            {/* <li>Papers and Insurance</li> */}
            <li>Trust and safety</li>
            <li>Policies</li>
            <li>Terms of use</li>
          </ul>
        </div>
        <div className="w-[200px]">
          <h3>Support</h3>
          <ul>
            <li>Policies</li>
            <li>
              <Link to={"https://wa.link/6cjao8"}>Customer support</Link>
            </li>
            <li>
              {" "}
              <a href="/#faq-section">Frequently asked questions</a>
            </li>
            <li>
              {" "}
              <Link to={"/how-it-works"}>How it works </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="md:border-l-[2px] md:ps-[10%]">
        <h3 className="text-white">Follow our socials</h3>

        <div className="icon-container flex gap-5 mt-5">
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M17.5078 6.5H17.4988"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 21L10.5484 13.4516M10.5484 13.4516L3 3H8L13.4516 10.5484M10.5484 13.4516L16 21H21L13.4516 10.5484M21 3L13.4516 10.5484"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M10.5359 11.0104C9.71585 10.8945 7.84666 11.0863 6.93011 12.7811C6.01355 14.4758 6.9373 16.2397 7.51374 16.9098C8.08298 17.5367 9.89226 18.7239 11.8114 17.5648C12.2871 17.2775 12.8797 17.0632 13.552 14.8182L13.4738 5.98438C13.3441 6.95711 14.4186 9.23868 17.478 9.50863"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="mt-10">
          <span>Copyright © 2024 Vooom </span>
          <img
            src={require("../../assets/image/white-logo.png")}
            alt="footer_logo"
            className="w-[120px]"
          />
        </div>
      </div>
    </footer>
  );
}

export function FAQComponent() {
  const faqItems = [
    {
      id: 1,
      question: "What is Vooom?",
      answer:
        "Vooom is a logistics peer-to-peer (P2P) management platform that connects delivery riders, motorcycle owners, all under the Parent company - Binnox.",
    },
    {
      id: 2,
      question: "How does Vooom benefit Logistics Bike owners?",
      answer:
        "Logistics Bikes owners can earn passive income by listing their Delivery Motorcycles for delivery services on Vooom. The platform handles rider management, maintenance, and logistics, ensuring a stress-free investment.",
    },
    {
      id: 3,
      question: "Can I join Vooom as a freelance delivery rider?",
      answer:
        "Yes! Vooom welcomes freelance riders who want to be part of the Vooom Verified riders community, to use Logistics Motorcycles listed on the platform. Riders enjoy flexible schedules, consistent jobs, and competitive earnings.",
    },
    {
      id: 4,
      question: "What types of vehicles can I list on Vooom?",
      answer:
        "You can list motorcycles suitable for logistics purposes. These Motorcycles must meet the platform's standards for maintenance and documentation.",
    },
    {
      id: 5,
      question: "How are earnings calculated for vehicle owners and riders?",
      answer:
        "Earnings are based on your choice city of operation. We basically operate in Lagos and Abuja.",
    },
    {
      id: 6,
      question: "Is there insurance coverage for vehicles listed on Vooom?",
      answer:
        "Yes, vehicles listed on Vooom are insured as part of the service, providing peace of mind to vehicle owners.",
    },
    {
      id: 7,
      question: "How do I onboard my vehicle or start as a rider?",
      answer:
        "Simply sign up on the Vooom platform, upload your vehicle or rider documents, and complete the verification process. Our team will guide you through the setup.",
    },
    {
      id: 8,
      question: "What areas does Vooom currently operate in?",
      answer:
        "Vooom is active in major cities across Nigeria, including Lagos, Abuja, with plans for expansion into more regions.",
    },
    {
      id: 9,
      question: "What happens if my vehicle breaks down?",
      answer:
        "Vooom offers a maintenance support system to ensure vehicles are always in top condition. Owners and riders can access discounted repair services through the platform.",
    },
    {
      id: 10,
      question: "Can businesses partner with Vooom for logistics?",
      answer:
        "Absolutely! Businesses can leverage Vooom’s network of riders and vehicles for efficient and reliable delivery services tailored to their needs.",
    },
  ];

  return (
    <div className="flex flex-col px-5 py-20 w-full max-w-[1060px]">
      <div className="self-center max-w-full text-4xl font-bold leading-snug text-black w-[590px] max-md:max-w-full">
        Frequently Asked Questions
      </div>
      <div className="flex flex-col mt-16 w-full max-md:mt-10 max-md:max-w-full">
        {faqItems.map((item, index) => (
          <div key={item.id} className={index > 0 ? "mt-3" : ""}>
            <FAQItem question={item.question} answer={item.answer} />
          </div>
        ))}
      </div>
    </div>
  );
}

function FAQItem({ question, answer }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col">
      <div
        className="flex min-h-[65px] py-1 items-center gap-3 border px-[10px] border-solid border-stone-300 border-opacity-50 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div className="flex">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="8" width="20" height="4" rx="2" fill="#F46702" />
            {!open && (
              <rect x="8" width="4" height="20" rx="2" fill="#F46702" />
            )}
          </svg>
        </div>
        <div className="text-xl font-bold leading-snug text-black ">
          {question}
        </div>
      </div>
      <div
        className={` overflow-hidden transition-all duration-300 ease-in-out ps-1 ${
          !open ? "h-0" : "h-auto py-2"
        }`}
      >
        {answer}
      </div>
    </div>
  );
}

function CustomSlideCardCompinent(props) {
  const { testimony, index, ...otherProps } = props;

  return (
    <div
      tabIndex={-1}
      className="bor der border-red-500 flex flex-col justify-center items-center text-center mx-3"
    >
      <div key={index} className="relative size-[140px] mb-3 overflow-hid den ">
        {/* Diamond Layer */}
        {/* <div className="absolute inset-0 clip-diamond "> */}
        <img
          loading="lazy"
          src={testimony.src}
          alt={testimony.alt}
          className="w-full h-full object-cover object-center"
        />
        {/* </div> */}
      </div>

      <h3 className="text-center text-black/40 text-lg font-semibold leading-snug tracking-tight">
        {testimony.text}
      </h3>
      <h3 className="text-center text-[#f46702] text-[22px] font-bold leading-10">
        {testimony.author}
      </h3>
    </div>
  );
}

function TestimoniesSlideComponent() {
  const testimonialImages = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/e85d4038ac5a41748b51322169f61f43/85e0ed93a70b6d3dd2381433067d8340742c69afddb7c15e6f7525c053ecac4b?apiKey=e85d4038ac5a41748b51322169f61f43&",
      alt: "Happy customer testimonial",
      text: "I was tired of managing riders and handling repairs. Now, Vooom does it all while I focus on enjoying the passive income!",
      author: "Deli Logistics",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/e85d4038ac5a41748b51322169f61f43/ab9c123fffba0ecd2a5891440ddcc9f039d85907016cdd24e1133298c441e597?apiKey=e85d4038ac5a41748b51322169f61f43&",
      alt: "Customer success story",
      text: "I handed over my bikes to Vooom, and in just a few months, I started seeing profits without any stress. It’s the best decision I made in 2024!",
      author: "Mr Tolu",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/e85d4038ac5a41748b51322169f61f43/2ee897e8515c9e503a4d55c1cf29e2f632c8d36dc31d7e6dbeb6e462a88831cb?apiKey=e85d4038ac5a41748b51322169f61f43&",
      alt: "User testimonial",
      text: "I bought bikes as an investment. Vooom manages them, and I earn steady income every month.",
      author: "Gudell Logistics",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/e85d4038ac5a41748b51322169f61f43/1368a4014e2b4fa2a679a1ee7e6903f1a7ca1ec13159f4978d83033e8b2da357?apiKey=e85d4038ac5a41748b51322169f61f43&",
      alt: "Client feedback",
      text: "With Vooom, I can work on my own terms. I decide when and how much I want to work, which has been perfect for balancing my side hustle. The weekly earnings are great, and the incentives motivate me to deliver more.",
      author: "Kehinde, Rider",
    },
    // {
    //   src: "https://cdn.builder.io/api/v1/image/assets/e85d4038ac5a41748b51322169f61f43/84f84f67fb3fe82ee65631cd03daa9a9b1cbe69cc0e4a16e43ccc5caeb3a7a7a?apiKey=e85d4038ac5a41748b51322169f61f43&",
    //   alt: "Customer review",
    //   text: "My wife and I have been lo0king for an extra stream of income till we were introduced to the vooom app, now we earn without stress weekly!",
    //   author: "Mr. Moyo Nwosu",
    // },
  ];
  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  });
  useEffect(() => {
    const updateSettings = () => {
      setSettings((prev) => ({
        ...prev,
        slidesToShow: window.innerWidth < 768 ? 1 : 3,
      }));
    };

    // Initial check
    updateSettings();

    // Event listener
    window.addEventListener("resize", updateSettings);

    // Cleanup listener
    return () => window.removeEventListener("resize", updateSettings);
  }, []);

  return (
    <div className="slider-container">
      {/* READ Docs: https://react-slick.neostack.com/docs */}
      <Slider {...settings}>
        {testimonialImages.map((testimony, index) => (
          <CustomSlideCardCompinent
            testimony={testimony}
            index={index}
            key={index}
          />
        ))}
      </Slider>
    </div>
  );
}
