import React, { useEffect, useState } from "react";
import NavigationComponent from "../../components/navigation/NavigationComponent";
import { Link } from "react-router-dom";

import { useLocation, useHistory, useNavigate } from "react-router-dom";

function SelectPurposePage() {
  const [nextAction, setNextAction] = useState("login");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let action = queryParams.get("action");

  useEffect(() => {
    if (!action || (action !== "login" && action !== "register")) {
      navigate("/");

      // console.log({ action });
    }
    setNextAction(action);
  }, [action]);

  const purposes = [
    {
      title: "I am a vehicle owner",
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/2d6511dbe39f4af7b1efa0a103e9fec44f6238d239c0c3d067a4d0f2ffb99b16?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43",
      className: "bg-orange-600 bg-opacity-10",
      linkTo: `/auth/${nextAction}?action=owner`,
    },
    {
      title: "I need a Bike /vehicle",
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/807d1e8d041fc6d12e45dcefa5d98d495799b7fa498d3f3faa0fa248f2dabbfc?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43",
      className: "bg-black bg-opacity-10",
      linkTo: `/auth/${nextAction}?action=rider`,
    },
  ];

  return (
    <main className="flex overflow-hidden flex-col  bg-white h-full md:pb-24">
      <div className="fixed bg-white top-0 w-full">
        {" "}
        <NavigationComponent />
      </div>
      <section className="flex flex-col justify-center items-center self-center p-10 py-20 md:p-20 mt-32 md:mt-52  rounded-2xl bg-neutral-50  w-full md:w-[697px]">
        <div className="flex flex-col justify-center items-center max-w-full tracking-tight text-center text-neutral-900 w-[249px]">
          <h1 className="text-4xl font-bold leading-none">Select purpose</h1>
          <p className="mt-5 text-xl leading-7 font-[450]">
            Select your purpose for{" "}
            {nextAction == "register" ? "creating" : "Logging"} account.
          </p>
        </div>
        <div className="border flex gap-5 mt-5">
          <button
            className={`w-36 h-10  border-[2px] border-[#121212] rounded-md ${
              nextAction === "login" && "bg-[#F46702] text-white"
            }`}
            onClick={() => setNextAction("login")}
          >
            Login
          </button>
          <button
            className={`w-36 h-10  border-[2px] border-[#121212] rounded-md ${
              nextAction === "register" && "bg-[#F46702] text-white"
            }`}
            onClick={() => setNextAction("register")}
          >
            Register
          </button>
        </div>
        <div className="flex flex-col mt-10 w-full max-w-[537px] max-md:mt-10 max-md:max-w-full">
          {purposes.map((purpose, index) => (
            <PurposeCard key={index} {...purpose} index={index} />
          ))}
        </div>
      </section>
    </main>
  );
}

export default SelectPurposePage;

function PurposeCard({ title, imageSrc, className, linkTo, index }) {
  return (
    <Link
      to={linkTo}
      className={`px-7 py-1 w-full rounded-md ${className} max-md:px-5 mt-10 first:mt-0  border-[2px] border-[#121212]`}
    >
      <div
        className={`${
          index === 1 ? "flex-row-reverse" : ""
        } flex justify-evenly`}
      >
        <h2 className="self-stretch my-auto text-2xl font-bold tracking-tighter leading-none text-neutral-900 ">
          {title}
        </h2>
        <div className="flex flex-col w-40 md:w-52">
          <img
            loading="lazy"
            src={imageSrc}
            alt=""
            className="object-contain grow shrink-0 max-w-full aspect-[1.33] w-full "
          />
        </div>
      </div>
    </Link>
  );
}
