import React, { useContext, useEffect, useState } from "react";
import NavigationComponent from "../../components/navigation/NavigationComponent";
import { UploadInputField } from "../AuthPage/AccountSetupPage";
import ModelPopupComponent from "../../components/modalPopup/ModelPopupComponent";
import LocationInput from "../../components/locationInput/LocationInputComponent";

import CongratulationsImage from "../../assets/image/CongratulationsImage.png";
import { ModalContext } from "../../Context/modalContext";
import { toast } from "react-toastify";
import { useAuthContext } from "../../Context/authContext";
import axios from "axios";
import { useDataContext } from "../../Context/dataContext";
import { Link } from "react-router-dom";
import {
  InputField,
  SelectDropDownField,
} from "../../components/FormElementComponent/FormElementComponent";
// import CongratulationsImage from "../../";
function HostVehiclePage() {
  const { openModelFunction } = useContext(ModalContext);
  const {} = useDataContext();
  const [currentStep, setCurrentStep] = useState(0);
  const { token, endpoint } = useAuthContext();
  const progressSteps = [
    {
      step: 1,
      label: "Vehicle Details",
      content: <VehicleHostingForm action={nextStepFunction} />,
    },
    // {
    //   step: 2,
    //   label: "Vehicle Features",
    //   content: <VehicleFeaturesForm action={nextStepFunction} />,
    // },
    {
      step: 2,
      label: "Vehicle Image",
      content: <UploadCarImageForm action={handelCreateVehicleFunction} />,
    },
  ];

  const [hostVehicleFormData, setHostVehicleFormData] = useState({});

  function handelCreateVehicleFunction(formInformationData) {
    if (formInformationData) {
      console.log({ formInformationData });
      setHostVehicleFormData((prev) => ({
        ...prev,
        ...formInformationData,
      }));
    }

    // return console.log({ hostVehicleFormData });
    let data = JSON.stringify(hostVehicleFormData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${endpoint}/vehicle`,
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setHostVehicleFormData({});
        openModelFunction();
      })
      .catch((error) => {
        console.log(error);
      });

    // console.log({ hostVehicleFormData });
  }

  function nextStepFunction(stepIndex, formInformationData) {
    if (formInformationData) {
      setHostVehicleFormData({
        ...hostVehicleFormData,
        ...formInformationData,
      });
    }
    console.log({ hostVehicleFormData });
    setCurrentStep(stepIndex);
  }

  return (
    <main className="flex overflow-hidden flex-col pb-56 max-md:pb-24 ">
      <section className="flex flex-col flex-wrap gap-10 mt-10 items-center justify-center w-full ">
        {/* Step Indicator */}
        <div className="flex space-x-4">
          {progressSteps.map((step, index) => (
            <div
              key={index}
              className={`flex flex-col items-center cursor-pointer ${
                currentStep === index ? "text-orange-600" : "text-gray-400"
              }`}
              onClick={() => setCurrentStep(index)}
            >
              {/* <div className="font-medium">{step.step}</div> */}
              <div
                className={`h-1 w-1 ${
                  currentStep === index ? "bg-orange-600" : "bg-gray-400"
                }`}
              />
              <div className="text-sm">{step.label}</div>
            </div>
          ))}
        </div>

        {progressSteps[currentStep]?.content}
        <ModelPopupComponent trigger={<></>}>
          <CongratulationModelBody />
        </ModelPopupComponent>
      </section>
    </main>
  );
}

export default HostVehiclePage;

function VehicleHostingForm({ action }) {
  const [vehicleInfo, setVehicleInfo] = useState({});

  function handelInputChanges(e) {
    // console.log(e.target.name, e.target.value);
    setVehicleInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  const handleLocationSelect = (place) => {
    // Assuming you want to save the selected place's details
    setVehicleInfo((prev) => ({
      ...prev,
      pick_up_location: place.description, // or any other property you want to save
      location_lat: place.geometry.location.lat(),
      location_lng: place.geometry.location.lng(),
    }));
  };

  function handelSelectChanges(name, value) {
    setVehicleInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const vehicleInfoFields = [
    {
      label: "Location",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4ff5ee4ea1f046d7091e8aa945676da93bc80fc09026d068c5aee051f11d42bb?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      // optionData: ["Car", "Truck", "Motorcycle", "SUV"],
      name: "pick_up_location",
      optionData: ["FCT Abuja", "Lagos"],
    },
    {
      label: "Vehicle type",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e976569a02bfe8d3bbc277d278da78a5b0de5d9a8e600b368bf27487bbbc8532?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: ["Bike"],
      name: "vehicle_type",
    },
    {
      label: "Bike Model",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8817e337f08e011aebe2e8256fd86234b8db663315e00994a6c9f92bf61bcd54?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: ["QLink", "Yamaha", "Sinoki", "Honda"],
      name: "vehicle_company",
    },
    {
      label: "Chassis Number",
      type: "input",
      // icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8817e337f08e011aebe2e8256fd86234b8db663315e00994a6c9f92bf61bcd54?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
      optionData: ["QLink", "Yamaha", "Sinoki", "Honda"],
      name: "chassis_number",
    },
    // {
    //   label: "Timeline available for rent",
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/49fd9e7c5094afe1a7654815a7d3d487f926263b983738cd2fc1f7eb8c351066?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
    //   optionData: [
    //     "Long term | Commercial use",
    //     "Working hours (10am – 4pm)",
    //     "Night time (7pm – 7am)",
    //   ],
    //   name: "timeline_rent",
    // },
    // {
    //   label: "Distance",
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/49fd9e7c5094afe1a7654815a7d3d487f926263b983738cd2fc1f7eb8c351066?placeholderIfAbsent=true&apiKey=e85d4038ac5a41748b51322169f61f43",
    //   optionData: ["100km", "150km", "200km", "300km"],
    //   name: "distance_allowed",
    // },
  ];
  const {
    pick_up_location,
    vehicle_type,
    vehicle_company,
    // timeline_rent,
    // available_date,
    // distance_allowed,
    chassis_number,
  } = vehicleInfo;
  const requiredProperties = [
    pick_up_location,
    vehicle_type,
    vehicle_company,
    // timeline_rent,
    // available_date,
    // distance_allowed,
    chassis_number,
  ];
  return (
    <main className="flex flex-col px-5 w-full md:max-w-[689px] relative z-10">
      <section className="flex flex-col max-md:max-w-full">
        <h1 className="text-2xl font-medium text-neutral-900">
          Host your vehicle
        </h1>
        <form className="flex flex-col justify-center items-center mt-5 w-full text-xl max-md:max-w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            {vehicleInfoFields.map((field, index) => (
              <React.Fragment key={index}>
                {field.type === "input" ? (
                  <InputField
                    icon={field.icon}
                    label={field.label}
                    name={field?.name}
                    className="my-2 w-full"
                    handelInputChanges={handelInputChanges}
                    stateData={vehicleInfo}
                    key={`vehicleInfoFields${index}`}
                  />
                ) : (
                  <SelectDropDownField
                    label={field.label}
                    optionData={field.optionData}
                    className="my-2 w-full"
                    stateData={vehicleInfo}
                    key={index}
                    name={field?.name}
                    handelSelectChanges={handelSelectChanges}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
          <button
            type="button"
            onClick={() => {
              // console.log({ vehicleInfo });

              const missingProperties = requiredProperties.filter(
                (property) => {
                  console.log(property);
                  return !property;
                }
              );

              if (missingProperties.length > 0) {
                return toast.error(`Fill the required input`);
              }
              action(1, vehicleInfo);
            }}
            className=" px-3 py-4 mt-5 text-center text-white bg-black rounded-full w-full"
          >
            Next
          </button>
        </form>
      </section>
    </main>
  );
}

function VehicleFeaturesForm({ action }) {
  const features = [
    // {
    //   label: "Gear transmission",
    //   options: [
    //     {
    //       label: "Automatic",
    //       selected: true,
    //     },
    //     { label: "Manual", selected: false },
    //   ],
    //   name: "gear_transmission",
    // },
    // {
    //   label: "Bluetooth",
    //   options: [
    //     {
    //       label: "Yes",
    //       selected: true,
    //     },
    //     { label: "No", selected: false },
    //   ],
    //   name: "bluetooth",
    // },
    /*{
      label: "Android auto",
      options: [
        {
          label: "Yes",
          selected: true,
        },
        { label: "No", selected: false },
      ],

      name: "android_auto",
    },*/
    // {
    //   label: "Rear camera",
    //   options: [
    //     {
    //       label: "Yes",
    //       selected: true,
    //     },
    //     { label: "No", selected: false },
    //   ],
    //   name: "rear_camera",
    // },
    // {
    //   label: "AUX",
    //   options: [
    //     { label: "Yes", selected: false },
    //     {
    //       label: "No",
    //       selected: true,
    //     },
    //   ],
    //   name: "aux_in",
    // },
    {
      label: "GPS",
      options: [
        { label: "Yes", selected: false },
        {
          label: "No",
          selected: true,
        },
      ],
      name: "gps",
    },
    {
      label: "USB output",
      options: [
        { label: "Yes", selected: false },
        {
          label: "No",
          selected: true,
        },
      ],
      name: "usb_out",
    },
  ];

  const [vehicleHealthInfo, setVehicleHealthInfo] = useState({});
  const [vehicleFeatureInfo, setVehicleFeatureInfo] = useState({});

  function handelInputChanges(e) {
    if (e.target.type === "radio") {
      const selectedValue = e.target.value === "True";
      setVehicleFeatureInfo((prev) => {
        return {
          ...prev,
          [e.target.name]: selectedValue,
        };
      });
    } else {
      setVehicleHealthInfo((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
    }
  }
  const { body_exterior, engine, additional_note } = vehicleHealthInfo;
  const requiredProperties = [
    // pick_up_location,
    // vehicle_type,
    // vehicle_company,
    // vehicle_name,
    // model,
    // timeline_rent,
    // available_date,
    // distance_allowed,
    // features,
    // health_status,
    // gear_transmission,
    // gps,
    // rear_camera,
    // bluetooth,
    // usb_in,
    // usb_out,
    // aux_in,
    // android_auto,
    //body_exterior,
    //engine,
    //additional_note,
  ];

  return (
    <main className="flex flex-col tracking-tight px-5">
      <h1 className="text-3xl font-medium leading-none text-neutral-900">
        {/* <button className="mr-4" onClick={() => action(0)}>
          {"<-"}
        </button> */}
        Host your vehicle
      </h1>
      <section className="flex flex-col justify-center items-center mt-8 md:mt-16 w-full max-md:mt-10">
        <div className="flex flex-col w-full">
          <h2 className="gap-2.5 self-stretch  w-full text-2xl font-medium leading-none whitespace-nowrap text-neutral-900">
            Features
          </h2>
          <div className="flex flex-wrap gap-0 lg:gap-16 items-center self-center mt-8 text-xl leading-none max-w-[1120px]">
            {features.map((feature, index) => (
              <FeatureToggle
                key={index}
                label={feature.label}
                options={feature.options}
                name={feature.name}
                handelInputChanges={handelInputChanges}
              />
            ))}
          </div>
        </div>
        <button
          className="gap-3  px-3 py-4 mt-20 mx-auto  text-xl font-medium tracking-tight leading-none text-center text-white bg-orange-600 rounded-xl border border-solid border-black border-opacity-60 min-h-[60px] shadow-[2px_2px_9px_rgba(0,0,0,0.2)] w-full md:max-w-[420px] max-md:mt-10"
          onClick={() => {
            const missingProperties = requiredProperties.filter((property) => {
              // console.log(property);
              return !property;
            });

            if (missingProperties.length > 0) {
              return toast.error(`Fill the required input`);
            }
            action(2, {
              features: vehicleFeatureInfo,
              health_status: vehicleHealthInfo,
            });
          }}
        >
          Next
        </button>
      </section>
    </main>
  );
}

function FeatureToggle({ name, label, handelInputChanges }) {
  return (
    <div className="flex flex-col grow shrink self-stretch py-2 md:py-5 my-auto min-w-[240px] w-[318px]">
      <div className="font-medium text-neutral-900">{label}</div>
      <div className="flex gap-3 items-start mt-6 whitespace-nowrap font-[450] text-neutral-900 text-opacity-80">
        <label
          className={`flex gap-5 md:gap-10 p-2 md:p-5 w-[150px] md:w-[193px]`}
        >
          <div className="my-auto">True</div>
          <input
            type="radio"
            name={name}
            value={"True"}
            className="my-auto"
            onChange={handelInputChanges}
          />
        </label>
        <label
          className={`flex gap-5 md:gap-10 p-2 md:p-5 w-[150px] md:w-[193px]`}
        >
          <div className="my-auto">False</div>
          <input
            type="radio"
            name={name}
            value={"False"}
            className="my-auto"
            onChange={handelInputChanges}
          />
        </label>
      </div>
    </div>
  );
}

export function ImageUploadInput({ placeholder, onImageUpload, multiple }) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ostkv0vd");

    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/diki2xwvj/image/upload`,
        formData,
        {
          onUploadProgress: (event) => {
            const percentCompleted = Math.round(
              (event.loaded * 100) / event.total
            );
            setProgress(percentCompleted);
          },
        }
      );
      setLoading(false);
      return response.data.secure_url; // Return the uploaded image URL
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
      return null; // Return null in case of an error
    }
  };

  const handleImageChange = async (event) => {
    const files = event.target.files;
    if (files) {
      if (multiple) {
        // Handle multiple file uploads
        const uploadedImages = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const url = await uploadImage(file);
          if (url) uploadedImages.push(url); // Collect successful uploads
        }
        onImageUpload(uploadedImages); // Pass all uploaded URLs back to parent
      } else {
        // Handle single file upload
        const file = files[0];
        const url = await uploadImage(file);
        onImageUpload(url); // Pass the uploaded image URL back to parent
      }
    }
  };

  return (
    <>
      <label
        htmlFor={placeholder.toLowerCase().replace(/\s+/g, "-")}
        className="flex gap-2 justify-center items-center py-8 mt-5 w-full font-medium tracking-tight leading-7 rounded-lg text-neutral-900 max-md:px-5 border border-dashed border-[#7c7c7c] cursor-pointer"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a9917c3fe2a46b9166750538f2436cc08df722b464f7dc9c96556276d830dd1?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
        />
        {placeholder}
        <input
          type="file"
          id={placeholder.toLowerCase().replace(/\s+/g, "-")}
          className="sr-only"
          accept="image/*"
          onChange={handleImageChange} // Attach the onChange handler
          multiple={multiple} // Allow multiple uploads if specified
        />
      </label>
      {loading && (
        <p>
          Uploading {placeholder}... {progress}%
        </p>
      )}
    </>
  );
}

function UploadCarImageForm({ action }) {
  const [images, setImages] = useState([]); // State to hold all uploaded images

  const handleImagesUpload = (urls) => {
    setImages(urls); // Set uploaded URLs for all images
  };

  const handleSubmit = () => {
    // console.log({
    //   vehicle_images: images.filter((item) => item !== ""), // Filter out empty strings
    // });
    action({
      vehicle_images: images.filter((item) => item !== ""),
    });
  };

  return (
    <main className="flex flex-col tracking-tight w-full max-w-[607px] pe-10 ps-5 md:p-0">
      <h1 className="text-2xl font-medium leading-none text-neutral-900">
        Vehicle Image
      </h1>
      <div className="w-full m-3 inline-flex flex-col">
        <ImageUploadInput
          placeholder="Upload Images"
          multiple
          onImageUpload={handleImagesUpload} // Handle multiple images upload
        />

        <div className="md:flex gap-10 mt-4">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Uploaded ${index}`}
              className="w-32 h-32 object-cover"
            />
          ))}
        </div>
        <button
          onClick={handleSubmit}
          className=" px-3 py-4 mt-5 text-center text-white bg-black rounded-full w-full"
        >
          Host vehicle
        </button>
      </div>
    </main>
  );
}

function CongratulationModelBody() {
  const { isModalOpen, closeModelFunction, openModelFunction } =
    useContext(ModalContext);
  return (
    <div className="h-[500px] w-full mx-3 md:w-[520px] mt-40 md:mt-0 relative z-[999] bg-white rounded-xl shadow border border-[#666666]/80  flex-col justify-center items-center gap-4 flex">
      <img className="w-60 h-[211.30px]" src={CongratulationsImage} />
      <div className="self-stretch h-[72px]">
        <div className="self-stretch text-center text-[#121212] text-[32px] font-medium font-['Circular Std'] leading-[34px]">
          Congratulations!
        </div>
        <div className=" text-center text-[#121212]/70 text-xl font-medium font-['Circular Std'] leading-snug mt-0.5">
          Your vehicle is now live.
        </div>
      </div>

      <Link
        to={"/dashboard/"}
        onClick={() => closeModelFunction()}
        className=" px-3 py-4 mt-5 text-center text-white bg-black rounded-full w-[420px]"
        // className="text-center text-white text-xl font-medium font-['Circular Std'] leading-[26.96px] w- h-[60px] p-3 bg-[#f46702] rounded-xl shadow border border-black/60 justify-center items-center gap-3 inline-flex cursor-pointer"
      >
        View dashboard
      </Link>
    </div>
  );
}
