import React from 'react';

export const StatCard = ({ title, value, icon, iconBgColor = 'bg-rose-200' }) => {
  return (
    <div className="flex flex-col justify-center items-center self-stretch px-4 py-6 my-auto bg-white rounded-3xl min-h-[148px] min-w-[240px] shadow-[0px_9.36px_18.13px_0px_#00000003] w-[257px]">
      <div className="flex flex-col w-full max-w-[208px]">
        <div className="flex gap-10 justify-between items-center w-full text-base font-bold text-black">
          <div className="self-stretch my-auto">{title}</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/61f129d2b9c0f82f3aabd2c82250a5480a3ab6374db11e383378609cd520d551?placeholderIfAbsent=true&apiKey=6c1d99a466544391a3b6e363789d09fb"
            alt=""
            className="object-contain shrink-0 self-stretch my-auto aspect-[0.3] w-[3px]"
          />
        </div>
        <div className="flex gap-7 justify-between items-center mt-7 w-full">
          <div className="self-stretch my-auto text-2xl font-bold text-black w-[126px] text-nowrap">
            {value}
          </div>
          <div
            className={`flex gap-2 items-center self-stretch p-4 my-auto ${iconBgColor} rounded-xl h-[54px] min-h-[54px] w-[54px]`}
          >
            <img
              loading="lazy"
              src={icon}
              alt={`${title} icon`}
              className="object-contain self-stretch my-auto rounded-none aspect-[0.92] w-[23px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};