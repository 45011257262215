import React, { useEffect, useRef, useState } from "react";
import NavigationComponent from "../../components/navigation/NavigationComponent";
import SearchFormComponent, {
  SearchBarComponent,
} from "../../components/search/SearchFormComponent";
import "./searchPage.scss";
import {
  BikeIconSvg,
  CarIconSvg,
  FilterSearchIconSvg,
  LorryIconSvg,
  VanIconSvg,
} from "../../icons";
import CarRentalCardComponent from "../../components/carRentalCard/CarRentalCardComponent";
import { useDataContext } from "../../Context/dataContext";
import { Spinner } from "@chakra-ui/react";

function SearchPage() {
  const { listedVehicle, SearchFilter, setSearchFilter } = useDataContext();
  const [filterToggle, setFilterToggle] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [filterByTypeButton, setFilterByType] = useState([
    {
      key: "Bike",
      iconSvg: <BikeIconSvg className="text-[40px]" />,
    },
    {
      key: "Car",
      iconSvg: <CarIconSvg className="text-[40px]" />,
    },
    {
      key: "Van",
      iconSvg: <VanIconSvg className="text-[40px]" />,
    },
    {
      key: "Lorry",
      iconSvg: <LorryIconSvg className="text-[40px]" />,
    },
  ]);
  // useEffect(() => {
  //   console.log({ searchResult });
  // }, [searchResult]);

  // useEffect(() => {
  //   console.log("listedVehicle:", listedVehicle);
  //   console.log("SearchFilter:", SearchFilter);

  //   // Check if listedVehicle or its data property is undefined
  //   if (!listedVehicle || !Array.isArray(listedVehicle.data)) {
  //     console.warn(
  //       "Exiting: listedVehicle or listedVehicle.data is undefined or not an array"
  //     );
  //     return;
  //   }

  //   // If both type and key are empty, display all listed vehicles
  //   if (SearchFilter.type === "" && SearchFilter.key === "") {
  //     setSearchResult(listedVehicle.data);
  //     console.log("Displaying all vehicles because both type and key are empty");
  //     return;
  //   }

  //   // Filter based on both type and key if both are provided, otherwise by either
  //   const filteredArray = listedVehicle.data.filter((vehicle) => {
  //     const typeMatch = SearchFilter.type
  //       ? vehicle?.vehicle_type?.toLowerCase() === SearchFilter.type.toLowerCase()
  //       : true; // If no type filter, match all types

  //     const keyMatch = SearchFilter.key
  //       ? vehicle.vehicle_company
  //           ?.toLowerCase()
  //           .includes(SearchFilter.key.toLowerCase()) ||
  //         vehicle.model?.toLowerCase().includes(SearchFilter.key.toLowerCase()) ||
  //         vehicle.pick_up_location
  //           ?.toLowerCase()
  //           .includes(SearchFilter.key.toLowerCase())
  //       : true; // If no key filter, match all keys

  //     return typeMatch && keyMatch; // Require both matches if both filters are present
  //   });

  //   console.log("Filtered Array:", filteredArray);

  //   // Update the search results with the filtered array directly, without sorting
  //   setSearchResult(filteredArray);
  // }, [SearchFilter, listedVehicle]);

  useEffect(() => {
    // console.log("listedVehicle:", listedVehicle);
    // console.log("SearchFilter:", SearchFilter);

    // Check if listedVehicle or its data property is undefined
    if (!listedVehicle || !Array.isArray(listedVehicle.data)) {
      console.warn(
        "Exiting: listedVehicle or listedVehicle.data is undefined or not an array"
      );
      return;
    }

    // If both type and key are empty, display all listed vehicles
    if (SearchFilter.type === "" && SearchFilter.key === "") {
      setSearchResult(listedVehicle.data);
      console.log(
        "Displaying all vehicles because both type and key are empty"
      );
      return;
    }

    // Filter based on both type and key if both are provided, otherwise by either
    const filteredArray = listedVehicle.data.filter((vehicle) => {
      const typeMatch = SearchFilter.type
        ? vehicle?.vehicle_type?.toLowerCase() ===
          SearchFilter.type.toLowerCase()
        : true; // If no type filter, match all types

      const keyMatch = SearchFilter.key
        ? vehicle.vehicle_company
            ?.toLowerCase()
            .includes(SearchFilter.key.toLowerCase()) ||
          vehicle.model
            ?.toLowerCase()
            .includes(SearchFilter.key.toLowerCase()) ||
          vehicle.pick_up_location
            ?.toLowerCase()
            .includes(SearchFilter.key.toLowerCase())
        : true; // If no key filter, match all keys

      return typeMatch && keyMatch; // Require both matches if both filters are present
    });

    // console.log("Filtered Array:", filteredArray);

    // Update the search results with the filtered array directly, without sorting
    setSearchResult(filteredArray);
  }, [SearchFilter, listedVehicle]);

  function handelInputChanges(e) {
    setSearchFilter((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  return (
    <div className="SearchPage flex flex-col justify-end bg-white">
      <NavigationComponent />
      <main className="self-center mt-6 w-full max-w-[1200px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {filterToggle && <SearchFormComponent filterToggle={filterToggle} />}
          <div
            className={`flex flex-col ml-5 w-full max-md:ml-0 max-md:w-full`}
          >
            <div className=" flex items-center">
              <SearchBarComponent handelInputChanges={handelInputChanges} />
              <FilterToggleButton
                setFilterToggle={setFilterToggle}
                filterToggle={filterToggle}
              />
            </div>
            <div className="flex flex-col grow tracking-tighter max-md:mt-10 max-md:max-w-full ">
              {/* <LocationSearch /> */}
              {/* <ScrollContainer /> */}
              {/* <RecentSearches /> */}
              <div className="inline-flex items-center justify-center gap-10 md:gap-20 mt-5">
                {SearchFilter.type !== "" && (
                  <div
                    className={`inline-flex flex-col items-center justify-center gap-0.5 relative flex-[0_0_auto] cursor-pointer `}
                    onClick={() =>
                      setSearchFilter((prev) => {
                        return {
                          ...prev,
                          type: "",
                        };
                      })
                    }
                  >
                    <div className="icon-container mb-2">
                      {/* {button.iconSvg} */}
                    </div>

                    <div className="relative w-fit [font-family:'Circular_Std-Medium',Helvetica] fo nt-medium tex t-[#121212] text-xl tracking-[-0.43px] leading-3 whitespace-nowrap">
                      All
                    </div>
                  </div>
                )}
                {filterByTypeButton.map((button, index) => {
                  return (
                    <div
                      key={index}
                      className={`inline-flex flex-col items-center justify-center gap-0.5 relative flex-[0_0_auto] cursor-pointer ${
                        SearchFilter.type === button.key &&
                        "border-black pb-1 border-b-[2px] text-black font-bold"
                      }`}
                      onClick={() =>
                        setSearchFilter((prev) => {
                          return {
                            ...prev,
                            type: button.key,
                          };
                        })
                      }
                    >
                      <div className="icon-container mb-2">
                        {button.iconSvg}
                      </div>

                      <div className="relative w-fit [font-family:'Circular_Std-Medium',Helvetica] fo nt-medium tex t-[#121212] text-xl tracking-[-0.43px] leading-3 whitespace-nowrap">
                        {button.key}
                      </div>
                    </div>
                  );
                })}
              </div>

              {listedVehicle.loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                  className="mt-20 mx-auto"
                />
              ) : (
                <section className="flex flex-wrap justify-center gap-10 my-10">
                  {/* {searchResult} */}
                  {searchResult?.map((vehicle, index) => {
                    return (
                      <CarRentalCardComponent
                        vehicleDetails={vehicle}
                        index={vehicle._id}
                        key={vehicle._id}
                      />
                    );
                  })}
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SearchPage;

const LocationSearch = () => {
  return (
    <section>
      <div className="flex gap-5 mt-12 w-full text-2xl font-light tracking-tighter leading-8 text-neutral-900 max-md:flex-wrap max-md:mt-10 max-md:max-w-full  custom-x-scroll">
        <div className="flex flex-auto gap-5 justify-between items-center px-5 max-md:flex-wrap">
          <div className="justify-center self-stretch px-2 py-3 rounded-lg border border-solid bg-neutral-100 border-neutral-400 font-[450] whitespace-nowrap">
            FCT, Abuja
          </div>
          <div className="self-stretch my-auto whitespace-nowrap">Abia</div>
          <div className="self-stretch my-auto whitespace-nowrap">Adamawa</div>
          <div className="self-stretch my-auto whitespace-nowrap">Akwaibom</div>
          <div className="self-stretch my-auto whitespace-nowrap">Anambra</div>
          {/* <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
          <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div> */}
          <div className="self-stretch my-auto whitespace-nowrap">
            Cross river
          </div>
          <div className="self-stretch my-auto whitespace-nowrap">Delta</div>
        </div>
        {/* <div className="flex gap-0 self-start px-5 whitespace-nowrap">
          <div className="my-auto whitespace-nowrap">Delta</div>
          <div className="shrink-0 h-[52px]" />
        </div> */}
      </div>
    </section>
  );
};

const RecentSearches = () => {
  const recentSearches = [
    { location: "Wuse", area: "Federal capital territory, Abuja" },
    { location: "Kuje", area: "Federal capital territory, Abuja" },
    { location: "Gwagwalada", area: "Federal capital territory, Abuja" },
    { location: "Pyakasa", area: "Federal capital territory, Abuja" },
    { location: "Emene", area: "Enugu state" },
    { location: "Agkpapa", area: "" },
  ];
  return (
    <section className="mt-16 ml-6 max-md:mt-10 max-md:ml-2.5">
      <h2 className="text-2xl font-medium leading-[52px] text-neutral-900">
        Recent searches
      </h2>
      <div className="flex flex-col justify-center px-6 mt-5 max-md:px-5 max-md:max-w-full">
        {recentSearches.map((search, index) => (
          <div
            key={index}
            className="flex flex-col py-5 border-b border-solid border-neutral-400 max-md:max-w-full"
          >
            <div
              className={`text-2xl font-medium leading-10 text-neutral-900 max-md:max-w-full ${
                index === recentSearches.length - 1 ? "pb-1.5" : ""
              }`}
            >
              {search.location}
            </div>
            {search.area && (
              <div className="mt-3 text-xl leading-7 font-[450] text-neutral-900 text-opacity-80 max-md:max-w-full">
                {search.area}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

function ScrollContainer() {
  const [scrollPos, setScrollPos] = useState(0);
  const scrollContainerRef = useRef(null);
  const dragHandlerRef = useRef(null);

  const handleMouseDown = (e) => {
    const startY = e.clientY;
    let scrollPos = scrollContainerRef.current.scrollTop;

    document.addEventListener("mousemove", (e) => {
      const deltaY = e.clientY - startY;
      scrollPos += deltaY;
      setScrollPos(scrollPos);
    });

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", null, false);
    });
  };

  return (
    <div className="scroll-container" ref={scrollContainerRef}>
      <div className="scroll-content custom-x-scroll flex flex-auto gap-5 justify-between items-center px-5 max-md:flex-wrap">
        {" "}
        <div className="justify-center self-stretch px-2 py-3 rounded-lg border border-solid bg-neutral-100 border-neutral-400 font-[450] whitespace-nowrap">
          FCT, Abuja
        </div>
        <div className="self-stretch my-auto whitespace-nowrap">Abia</div>
        <div className="self-stretch my-auto whitespace-nowrap">Adamawa</div>
        <div className="self-stretch my-auto whitespace-nowrap">Akwaibom</div>
        <div className="self-stretch my-auto whitespace-nowrap">Anambra</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">Bauchi</div>
        <div className="self-stretch my-auto whitespace-nowrap">
          Cross river
        </div>
        <div className="self-stretch my-auto whitespace-nowrap">Delta</div>
      </div>
      <div
        className="drag-handler"
        ref={dragHandlerRef}
        onMouseDown={handleMouseDown}
      />
    </div>
  );
}

function FilterToggleButton({ setFilterToggle, filterToggle }) {
  return (
    <div
      className="inline-flex flex-col items-end justify-center gap-3 me-0 mt-[2.5px] w-[100px] md:w-[150px] pe-5 md:p-0 cursor-pointer"
      onClick={() => setFilterToggle(!filterToggle)}
    >
      <div className="icon-container ms-auto ">
        <FilterSearchIconSvg />
      </div>

      <div className="relative font-normal text-center text-[#121212] text-xl tracking-[-0.41px] leading-3">
        Filter <span className="md:inline hidden">search</span>
      </div>
    </div>
  );
}
