import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import GlobalFilter from "./GlobalFilter"; // Assuming you have a GlobalFilter component

const VoomTable = ({ title, data, columns, filter, setFilter }) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      globalFilter: filter,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow, // Destructure prepareRow here
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-between items-center">
        <h4>{title}</h4>
        <GlobalFilter filter={filter} setFilter={setFilter} />
      </div>
      <table {...getTableProps()} className="min-w-full mt-3">
        <thead className=" text-[#ffffff] font-raleway text-[14px] font-semibold leading-[16px] text-left">
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              className="h-[50px] bg-[#000000] "
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                  className={`${index === 0 && " rounded-tl-[10px]"} ${
                    index === headerGroup?.headers?.length - 1 &&
                    " rounded-tr-[10px] "
                  } ps-[17px]`}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row); // Call prepareRow for each row
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    key={cell.column.id}
                    className="p-[14px_17px] border-b border-[#0000001A] text-[#878686] font-raleway text-[14px] font-semibold leading-[16px] text-left"
                  >
                    {cell.column.id === "rider"
                      ? cell.value.full_name
                      : cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex gap-3 mt-5 w-full">
        <button
          className=""
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className=""
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <span>
          Page {pageIndex + 1} of {pageCount}
        </span>{" "}
        <button className="" onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <button
          className=""
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          className="ms-auto"
        >
          {[10, 25, 50].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default VoomTable;