import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import NavigationItem from "./NavigationItem";
import Logo from "./Logo";

const navigationItems = {
  owner: [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c1367d1af5cb2b5d7bf143d38f9a4c734d68e8026e3b554daa6f7e3052f1e2da", label: "Dashboard", link: "/dashboard" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6e3814889d9f5774414c528123125940707e3be7158c0eb384bc3879c86a5695", label: "Listed Bikes", link: "/dashboard/bikes" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/401c19fb231cc9151505006cfe2027fb0243c6d252a895cc8658ad4289ee422b", label: "Withdrawal", link: "/dashboard/withdrawal" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e7d60657bdb24d612ecbb81bfcfbf1ee77d232d0428f57704cb9a71d666ad500", label: "Report", link: "/dashboard/report" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/abe35b51eea05a818fc826b0cff567a64ce8b78c57d96a831e74c13a6c33bf8c", label: "Profile", link: "/dashboard/profile" },
  ],
  rider: [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c1367d1af5cb2b5d7bf143d38f9a4c734d68e8026e3b554daa6f7e3052f1e2da", label: "Dashboard", link: "/dashboard" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6e3814889d9f5774414c528123125940707e3be7158c0eb384bc3879c86a5695", label: "Payment History", link: "/dashboard/payment" },
    /*{ icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/401c19fb231cc9151505006cfe2027fb0243c6d252a895cc8658ad4289ee422b", label: "Complaint", link: "/dashboard/complaint" },*/
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/abe35b51eea05a818fc826b0cff567a64ce8b78c57d96a831e74c13a6c33bf8c", label: "Profile", link: "/dashboard/profile" },
  ]
};

const SidebarWithNav = ({ logoutFunction, children, accountType }) => {
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Get the correct navigation items based on accountType
  const itemsToRender = navigationItems[accountType] || [];

  return (
    <div className="flex h-screen bg-white relative">
      {/* Sidebar */}
      <button
        onClick={toggleSidebar}
        className={`absolute top-4 left-4 z-50 p-2 bg-gray-200 rounded-lg md:hidden transition-opacity ${
          isSidebarOpen ? "opacity-0" : "opacity-100"
        }`}
        aria-label="Toggle Sidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 5.25h16.5M3.75 12h16.5M3.75 18.75h16.5"
          />
        </svg>
      </button>

      <aside
        className={`fixed top-0 left-0 h-full w-[240px] bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-40 flex flex-col items-center 
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} 
          md:translate-x-0`}
      >
        <Logo />
        <ul className="flex flex-col mt-12 w-[184px]">
          {itemsToRender.map((item, index) => (
            <li key={index}>
              <NavigationItem
                icon={item.icon}
                label={item.label}
                link={item.link}
                isActive={location.pathname === item.link}
              />
            </li>
          ))}
        </ul>
        <button
          onClick={logoutFunction}
          className="flex gap-4 items-center px-3 py-4 mt-auto mb-8 w-[184px] text-xs rounded-xl border border-solid bg-white bg-opacity-10 border-neutral-200"
          aria-label="Sign out"
        >
          <span>Sign out</span>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/456b4f4d-2f08-4ce6-8f46-ca115ea756d4"
            alt="Sign out"
            className="w-6 h-[25px] rounded-lg"
          />
        </button>
      </aside>

      {/* Overlay for Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Main Content */}
      <main
        className="flex-1 transition-all duration-300 md:ml-[240px] w-full"
      >
        {children}
      </main>
    </div>
  );
};

export default SidebarWithNav;
