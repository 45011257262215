import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";

import { router } from "./routes";
import ModalProvider from "./Context/modalContext";
import AuthProvider from "./Context/authContext";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import DataProvider from "./Context/dataContext";
import axios from 'axios';


// Flag to track if the toast is already shown
let isToastShown = false;

// Set up Axios interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      if (!isToastShown) {
        isToastShown = true; // Set the flag to true
        toast.error("Session expired. Please log in again.");
        localStorage.removeItem("vooom-token");
        localStorage.removeItem("vooom-profile");
        // Redirect to the login page
        window.location.href = "/auth/login";

      }
    }
    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <React.StrictMode>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthProvider>
        <DataProvider>
          <ModalProvider>
            <RouterProvider router={router} />
          </ModalProvider>
        </DataProvider>
      </AuthProvider>
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
