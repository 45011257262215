import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../Context/authContext";
import {
  CreateAccountIconSvg,
  DashboardIconSvg,
  LoginIconSvg,
  LogoutIonSvg,
  NotificationIconSvg,
} from "../../icons";
import { useDataContext } from "../../Context/dataContext";
import { NotificationComponent } from "../../routes/DashboardPage/DashboardPage";
import moment from "moment";
import { Spinner } from "@chakra-ui/react";
import './custom.css';

function NavigationComponent() {
  const { endpoint, token, accountType } = useAuthContext();
  // useEffect(() => {
  //   console.log({ endpoint, token });
  // }, []);
  return (
    <header className="header-container border-b border-solid border-neutral-900 border-opacity-70">
      {/* Company logo */}
      {!token && (
        <Link to="/" className="logo">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c7e4693bea7682db8585fc8823e9a4da6fcc4b3350fb87f462fb0b67c77451b?apiKey=e85d4038ac5a41748b51322169f61f43&"
            alt="Company logo"
            className="w-[80px] md:w-[151px]"
          />
        </Link>
      )}

      {/* Navigation links */}
      <div className="nav-links">
        <div className="menu-options">
          {accountType ? (
            accountType !== "owner" ? (
              // <Link
              //   to={"/search"}
              //   className="font-bold text-neutral-900"
              // >
              //   Explore vehicles
              // </Link>
              <></>
            ) : (
              <Link
                to={"/dashboard/host-vehicle"}
                className="font-bold text-neutral-900"
              >
                Host your vehicle
              </Link>
            )
          ) : (
            <Link
              to={accountType === "owner" ? "/host-vehicle" : "/auth/login"}
              className="font-bold text-neutral-900"
            >
              Host your vehicle
            </Link>
          )}
        </div>

        <NavigationMenuButtonComponent isLogin={token} />
        <NavigationNotificationButtonComponent isLogin={token} />
      </div>
    </header>
  );
}

export default NavigationComponent;

function NavigationMenuButtonComponent({ isLogin }) {
  const [isOpen, setIsOpen] = useState(false);
  const [navigationLink, setNavigationLink] = useState([]);
  const { logoutFunction, accountType } = useAuthContext();

  useEffect(() => {
    console.log({ accountType });
    if (isLogin) {
      setNavigationLink([
        // { linkUrl: "./#", icon: "", name: "Notifications" },
        // { linkUrl: "./#", icon: "", name: "Guarantor" },
        // { linkUrl: "./#", icon: "", name: "Privacy policy" },
        // { linkUrl: "./#", icon: "", name: "Protection plan" },
        // { linkUrl: "./#", icon: "", name: "Referrals" },
        // { linkUrl: "./#", icon: "", name: "Customer support" },
      ]);
    } else {
      setNavigationLink([
        {
          linkUrl: `/auth/register`,
          icon: <CreateAccountIconSvg />,
          name: "Create an account",
        },
        {
          linkUrl: `/auth/login`,
          icon: <LoginIconSvg />,
          name: "Login",
        },
      ]);
    }
  }, [isOpen]);

  return (
    <div
      className="NavigationMenuButtonComponent cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        // to={"/auth/login"}
        className="flex gap-1.5 justify-center p-1 md:p-1.5 rounded bg-zinc-100"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1271845cdb073f630f55374e515a3e66de6617c2a9033c3642886de3045a13f?apiKey=e85d4038ac5a41748b51322169f61f43&"
          alt=""
          className="shrink-0 my-auto w-5 aspect-square"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d10246d2bd3ca71d0440d5a9396e9db9c0643e2869de49756c488752c948d462?apiKey=e85d4038ac5a41748b51322169f61f43&"
          alt=""
          className="shrink-0 w-6 aspect-square hidden md:block"
        />
      </div>

      {isOpen && (
        <div
          className={`z-[1100]  bg-white shadow absolute w-[250px]  w -[323px] h-auto right-5 mt-[20px] p-[20px] gap-[60px] rounded-[8px] `}
        >
          <div className="md:hidden flex w-full">
            {accountType ? (
              // Show different links based on account type if logged in
              <>
                {accountType !== "owner" ? (
                  // If the user is not an "owner", show Explore Vehicles link

                  <Link
                    to={"/search"}
                    className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer"
                  >
                    <div className="icon w-[24px] h-[24px] ">
                      {" "}
                      <DashboardIconSvg />
                    </div>
                    <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                      Explore vehicles
                    </div>
                  </Link>
                ) : (
                  // If the user is an "owner", show Host Your Vehicle link

                  <Link
                    to={"/dashboard/host-vehicle"}
                    className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer"
                  >
                    <div className="icon w-[24px] h-[24px] ">
                      {" "}
                      <DashboardIconSvg />
                    </div>
                    <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                      Host your vehicle
                    </div>
                  </Link>
                )}
              </>
            ) : (
              // If not logged in, show a conditional link based on account type

              <Link
                to={accountType === "owner" ? "/host-vehicle" : "/auth/login"}
                className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer"
              >
                <div className="icon w-[24px] h-[24px] ">
                  {" "}
                  <DashboardIconSvg />
                </div>
                <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                  Host your vehicle
                </div>
              </Link>
            )}
          </div>

          {isLogin && (
            <Link
              to={"/dashboard"}
              className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer border-b-1"
            >
              <div className="icon w-[24px] h-[24px] ">
                {" "}
                <DashboardIconSvg />
              </div>
              <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                Dashboard
              </div>
            </Link>
          )}

          {navigationLink.map((link, i) => (
            <Link
              to={link.linkUrl}
              className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer "
              key={i}
            >
              <div className="icon w-[24px] h-[24px] ">{link.icon}</div>
              <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                {link.name}
              </div>
            </Link>
          ))}

          {isLogin && (
            <div
              to={"/dashboard"}
              className=" flex gap-[10px] items-center my-2 py-3 cursor-pointer border-b-1 text-red-900 hover:bg-red-200"
              onClick={() => logoutFunction()}
            >
              <div className="icon w-[24px] h-[24px] ">
                <LogoutIonSvg />
              </div>
              <div className="name font-Circular font-size-[20px] font-semibold leading-[12px] tracking-[-0.425px] text-left">
                Logout
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
function NavigationNotificationButtonComponent({ isLogin }) {
  const [isOpen, setIsOpen] = useState(false);
  const { notificationList } = useDataContext();

  const [countPerPage, setCountPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let maxPageLength = notificationList.data.length;
    setTotalPage(Math.ceil(maxPageLength / countPerPage));
  }, [countPerPage, notificationList]);

  const nextPageFunction = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageFunction = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="NavigationMenuButtonComponent cursor-pointer">
      <div
        // to={"/auth/login"}
        onClick={() => setIsOpen(!isOpen)}
        className="flex gap-1.5 justify-center p-1 md:p-1.5 rounded bg-zinc-100"
      >
        <NotificationIconSvg />
      </div>

      {isOpen && (
        <div
          className={`z-[1100]  bg-white shadow absolute w-full max-w-[500px] h-auto overflow-scroll right-1 left-1   mt-[20px] p-[20px] gap-[60px] rounded-[8px] `}
        >
          {notificationList.loading ? (
            <Spinner className="mx-auto mt-5" />
          ) : (
            <>
              {notificationList.data
                ?.slice(
                  currentPage * countPerPage,
                  (currentPage + 1) * countPerPage
                )
                .map((notification, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-[#dedede] p-2 my-2 flex flex-col"
                    >
                      {notification.message}
                      <div className="text-xs mt-0.5">
                        {" "}
                        {moment(notification.createdAt).format("llll")}
                      </div>
                    </div>
                  );
                })}
              <div className="mt-3">
                <button
                  onClick={prevPageFunction}
                  className="btn btn-sm btn-primary"
                >
                  Prev
                </button>{" "}
                <span className="mx-3 ">
                  {currentPage + 1} of {totalPage}
                </span>
                <button
                  onClick={nextPageFunction}
                  className="btn btn-sm btn-primary"
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}


export function LandingPageNavigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const links = [
    { text: "How it works", href: "/how-it-works" },
    { text: "Blog", href: "#" },
    { text: "About Us", href: "#" },
    { text: "Contact Us", href: "/contact" },
  ];

  return (
    <>
      {/* Main Navigation Bar */}
      <nav className="relative z-10 flex items-center h-[70px] text-base font-bold bg-[#110e0d] backdrop-blur-[30px] px-5 max-md:px-3">
        <div className="flex mx-auto justify-between w-full md:w-[1440px] md:px-10">
          <div className="flex gap-7 items-center my-auto min-w-[240px]">
            <Link to="/">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/e85d4038ac5a41748b51322169f61f43/b5053617ecd16ccf3213800315717fcd810bfefcc7003b0546551bdda5fee094?apiKey=e85d4038ac5a41748b51322169f61f43&"
                alt="Company Logo"
                className="object-contain shrink-0 self-stretch my-auto aspect-[4.31] w-[151px]"
              />
            </Link>
          </div>
          <div className="flex gap-2">
            <div className="hidden sm:flex flex-wrap gap-8 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full">
              <div className="hidden md:flex items-center self-stretch my-auto text-white">
                {links.map((link, index) => (
                  <Link
                    key={index}
                    to={link.href}
                    className="p-[10px] text-nowrap self-stretch my-auto text-center rounded-xl hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                    tabIndex="0"
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
              <div className="flex gap-4 items-center self-stretch my-auto min-w-[240px]">
                <Link
                  to="/auth/login"
                  className="gap-2.5 self-stretch px-8 py-2.5 my-auto text-white whitespace-nowrap border border-white border-solid rounded-[100px] max-md:px-5 hover:bg-white hover:text-stone-950 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                  tabIndex="0"
                >
                  Login
                </Link>
                <Link
                  to="/auth/register"
                  className="gap-2.5 self-stretch flex justify-center items-center p-2.5 my-auto bg-white rounded-[100px] text-stone-950 w-[132px] hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                  tabIndex="0"
                >
                  Get Started
                </Link>
              </div>
            </div>
            {/* Open Button for Mobile */}
            <button
              className="te xt-white px-3 md:hidden flex gap-1.5 justify-center p-1 md:p-1.5 rounded bg-zinc-100"
              onClick={() => setIsMenuOpen(true)}
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1271845cdb073f630f55374e515a3e66de6617c2a9033c3642886de3045a13f?apiKey=e85d4038ac5a41748b51322169f61f43&"
                alt=""
                className="shrink-0 my-auto w-5 aspect-square"
              />
            </button>
          </div>
        </div>
      </nav>

      {/* Full-Screen Navigation Overlay */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center text-center text-white"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          {/* Close Button */}
          <button
            className="absolute top-5 right-5 text-white text-2xl"
            onClick={() => setIsMenuOpen(false)}
          >
            ×
          </button>

          {/* Links */}
          <div className="flex flex-col gap-6 text-xl">
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.href}
                className="hover:underline"
                onClick={() => setIsMenuOpen(false)}
              >
                {link.text}
              </Link>
            ))}
          </div>

          {/* Login and Get Started Buttons */}
          <div className="mt-10 flex flex-col gap-4">
            <Link
              to="/auth/login"
              className="px-8 py-2 border border-white rounded-full text-center"
              onClick={() => setIsMenuOpen(false)}
            >
              Login
            </Link>
            <Link
              to="/auth/register"
              className="px-8 py-2 bg-white text-black rounded-full text-center"
              onClick={() => setIsMenuOpen(false)}
            >
              Get Started
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
