import React, { useEffect, useState } from "react";
import NavigationComponent from "../../components/navigation/NavigationComponent";
import { useAuthContext } from "../../Context/authContext";
import {
  ChooseProtectionPlan,
  OwnerNationalIdentityForm,
  PersonalInformationForm,
  UserAddGuarantorForm,
  UserNationalIdentityForm,
} from "../AuthPage/AccountSetupPage";
import { useDataContext } from "../../Context/dataContext";
import { InputField } from "../../components/FormElementComponent/FormElementComponent";
import { CreateWalletModelContentComponent } from "../DashboardPage/DashboardPage";

function ProfilePage() {
  const { profile, accountType, handleProfileUpdateFunction } =
    useAuthContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [pageSteps, setPageSteps] = useState([]);

  useEffect(() => {
    // NOTE: this is the owner profile sub navigation
    if (accountType === "owner") {
      return setPageSteps([
        {
          step: 1,
          label: "Account",
          content: <PersonalInformationForm action={nextStepFunction} />,
        },
        {
          step: 2,
          label: "National Identity",
          content: <OwnerNationalIdentityForm action={nextStepFunction} />,
        },
        {
          step: 3,
          label: "Security & Withdrawal",
          content: <SecurityWithdrawalForm action={nextStepFunction} />,
        },
      ]);
    }

    if (accountType === "rider") {
      return setPageSteps([
        {
          step: 1,
          label: "Personal Information",
          content: <PersonalInformationForm action={nextStepFunction} />,
        },
        {
          step: 2,
          label: "National Identity",
          content: <UserNationalIdentityForm action={nextStepFunction} />,
        },
        {
          step: 3,
          label: "Add Guarantor",
          content: <UserAddGuarantorForm action={nextStepFunction} />,
        },
      ]);
    }
  }, [accountType]);

  function nextStepFunction(stepIndex) {
    // setCurrentStep(stepIndex);
  }

  return (
    <main className="flex overflow-hidden flex-col pb-56 max-md:pb-24 ">
      <section className="w-full  flex">
        <div className="w-full lg:w-[1128px] h-[100px] py-5 justify-between items-center inline-flex mx-auto px-5 md:px-0">
          <div className="text-[#121212] text-[25px] lg:text-[32px] font-['Circular Std'] leading-normal ">
            {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
            Profile
          </div>
          <div className="justify-center items-center gap-3 flex">
            <div className="flex-col justify-center items-start gap-3 inline-flex">
              <div className="text-[#121212]/70 text-base font-['Circular Std'] leading-[13px]">
                {accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}
              </div>
              <div className="text-[#121212] text-xl font-bold font-['Circular Std'] leading-[13px]">
                {profile?.full_name}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 flex flex-col items-center  font-[450] w-full lg:w-[11 28px] mx-auto">
        <div className="flex gap-[30px] md:gap-5 px-3 md:px-0 w-full">
          {pageSteps.map((step, index) => (
            <button
              className={`font-raleway text-[16px] font-normal leading-[18.78px] text-center pt-5 px-2 pb-2  ${
                index === currentStep
                  ? "border-b-[4px] border-[#000] text-black"
                  : "text-[#ABAFB1]"
              } `}
              key={step.step}
              onClick={() => setCurrentStep(index)}
            >
              {step.label}
              {/* HI */}
            </button>
          ))}
        </div>
        <div className="mt-0 w-full px-5 flex flex-col justify-center">
          {pageSteps[currentStep]?.content}
        </div>
      </section>
    </main>
  );
}

export default ProfilePage;

export function SecurityWithdrawalForm({ action }) {
  const { profile, handleProfileUpdateFunction } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({});

  const handelInputChanges = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    // console.log({ wallet: profile.wallet });
    setFormData(profile ? profile : {});
  }, [profile]);

  return (
    <>
      <div className="flex flex-col gap-[20px] mt-10 w-full text-base tracking-tight leading-none  text-neutral-900">
        {/* TODO: TEst if all this works */}
        {isModalOpen && (
          <CreateWalletModelContentComponent
            setIsModalOpen={setIsModalOpen}
            wallet={profile.wallet}
          />
        )}{" "}
        <div className="flex gap-[30px]">
          <InputField
            label={"Old Password"}
            type={"password"}
            name={"old_password"}
            handelInputChanges={handelInputChanges}
            stateData={formData}
          />
        </div>
        <div className="flex gap-[30px]">
          <InputField
            type={"password"}
            label={"New Password"}
            name={"password"}
            handelInputChanges={handelInputChanges}
            stateData={formData}
          />
          <InputField
            label={"Re Password"}
            type={"password"}
            name={"re_password"}
            handelInputChanges={handelInputChanges}
            stateData={formData}
          />
        </div>
        <button
          className="w-[476px] h-[52px] rounded-[100px] bg-[#000] text-[#fff] mt-[27px] mx-auto"
          onClick={() => {
            handleProfileUpdateFunction(formData);
            action(1);
          }}
        >
          Update Profile
        </button>
      </div>

      <div className="my-10 flex flex-col gap-2 border-t-2 pt-5 border-[#c9c9c9]">
        <div className=" flex justify-between items-center">
          <b>Bank Account</b>
          <button
            className="px-5 h-[35px] rounded-[50px] text-xs bg-[#000] text-[#fff] "
            onClick={() => setIsModalOpen(true)}
          >
            Update Account
          </button>
        </div>
        <div className="">
          <b>Account name:</b> {profile.wallet.bank.account_name}
        </div>
        <div className="">
          <b>Account number:</b> {profile.wallet.bank.account_number}
        </div>
        <div className="">
          <b>Bank:</b> {profile.wallet.bank.bank}
        </div>
      </div>
    </>
  );
}