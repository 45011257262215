"use client";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect } from "react";
import { toast } from "react-toastify";

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [accountType, setAccountType] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [token, setToken] = React.useState(null);

  // let endpoint = "http://192.168.0.179:5000/api";
  // let endpoint = "http://localhost:5000/api";
  let endpoint = "https://voom-backend.vercel.app/api";

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const token = localStorage.getItem("vooom-token");
    const profile = JSON.parse(localStorage.getItem("vooom-profile"));
    if (currentPathname.includes("dashboard")) {
      if (!token || !profile) {
        window.location.href = "/auth/login";
        return;
      }
    }
    if (profile && token) {
      setProfile(profile);
      setAccountType(profile.account_type);
      setToken(token);
    }
  }, []);

  async function loginAuthFunction({ email, password, accountType }) {
    let data = JSON.stringify({
      email,
      password,
      accountType,
    });

    let url;

    if (accountType === "owner") {
      url = `${endpoint}/auth/owner/login`;
    }

    if (accountType === "rider") {
      url = `${endpoint}/auth/rider/login`;
    }

    console.log({ email, password, accountType });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "",
      },
      data: data,
    };

    try {
      let response = await axios.request(config);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async function registerAuthFunction({
    email,
    password,
    full_name,
    phone_number,
    accountType,
  }) {
    let data = JSON.stringify({
      email,
      password,
      full_name,
      phone_number,
      accountType,
    });

    let url;

    if (accountType === "owner") {
      url = `${endpoint}/auth/owner/register`;
    }

    if (accountType === "rider") {
      url = `${endpoint}/auth/rider/register`;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "",
      },
      data: data,
    };

    try {
      let response = await axios.request(config);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  const logoutFunction = async () => {
    if (window.confirm("You will be logout of your account")) {
      window.location.href = "/";

      setAccountType(null);
      setToken(null);
      setProfile(null);
      localStorage.removeItem("vooom-token");
      localStorage.removeItem("vooom-profile");
    }
  };

  useEffect(() => {
    if (!token) return;
  }, [token]);

  function getAdminProfileUpdateFunction() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/admin/profile`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.ok) {
          localStorage.setItem(
            "vooom-admin-profile",
            JSON.stringify(response.data.profile)
          );
          setProfile(response.data.profile);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getProfileUpdateFunction() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${endpoint}/user`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.ok) {
          localStorage.setItem(
            "vooom-admin-profile",
            JSON.stringify(response.data.profile)
          );
          setProfile(response.data.profile);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleProfileUpdateFunction(updateProfile) {
    if (checkObjectIsEmpty(updateProfile)) {
      return toast.error("No Changes made");
    }

    // return console.log({ updateProfile });

    let data = JSON.stringify(updateProfile);

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${endpoint}/user/`,
      headers: {
        "Cache-Control": "no-cache",
        Accept: "*/*",
        authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        console.log({ response });
        if (response.data.ok) {
          getProfileUpdateFunction();
          toast.success("Profile Updated");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function checkObjectIsEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  return (
    <AuthContext.Provider
      value={{
        endpoint,
        token,
        profile,
        accountType,
        checkObjectIsEmpty,
        loginAuthFunction,
        registerAuthFunction,
        logoutFunction,
        getAdminProfileUpdateFunction,
        getProfileUpdateFunction,
        handleProfileUpdateFunction,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContext);
