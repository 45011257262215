import React, { useState, useMemo } from "react";
import VoomTable from "./Table"; // Adjust the path to your VehicleTable component

const RemittanceListComponent = () => {
  const [filter, setFilter] = useState("");
  const [selectedReport, setSelectedReport] = useState(null); // To manage modal content
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility

  const columns = useMemo(
    () => [
      { Header: "Date", accessor: "date" },
      { Header: "Priority", accessor: "priority" },
      { Header: "Report", accessor: "report_summary" }, // For truncated text
      {
        Header: "View Report",
        accessor: "view_report",
        Cell: ({ row }) => (
          <button
            className="bg-blue-500 text-white py-1 px-3 rounded"
            onClick={() => handleViewReport(row.original.report)}
          >
            View
          </button>
        ),
      },
    ],
    []
  );

  const dummyData = useMemo(
    () => [
      {
        date: "2024-11-25",
        priority: "High",
        report:
          "There is an ongoing maintenance on your vehicle with Registration number #8905674, it will be back up as soon as the maintenance is done.",
      },
      {
        date: "2024-11-20",
        priority: "Medium",
        report:
          "This report outlines the maintenance schedule for the upcoming month.",
      },
      {
        date: "2024-11-15",
        priority: "Low",
        report: "A general update about vehicle usage and performance metrics.",
      },
      {
        date: "2024-11-10",
        priority: "High",
        report: "A critical incident report involving a vehicle malfunction.",
      },
      {
        date: "2024-11-05",
        priority: "Medium",
        report:
          "An update on the new policy implementation regarding vehicle inspections.",
      },
    ],
    []
  );

  const filteredData = useMemo(() => {
    return dummyData
      .map((item) => ({
        ...item,
        report_summary:
          item.report.length > 30
            ? `${item.report.slice(0, 30)}...` // Truncate report
            : item.report,
      }))
      .filter((item) =>
        Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(filter?.toLowerCase())
        )
      );
  }, [dummyData, filter]);

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedReport(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <VoomTable
        title="Hosted Vehicles"
        data={filteredData}
        columns={columns}
        filter={filter}
        setFilter={setFilter}
      />

      {/* Modal for viewing full report */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-[#A3A3A333]/20 backdrop-blur-[3px] shadow-lg border border-white/20 flex items-center justify-center left-[240px]">
          <div
            className="w-[536px] p-[28px_24px_28px_24px] gap-[27px] rounded-[12px]  bg-white
"
          >
            <div className="flex justify-between mb-4 items-center">
              <h2 className="font-raleway text-lg font-semibold leading-[23.48px] text-center ">
                Full Report
              </h2>
              <button className="" onClick={closeModal}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="8" fill="#FFF2E2" />
                  <path
                    d="M22 10L10 22"
                    stroke="#1C1D22"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 10L22 22"
                    stroke="#1C1D22"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <p
              className="font-raleway text-[16px] font-semibold leading-[19px] text-left text-[#000000]
"
            >
              {selectedReport}
            </p>
            <button
              className="w-[476px] h-[52px] rounded-[100px] bg-[#000] text-[#fff] mt-[27px]"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RemittanceListComponent;
