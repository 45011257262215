import React, { useState, useMemo, useEffect } from "react";
import VoomTable from "./Table"; // Adjust the path to your historyTable component
import { useDataContext } from "../../../Context/dataContext";
import moment from "moment";

const WithdrawalListComponent = () => {
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const { walletHistory } = useDataContext();
  const columns = useMemo(
    () => [
      { Header: "History ID", accessor: "history_id" },
      { Header: "Type", accessor: "type" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Description", accessor: "description" },
      { Header: "New Balance", accessor: "newBalance" },
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "date" },
      // { Header: "Account", accessor: "account" },
      // { Header: "Bank Name", accessor: "bank_name" },
      // { Header: "Account Name", accessor: "account_name" },
    ],
    []
  );

  useEffect(() => {
    // console.log({ walletHistory });

    const transformedArray = walletHistory.data.map((history) => ({
      history_id: history._id,
      status: "Successful",
      type: history.type || "Credit",
      newBalance: `₦${history.newBalance.toLocaleString()}`,
      description: history.description,
      amount: `₦${history.amount.toLocaleString()}`,
      date: moment(history.createdAt).format("ll"),
    }));

    setData(transformedArray);
  }, [walletHistory]);

  return (
    <VoomTable
      title="Wallet History"
      data={data}
      columns={columns}
      filter={filter}
      setFilter={setFilter}
    />
  );
};

export default WithdrawalListComponent;
